import axios from 'axios';
import Cookies from 'js-cookie';
import { APP_DEMO_MODE } from '../config';

// Customer store
export let BASE_URL;
export let consumer_key;
export let consumer_secret;
export let wp_username;
export let wp_app_password;
export let WC_ADMIN_API_BASE_URL;
export let getAuthParams;

// StoreUI.net
export let API_BASE_URL;
export let WP_API_BASE_URL;

// Clients
export let axiosClient; // Customer store
export let axiosWCAdminClient; // Customer store - WooCommerce Admin API
export let axiosAnalyticsClient; // Customer store - WooCommerce Analytics API
export let wpApiClient; // Customer store - WordPress API

//  ======================================================================================
//  =============================== Customer Store =======================================
//  ======================================================================================

// Check for token in URL and create cookie if present
const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');

const getCookieData = () => {
	return new Promise((resolve) => {
		let cookieData;

		if (token) {
			try {
				const decodedToken = JSON.parse(atob(token));

				if (
					!decodedToken.store_url ||
					!decodedToken.consumer_key ||
					!decodedToken.consumer_secret
				) {
					throw new Error('Invalid token data');
				}

				const decodedData = {
					store_url: decodedToken.store_url,
					consumer_key: decodedToken.consumer_key,
					consumer_secret: decodedToken.consumer_secret,
				};

				cookieData = JSON.stringify(decodedData);
				Cookies.set('storeui_auth', cookieData, {
					path: '/',
					domain: 'app.storeui.net',
					sameSite: 'Lax',
					expires: 7,
				});
				resolve(decodedData);
			} catch (error) {
				console.error('Error decoding token:', error);
				resolve(null);
			}
		} else {
			cookieData =
				Cookies.get('storeui_auth') ||
				Cookies.get('storeui_auth_local');
			if (cookieData) {
				try {
					resolve(JSON.parse(cookieData));
				} catch (error) {
					console.error('Error parsing auth cookie:', error);
					resolve(null);
				}
			} else {
				resolve(null);
			}
		}
	});
};

getCookieData().then((decodedData) => {
	if (APP_DEMO_MODE) {
		BASE_URL = 'https://demo.storeui.net/wp';
		consumer_key = process.env.REACT_APP_DEMO_CONSUMER_KEY;
		consumer_secret = process.env.REACT_APP_DEMO_CONSUMER_SECRET;
		wp_username = process.env.REACT_APP_WP_DEMO_USERNAME;
		wp_app_password = process.env.REACT_APP_WP_DEMO_APP_PASSWORD;
	} else if (decodedData) {
		BASE_URL = decodedData.store_url;
		consumer_key = decodedData.consumer_key;
		consumer_secret = decodedData.consumer_secret;
		wp_username = decodedData.wp_username;
		wp_app_password = decodedData.wp_app_password;
	} else {
		// redirect to login page
		window.location.href = 'https://storeui.net/my-account/';
	}

	API_BASE_URL = `${BASE_URL}/wp-json/wc/v3`;
	WP_API_BASE_URL = `${BASE_URL}/wp-json`;
	WC_ADMIN_API_BASE_URL = `${BASE_URL}/wp-json/wc-admin`;

	getAuthParams = () => {
		return {
			consumer_key: consumer_key,
			consumer_secret: consumer_secret,
		};
	};

	const authParams = getAuthParams();

	axiosClient = axios.create({
		baseURL: API_BASE_URL,
		headers: {
			'Content-Type': 'application/json',
		},
		params: authParams,
	});

	// Add an interceptor to handle WordPress REST API requests
	axiosClient.interceptors.request.use((config) => {
		if (config.url.startsWith('/wp/v2')) {
			config.baseURL = WP_API_BASE_URL;
			// Use WordPress authentication for WP API requests
			config.auth = {
				username: wp_username,
				password: wp_app_password,
			};
			// Remove WooCommerce params for WP API requests
			delete config.params.consumer_key;
			delete config.params.consumer_secret;
		}
		return config;
	});

	axiosWCAdminClient = axios.create({
		baseURL: WC_ADMIN_API_BASE_URL,
		headers: {
			'Content-Type': 'application/json',
		},
		params: authParams,
	});

	axiosAnalyticsClient = axios.create({
		baseURL: `${BASE_URL}/wp-json/wc-analytics`,
		headers: {
			'Content-Type': 'application/json',
		},
		params: authParams,
	});

	// New client specifically for WordPress API requests
	wpApiClient = axios.create({
		baseURL: WP_API_BASE_URL,
		auth: {
			username: wp_username,
			password: wp_app_password,
		},
	});
});

//  ======================================================================================
//  =============================== StoreUI.net =========================================
//  ======================================================================================
export const STORE_UI_BASE_URL = 'https://storeui.net';
export const STORE_UI_API_BASE_URL = `${STORE_UI_BASE_URL}/wp-json`;

export const getStoreUIAuthParams = () => {
	return {
		consumer_key: process.env.REACT_APP_STOREUI_CONSUMER_KEY,
		consumer_secret: process.env.REACT_APP_STOREUI_CONSUMER_SECRET,
	};
};

const storeUIAuthParams = getStoreUIAuthParams();

export const axiosStoreUIClient = axios.create({
	baseURL: STORE_UI_API_BASE_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

// Add an interceptor to handle different API endpoints
axiosStoreUIClient.interceptors.request.use((config) => {
	if (config.url.startsWith('/wc/v3')) {
		// For WooCommerce API endpoints, add the auth params
		config.params = { ...config.params, ...storeUIAuthParams };
	}
	return config;
});

// Store demo email on storeui.net
export const submitDemoEmail = async (email) => {
	try {
		const response = await axiosStoreUIClient.post(
			'/storeui/v1/demo-email',
			{
				email,
			}
		);
		return response.data;
	} catch (error) {
		console.error('Error submitting demo email:', error);
		throw error;
	}
};
