import { useState, useEffect } from 'react';
import { APP_DEMO_MODE } from '../../config'
import { Button } from 'components/ui/button';
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from 'components/ui/card';
import { Input } from 'components/ui/input';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from 'components/ui/dialog';
import { Progress } from 'components/ui/progress';
import {
	AlertTriangle,
	RefreshCw,
	Trash2,
	Database,
	ShoppingBag,
	FileText,
	Zap,
	Copy,
	CheckCircle,
} from 'lucide-react';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from 'components/ui/tooltip';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { axiosClient } from 'api/axios';
import { useToast } from 'components/ui/use-toast';
import { Textarea } from 'components/ui/textarea';
import { Skeleton } from 'components/ui/skeleton';
import { SETUP_API } from 'api/setup';

export default function Component() {
	const [isProcessing, setIsProcessing] = useState(false);
	const [progress, setProgress] = useState(0);
	const [systemStatus, setSystemStatus] = useState('');
	const [isCopied, setIsCopied] = useState(false);
	const { toast } = useToast();
	const [systemAnalysis, setSystemAnalysis] = useState(null);
	const [shouldFetchSystemStatus, setShouldFetchSystemStatus] =
		useState(false);

	const simulateProcess = async (duration = 2000) => {
		setIsProcessing(true);
		setProgress(0);
		const interval = setInterval(() => {
			setProgress((prev) => {
				if (prev >= 100) {
					clearInterval(interval);
					setIsProcessing(false);
					return 100;
				}
				return prev + 5;
			});
		}, duration / 20);
	};

	const ConfirmationDialog = ({
		title,
		description,
		onConfirm,
		children,
	}) => (
		<Dialog>
			<DialogTrigger asChild>{children}</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>{title}</DialogTitle>
					<DialogDescription>{description}</DialogDescription>
				</DialogHeader>
				<DialogFooter>
					<Button variant='outline' onClick={() => {}}>
						Cancel
					</Button>
					<Button
						onClick={() => {
							onConfirm();
							simulateProcess();
						}}>
						Confirm
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);

	const ToolCard = ({
		title,
		description,
		buttonText,
		icon: Icon,
		warning,
		toolId,
	}) => {
		const queryClient = useQueryClient();

		const runToolMutation = useMutation({
			mutationFn: async (id) => {
				const response = await axiosClient.put(
					`system_status/tools/${id}`
				);
				return response.data;
			},
			onSuccess: (data) => {
				toast({
					title: 'Success',
					description:
						data.message || `${title} completed successfully.`,
				});
				queryClient.invalidateQueries({ queryKey: ['run-tool'] });
			},
			onError: (error) => {
				toast({
					title: 'Error',
					description: `Failed to run ${title}: ${error.message}`,
					variant: 'destructive',
				});
			},
		});

		const handleRunTool = () => {
			runToolMutation.mutate(toolId);
		};

		return (
			<Card>
				<CardHeader className='flex flex-col gap-y-1'>
					<div className='flex items-center justify-between'>
						<CardTitle className='flex items-center text-xl'>
							<Icon className='mr-2 h-4 w-4' />
							{title}
						</CardTitle>
						{warning && (
							<TooltipProvider>
								<Tooltip>
									<TooltipTrigger>
										<AlertTriangle className='h-4 w-4 text-red-500' />
									</TooltipTrigger>
									<TooltipContent>
										<p>{warning}</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						)}
					</div>
					<CardDescription>{description}</CardDescription>
				</CardHeader>
				<CardFooter>
					<ConfirmationDialog
						title={`Confirm ${title}`}
						description={`Are you sure you want to ${buttonText.toLowerCase()}? This action cannot be undone.`}
						onConfirm={handleRunTool}>
						<Button>{buttonText}</Button>
					</ConfirmationDialog>
				</CardFooter>
			</Card>
		);
	};

	const handleTabChange = (value) => {
		if (value === 'system-status') {
			setShouldFetchSystemStatus(true);
		}
	};

	const { data: systemStatusData, isLoading: isSystemStatusLoading } = useQuery({
		queryKey: ['system-status'],
		queryFn: SETUP_API.GET_SYSTEM_STATUS.FN,
		enabled: shouldFetchSystemStatus,
	});

	const formatValue = (value) => {
		if (typeof value === 'object' && value !== null) {
			if (value.data !== undefined && value.index !== undefined) {
				return `${(value.data + value.index).toFixed(2)}MB`;
			}
			return JSON.stringify(value);
		}
		return value?.toString() || 'Not available';
	};

	const analyzeSystemStatus = (status) => {
		const analysis = {
			outdatedTemplates: [],
			outdatedPlugins: [],
			outdatedTheme: null,
			phpVersion: null,
			wordpressVersion: null,
			woocommerceVersion: null,
			issues: [],
			wcDatabaseVersion: '',
			databasePrefix: '',
			databaseSize: '',
			databaseTables: [],
			largestTable: { name: '', size: '' },
		};

		if (
			status.theme &&
			status.theme.version !== status.theme.version_latest
		) {
			analysis.outdatedTheme = `${status.theme.name} (${status.theme.version} -> ${status.theme.version_latest})`;
		}

		if (status.active_plugins) {
			status.active_plugins.forEach((plugin) => {
				if (plugin.version !== plugin.version_latest) {
					analysis.outdatedPlugins.push(
						`${plugin.name} (${plugin.version} -> ${plugin.version_latest})`
					);
				}
				if (plugin.name === 'WooCommerce') {
					analysis.woocommerceVersion = plugin.version;
				}
			});
		}

		analysis.phpVersion =
			status.environment && status.environment.php_version;
		analysis.wordpressVersion =
			status.environment && status.environment.wp_version;

		// Add database analysis
		if (status.database) {
			analysis.wcDatabaseVersion =
				status.database.wc_database_version || '';
			analysis.databasePrefix = status.database.database_prefix || '';
			analysis.databaseSize = status.database.database_size || '';

			if (typeof status.database.tables === 'string') {
				const tables = status.database.tables
					.split('\n')
					.filter((line) => line.includes(': Data:'));
				analysis.databaseTables = tables.map((table) => {
					const [name, details] = table.split(': ');
					const [data, index] = details.split(' + ');
					const dataSize = parseFloat(data.split(': ')[1]);
					const indexSize = parseFloat(index.split(': ')[1]);
					const totalSize = dataSize + indexSize;
					return {
						name,
						size: `${totalSize.toFixed(2)}MB`,
					};
				});
				analysis.largestTable = analysis.databaseTables.reduce(
					(largest, table) =>
						parseFloat(table.size) > parseFloat(largest.size)
							? table
							: largest,
					{ name: '', size: '0MB' }
				);
			}
		}

		return analysis;
	};

	useEffect(() => {
		if (systemStatusData) {
			setSystemStatus(JSON.stringify(systemStatusData, null, 2));
			setSystemAnalysis(analyzeSystemStatus(systemStatusData));
		}
	}, [systemStatusData]);

	const handleCopySystemStatus = () => {
		navigator.clipboard.writeText(systemStatus);
		setIsCopied(true);
		toast({
			title: 'Copied!',
			description: 'System status has been copied to clipboard.',
		});
		setTimeout(() => setIsCopied(false), 2000);
	};

	const toolSections = [
		{
			value: 'cache',
			label: 'Cache & Data',
			tools: [
				{
					title: 'Clear WooCommerce Transients',
					description:
						'This tool will clear the product/shop transients cache.',
					buttonText: 'Clear Transients',
					icon: RefreshCw,
					toolId: 'clear_transients',
				},
				{
					title: 'Clear Expired Transients',
					description:
						'This tool will clear ALL expired transients from WordPress.',
					buttonText: 'Clear Expired',
					icon: Trash2,
					toolId: 'clear_expired_transients',
				},
				{
					title: 'Clear Customer Sessions',
					description:
						'This tool will delete all customer session data from the database, including current carts and saved carts.',
					buttonText: 'Clear Sessions',
					icon: Trash2,
					warning: 'This will delete all customer session data.',
					toolId: 'clear_sessions',
				},
				{
					title: 'Clear Template Cache',
					description: 'This tool will empty the template cache.',
					buttonText: 'Clear Cache',
					icon: RefreshCw,
					toolId: 'clear_template_cache',
				},
				{
					title: 'Clear Analytics Cache',
					description:
						'This tool will reset the cached values used in WooCommerce Analytics.',
					buttonText: 'Clear Analytics Cache',
					icon: RefreshCw,
					toolId: 'clear_woocommerce_analytics_cache',
				},
			],
		},
		{
			value: 'products',
			label: 'Products & Orders',
			tools: [
				{
					title: 'Delete Orphaned Variations',
					description:
						'This tool will delete all variations which have no parent.',
					buttonText: 'Delete Orphaned',
					icon: Trash2,
					toolId: 'delete_orphaned_variations',
				},
				{
					title: 'Delete Used-up Download Permissions',
					description:
						'This tool will delete expired download permissions and permissions with 0 remaining downloads.',
					buttonText: 'Delete Permissions',
					icon: Trash2,
					toolId: 'clear_expired_download_permissions',
				},
				{
					title: 'Regenerate Product Lookup Tables',
					description:
						'This tool will regenerate product lookup table data. This process may take a while.',
					buttonText: 'Regenerate Tables',
					icon: Database,
					toolId: 'regenerate_product_lookup_tables',
				},
				{
					title: 'Recount Product Terms',
					description:
						'This tool will recount product terms - useful when changing settings that hide products from the catalog.',
					buttonText: 'Recount Terms',
					icon: RefreshCw,
					toolId: 'recount_terms',
				},
				{
					title: 'Regenerate Shop Thumbnails',
					description:
						'This will regenerate all shop thumbnails to match your theme and/or image settings.',
					buttonText: 'Regenerate Thumbnails',
					icon: ShoppingBag,
					toolId: 'regenerate_thumbnails',
				},
				{
					title: 'Regenerate Attribute Lookup Table',
					description:
						'This tool will regenerate the product attributes lookup table data from existing product(s) data.',
					buttonText: 'Regenerate Attributes',
					icon: Database,
					toolId: 'regenerate_product_attributes_lookup_table',
				},
			],
		},
		{
			value: 'database',
			label: 'Database',
			tools: [
				{
					title: 'Update Database',
					description:
						'Update your WooCommerce database to the latest version.',
					buttonText: 'Update Database',
					icon: Database,
					warning:
						'Please ensure you make sufficient backups before proceeding.',
					toolId: 'db_update_routine',
				},
				{
					title: 'Verify Base Database Tables',
					description:
						'Verify if all base database tables are present.',
					buttonText: 'Verify Tables',
					icon: Database,
					toolId: 'verify_db_tables',
				},
				{
					title: 'Clean Up Legacy Order Data',
					description:
						'This tool will clear the data from legacy order tables in WooCommerce.',
					buttonText: 'Clean Up Data',
					icon: Trash2,
					toolId: 'hpos_legacy_cleanup',
				},
				{
					title: 'Delete Custom Orders Tables',
					description:
						'Delete the custom orders tables. Only possible if High-Performance order storage is not authoritative and sync is disabled.',
					buttonText: 'Delete Tables',
					icon: Trash2,
					warning: 'This action cannot be undone.',
					toolId: 'delete_custom_orders_table',
				},
				{
					title: 'Convert Order Coupon Data',
					description:
						'Convert coupon_data order item meta entries to simplified coupon_info entries.',
					buttonText: 'Convert Data',
					icon: RefreshCw,
					toolId: 'start_convert_order_coupon_data',
				},
			],
		},
		{
			value: 'system',
			label: 'System',
			tools: [
				{
					title: 'Reset User Capabilities',
					description:
						'Reset the admin, customer and shop_manager roles to default.',
					buttonText: 'Reset Capabilities',
					icon: RefreshCw,
					toolId: 'reset_roles',
				},
				{
					title: 'Create Default WooCommerce Pages',
					description:
						'Install all the missing WooCommerce pages. Existing pages will not be replaced.',
					buttonText: 'Create Pages',
					icon: FileText,
					toolId: 'install_pages',
				},
				{
					title: 'Delete All Tax Rates',
					description: 'Delete ALL of your tax rates.',
					buttonText: 'Delete Tax Rates',
					icon: Trash2,
					warning: 'This action cannot be reversed.',
					toolId: 'delete_taxes',
				},
				{
					title: 'Schedule Transient Cleanup',
					description:
						'Schedule the action to delete expired transient files to run immediately.',
					buttonText: 'Schedule Cleanup',
					icon: Zap,
					toolId: 'schedule_expired_transient_files_cleanup',
				},
			],
		},
	];

	return (
		<div className=' p-4 max-w-4xl'>
			{isProcessing && (
				<div className='fixed inset-0 bg-black/50 flex items-center justify-center z-50'>
					<Card className='w-[350px]'>
						<CardHeader>
							<CardTitle>Processing</CardTitle>
							<CardDescription>
								Please wait while the operation completes.
							</CardDescription>
						</CardHeader>
						<CardContent>
							<Progress value={progress} className='w-full' />
						</CardContent>
					</Card>
				</div>
			)}

			<Tabs
				defaultValue='cache'
				className='space-y-4'
				onValueChange={handleTabChange}>
				<TabsList className='grid w-full grid-cols-1 md:grid-cols-5'>
					{toolSections.map((section) => (
						<TabsTrigger key={section.value} value={section.value}>
							{section.label}
						</TabsTrigger>
					))}
					<TabsTrigger value='system-status'>
						System Status
					</TabsTrigger>
				</TabsList>

				{toolSections.map((section) => (
					<TabsContent key={section.value} value={section.value}>
						<div className='grid gap-4 md:grid-cols-2'>
							{section.tools.map((tool) => (
								<ToolCard key={tool.toolId} {...tool} />
							))}
						</div>
						{section.value === 'database' && (
							<Card className='mt-8'>
								<CardHeader>
									<CardTitle className='flex items-center text-xl'>
										Regenerate Product Attribute Lookup
										Table
									</CardTitle>
									<CardDescription>
										Select a product to regenerate the data
										for, or leave empty for a full table
										regeneration.
									</CardDescription>
								</CardHeader>
								<CardContent>
									<div className='flex space-x-2'>
										<Input placeholder='Product ID (optional)' />
										<Button
											onClick={() => {
												console.log(
													'Regenerating product attribute lookup table'
												);
												simulateProcess();
											}}>
											Regenerate
										</Button>
									</div>
								</CardContent>
							</Card>
						)}
					</TabsContent>
				))}

				<TabsContent value='system-status'>
					<Card>
						<CardHeader className='flex flex-row items-center justify-between space-y-0 pb-2'>
							<div className='flex flex-col gap-y-1'>
								<CardTitle>System Status</CardTitle>
								<CardDescription>
									View the current status of your system.
								</CardDescription>
							</div>
							<Button
								onClick={handleCopySystemStatus}
								disabled={ APP_DEMO_MODE || isCopied || isSystemStatusLoading }>
								{isCopied ? (
									<>
										<CheckCircle className='mr-2 h-4 w-4' />
										Copied!
									</>
								) : (
									<>
										<Copy className='mr-2 h-4 w-4' />
										Copy to Clipboard
									</>
								)}
							</Button>
						</CardHeader>
						<CardContent>
							{isSystemStatusLoading ? (
								<div className='space-y-2'>
									<Skeleton className='h-4 w-full' />
									<Skeleton className='h-4 w-full' />
									<Skeleton className='h-4 w-full' />
									<Skeleton className='h-4 w-3/4' />
								</div>
							) : (
								! APP_DEMO_MODE ? (
									<Textarea
										value={systemStatus}
									readOnly
										className='h-[400px] font-mono text-sm'
									/>
								) : (
									<div className='text-center text-sm text-muted-foreground p-4'>
										Demo mode is enabled. The status
										information is not available in this mode.
										However, in live mode you can view the
										system status and copy it to your clipboard
										by clicking the button above.
									</div>
								)
							)}
						</CardContent>
					</Card>

					{systemAnalysis && (
						<Card className='mt-4'>
							<CardHeader>
								<CardTitle>System Analysis</CardTitle>
								<CardDescription>
									Analysis of your system status and potential
									issues
								</CardDescription>
							</CardHeader>
							<CardContent>
								{ ! APP_DEMO_MODE ? (
									<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
										<div className='space-y-4'>
										<div>
											<h3 className='font-semibold'>
												Versions:
											</h3>
											<p>
												PHP:{' '}
												{formatValue(
													systemAnalysis.phpVersion
												)}
											</p>
											<p>
												WordPress:{' '}
												{formatValue(
													systemAnalysis.wordpressVersion
												)}
											</p>
											<p>
												WooCommerce:{' '}
												{formatValue(
													systemAnalysis.woocommerceVersion
												)}
											</p>
										</div>
										{systemAnalysis.outdatedTheme && (
											<div>
												<h3 className='font-semibold'>
													Outdated Theme:
												</h3>
												<p>
													{formatValue(
														systemAnalysis.outdatedTheme
													)}
												</p>
											</div>
										)}
										{systemAnalysis.outdatedPlugins.length >
											0 && (
											<div>
												<h3 className='font-semibold'>
													Outdated Plugins:
												</h3>
												<ul className='list-disc pl-5'>
													{systemAnalysis.outdatedPlugins.map(
														(plugin, index) => (
															<li key={index}>
																{formatValue(
																	plugin
																)}
															</li>
														)
													)}
												</ul>
											</div>
										)}
									</div>
									<div className='space-y-4'>
										<div>
											<h3 className='font-semibold'>
												Database Information:
											</h3>
											<p>
												WooCommerce Database Version:{' '}
												{formatValue(
													systemAnalysis.wcDatabaseVersion
												)}
											</p>
											<p>
												Database Prefix:{' '}
												{formatValue(
													systemAnalysis.databasePrefix
												)}
											</p>
											<p>
												Total Database Size:{' '}
												{formatValue(
													systemAnalysis.databaseSize
												)}
											</p>
										</div>
										{systemAnalysis.largestTable &&
											systemAnalysis.largestTable
												.name && (
												<div>
													<h3 className='font-semibold'>
														Largest Table:
													</h3>
													<p>
														Name:{' '}
														{formatValue(
															systemAnalysis
																.largestTable
																.name
														)}
													</p>
													<p>
														Size:{' '}
														{formatValue(
															systemAnalysis
																.largestTable
																.size
														)}
													</p>
												</div>
											)}
									</div>
								</div>
								) : (
									<div className='text-center text-sm text-muted-foreground p-4'>
										Demo mode is enabled. The system analysis
										is not available in this mode. In
										live mode you can view the system analysis
										in this zone.
									</div>
								)}
							</CardContent>
						</Card>
					)}
				</TabsContent>
			</Tabs>
		</div>
	);
}