import React from "react";
import { useFormikContext, FieldArray } from "formik";
import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";

const FormFileUploadArray = ({
  name,
  label,
  labelColor = "text-gray-700",
  layout = "row",
  labelClassName = "",
  inputClassName = "",
  buttonClassName = "",
}) => {
  const { values, setFieldValue } = useFormikContext();

  const handleFileChoose = (index) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.style.display = "none";
    fileInput.onchange = (event) => {
      const file = event.currentTarget.files[0];
      if (file) {
        const newFiles = [...values[name]];
        newFiles[index] = { file, name: file.name };
        setFieldValue(name, newFiles);
      }
    };
    fileInput.click();
  };

  const containerClassName = `flex ${
    layout === "row" ? "flex-row" : "flex-col"
  } gap-3 items-${layout === "row" ? "center" : "start"}`;

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <div className={containerClassName}>
          {label && (
            <Label
              className={`${
                layout === "row" ? "w-[300px]" : "w-full"
              } ${labelColor} ${labelClassName}`}
            >
              {label}
            </Label>
          )}
          <div className={layout === "row" ? "w-full" : "w-full"}>
            {values[name] && values[name].length > 0
              ? values[name].map((file, index) => (
                  <div key={index} className="flex items-center gap-2 mb-2">
                    <Input
                      type="text"
                      readOnly
                      value={file.name || ""}
                      placeholder="No file chosen"
                      className={`flex-grow ${inputClassName}`}
                    />
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => handleFileChoose(index)}
                      className={buttonClassName}
                    >
                      Choose file
                    </Button>
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => arrayHelpers.remove(index)}
                      className={buttonClassName}
                    >
                      X
                    </Button>
                  </div>
                ))
              : null}
            <Button
              type="button"
              onClick={() => arrayHelpers.push({ file: null, name: "" })}
              className={`mt-2 ${buttonClassName}`}
            >
              Add new file
            </Button>
          </div>
        </div>
      )}
    />
  );
};

export default FormFileUploadArray;
