import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { COUPON_API } from "api/discounts";
import { useDisclosure } from "hooks/useDisclosure";
import SearchAndActions from "./_ui/searchAction";
import DiscountTable from "./_ui/discountTable";
import DiscountSheet from "./_ui/discountSheet";
import { addDays } from "date-fns";

export default function Discounts() {
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [loading, setLoading] = useState(false); // eslint-disable-line
  const [page, setPage] = useState(1);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    orderBy: "date",
    order: "desc",
    per_page: 10,
    dateRange: {
      from: new Date(),
      to: addDays(new Date(), 30),
    },
    status: "all",
  });

  const dateRange = `${filters.dateRange.from.toISOString()},${filters.dateRange.to.toISOString()}`;

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const newDiscountState = useDisclosure();
  const dropdownState = useDisclosure();

  const couponQuery = useQuery({
    queryFn: () =>
      COUPON_API.GET_ALL_COUPONS.FN({
        params: { page, search, ...filters, dateRange },
      }),
    queryKey: [COUPON_API.GET_ALL_COUPONS.KEY, page, search, filters],
  });

  const handleEditClick = (discount) => {
    setSelectedDiscount(discount);
    setIsSheetOpen(true);
  };

  const handleSheetClose = () => {
    setIsSheetOpen(false);
    setSelectedDiscount(null);
  };

  return (
    <div className="w-full">
      <SearchAndActions
        newDiscountState={newDiscountState}
        loading={loading}
        selectedRowIds={selectedRowIds}
        setSearch={setSearch}
        dropdownState={dropdownState}
        filters={filters}
        setFilters={setFilters}
      />

      <DiscountTable
        data={couponQuery.data || []}
        isLoading={couponQuery.isLoading}
        isError={couponQuery.isError}
        error={couponQuery.error}
        statusFilter={filters.status}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        page={page}
        per_page={filters.per_page}
        handleEditClick={handleEditClick}
        setSelectedRowIds={setSelectedRowIds}
        selectedRowIds={selectedRowIds}
        setPage={setPage}
        total={couponQuery.data?.length || 0}
      />

      {isSheetOpen && (
        <DiscountSheet
          isOpen={isSheetOpen}
          onClose={handleSheetClose}
          discount={selectedDiscount}
          isEdit={true}
          page={page}
        />
      )}
      <DiscountSheet
        isOpen={newDiscountState.isOpen}
        onClose={newDiscountState.onClose}
        isEdit={false}
        page={page}
      />
    </div>
  );
}
