import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PRODUCT_API } from 'api/product';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from 'components/ui/table';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import { Label } from 'components/ui/label';
import { DollarSign, Package, Tag, BarChart, Eye, CalendarIcon } from 'lucide-react';
import { LoadingSpinner } from 'components/ui/loadingSpinner';
import MultiSelect from 'components/ui/multi-select';
import { format } from "date-fns";
import { useToast } from 'components/ui/use-toast';

import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/ui/popover";
import { Calendar } from "components/ui/calendar";

export default function ProductsBulkEdit({ products: initialProducts, refetchProducts, onClose }) {
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState('');
	const [hasMore, setHasMore] = useState(true);
	const [filters, setFilters] = useState({
		orderby: undefined,
		order: undefined,
		per_page: 10,
		status: undefined,
	});

	const {
		data: productsData,
		isLoading,
		isError,
		refetch,
	} = useQuery({
		queryKey: [PRODUCT_API.GET_ALL_PRODUCTS.KEY, page, search, filters],
		queryFn: () =>
			PRODUCT_API.GET_ALL_PRODUCTS.FN({
				params: { page, search, ...filters },
			}),
		keepPreviousData: true,
	});

	const { data: categoriesData } = useQuery({
		queryKey: [PRODUCT_API.GET_PRODUCT_CATEGORIES.KEY],
		queryFn: PRODUCT_API.GET_PRODUCT_CATEGORIES.FN,
	});

	const { data: tagsData } = useQuery({
		queryKey: [PRODUCT_API.GET_PRODUCT_TAGS.KEY],
		queryFn: PRODUCT_API.GET_PRODUCT_TAGS.FN,
	});

	const [products, setProducts] = useState(initialProducts);
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [bulkAction, setBulkAction] = useState('');
	const [bulkActionValues, setBulkActionValues] = useState({
		priceChangeType: 'increase',
		priceChangeMethod: 'percentage',
		priceChangeAmount: '',
		category: '',
		tags: [],
		stockQuantity: '',
		manageStock: false,
		visibility: '',
		isDownloadable: false,
		isVirtual: false,
		taxStatus: '',
		shippingClass: '',
		backorders: '',
		featured: false,
		reviewsAllowed: true,
		soldIndividually: false,
	});

	const [isSaving, setIsSaving] = useState(false);

	const { toast } = useToast();

	useEffect(() => {
		if (productsData) {
			setProducts((prevProducts) => {
				// Combine previous products with new ones, avoiding duplicates
				const newProducts = [...prevProducts];
				productsData.forEach((product) => {
					if (!newProducts.some((p) => p.id === product.id)) {
						newProducts.push(product);
					}
				});
				return newProducts;
			});
			setHasMore(productsData.length === filters.per_page);
		}
	}, [productsData]);

	const handleChange = (id, field, value) => {
		setProducts((prevProducts) =>
			prevProducts.map((product) =>
				product.id === id ? { ...product, [field]: value } : product
			)
		);
	};

	const handleSelectProduct = (id) => {
		setSelectedProducts((prevSelected) =>
			prevSelected.includes(id)
				? prevSelected.filter((productId) => productId !== id)
				: [...prevSelected, id]
		);
	};

	const handleSelectAll = () => {
		setSelectedProducts(
			selectedProducts.length === products.length
				? []
				: products.map((p) => p.id)
		);
	};

	const handleBulkActionValueChange = (field, value) => {
		setBulkActionValues((prevValues) => ({
			...prevValues,
			[field]: value,
		}));
	};

	const handleApplyChanges = async () => {
		const changedProducts = products.filter(product => {
			const originalProduct = initialProducts.find(p => p.id === product.id);
			return JSON.stringify(product) !== JSON.stringify(originalProduct);
		});

		if (changedProducts.length === 0) {
			toast({
				title: "No Changes",
				description: "No changes detected. Nothing to update.",
				variant: "default",
			});
			return;
		}

		setIsSaving(true);

		try {
			for (let i = 0; i < changedProducts.length; i++) {
				await PRODUCT_API.UPDATE_PRODUCT.FN({
					id: changedProducts[i].id,
					data: changedProducts[i],
				});
			}

			refetchProducts();
			toast({
				title: "Success",
				description: "Products updated successfully.",
				variant: "default",
			});
		} catch (error) {
			console.error('Error updating products:', error);
			toast({
				title: "Error",
				description: "Failed to update products. Please try again.",
				variant: "destructive",
			});
		} finally {
			setIsSaving(false);
		}
	};

	const handleApplyBulkAction = () => {
		if (!bulkAction) {
			toast({
				title: "Action Required",
				description: "Please select a bulk action.",
				variant: "default",
			});
			return;
		}

		if (selectedProducts.length === 0) {
			toast({
				title: "Selection Required",
				description: "No products selected for update.",
				variant: "default",
			});
			return;
		}

		let updatedProducts = [...products];

		switch (bulkAction) {
			case 'changePrice': {
				const { priceChangeType, priceChangeMethod, priceChangeAmount } = bulkActionValues;
				updatedProducts = updatedProducts.map((product) => {
					if (!selectedProducts.includes(product.id)) return product;

					const currentPrice = parseFloat(product.regular_price) || 0;
					let newPrice = currentPrice;

					if (priceChangeMethod === 'percentage') {
						const changePercentage = parseFloat(priceChangeAmount) / 100;
						newPrice = priceChangeType === 'increase' 
							? currentPrice * (1 + changePercentage)
							: currentPrice * (1 - changePercentage);
					} else {
						const changeAmount = parseFloat(priceChangeAmount);
						newPrice = priceChangeType === 'increase'
							? currentPrice + changeAmount
							: currentPrice - changeAmount;
					}

					return { ...product, regular_price: newPrice.toFixed(2) };
				});
				break;
			}

			case 'setCategory':
				updatedProducts = updatedProducts.map((product) => {
					if (!selectedProducts.includes(product.id)) return product;

					return {
						...product,
						categories: [
							{ id: parseInt(bulkActionValues.category) },
						],
					};
				});
				break;

			case 'setTags':
				updatedProducts = updatedProducts.map((product) => {
					if (!selectedProducts.includes(product.id)) return product;

					return {
						...product,
						tags: bulkActionValues.tags.map((tagId) => ({
							id: parseInt(tagId),
						})),
					};
				});
				break;

			case 'setStock':
				updatedProducts = updatedProducts.map((product) => {
					if (!selectedProducts.includes(product.id)) return product;

					return {
						...product,
						stock_quantity: parseInt(
							bulkActionValues.stockQuantity
						),
						manage_stock: bulkActionValues.manageStock,
					};
				});
				break;

			case 'setVisibility':
				updatedProducts = updatedProducts.map((product) => {
					if (!selectedProducts.includes(product.id)) return product;

					return {
						...product,
						catalog_visibility: bulkActionValues.visibility,
					};
				});
				break;

			case 'setDownloadable':
				updatedProducts = updatedProducts.map((product) => {
					if (!selectedProducts.includes(product.id)) return product;

					return {
						...product,
						downloadable: bulkActionValues.isDownloadable,
					};
				});
				break;

			case 'setVirtual':
				updatedProducts = updatedProducts.map((product) => {
					if (!selectedProducts.includes(product.id)) return product;

					return {
						...product,
						virtual: bulkActionValues.isVirtual,
					};
				});
				break;

			case 'setTaxStatus':
				updatedProducts = updatedProducts.map((product) => {
					if (!selectedProducts.includes(product.id)) return product;
					return { ...product, tax_status: bulkActionValues.taxStatus };
				});
				break;

			case 'setShippingClass':
				updatedProducts = updatedProducts.map((product) => {
					if (!selectedProducts.includes(product.id)) return product;
					return { ...product, shipping_class: bulkActionValues.shippingClass };
				});
				break;

			case 'setBackorders':
				updatedProducts = updatedProducts.map((product) => {
					if (!selectedProducts.includes(product.id)) return product;
					return { ...product, backorders: bulkActionValues.backorders };
				});
				break;

			case 'setFeatured':
				updatedProducts = updatedProducts.map((product) => {
					if (!selectedProducts.includes(product.id)) return product;
					return { ...product, featured: bulkActionValues.featured };
				});
				break;

			case 'setReviewsAllowed':
				updatedProducts = updatedProducts.map((product) => {
					if (!selectedProducts.includes(product.id)) return product;
					return { ...product, reviews_allowed: bulkActionValues.reviewsAllowed };
				});
				break;

			case 'setSoldIndividually':
				updatedProducts = updatedProducts.map((product) => {
					if (!selectedProducts.includes(product.id)) return product;
					return { ...product, sold_individually: bulkActionValues.soldIndividually };
				});
				break;

			default:
				break;
		}

		setProducts(updatedProducts);
		toast({
			title: "Bulk Action Applied",
			description: "Bulk action applied. Click 'Save Changes' to save.",
			variant: "default",
		});
	};

	const renderBulkActionFields = () => {
		switch (bulkAction) {
			case 'changePrice':
				return (
					<div className="inline-flex mt-4 space-x-4">
						<Select
							value={bulkActionValues.priceChangeType}
							onValueChange={(value) => handleBulkActionValueChange('priceChangeType', value)}
						>
							<SelectTrigger>
								<SelectValue placeholder="Select price change type" />
							</SelectTrigger>
							<SelectContent>
								<SelectItem value="increase">Increase price</SelectItem>
								<SelectItem value="decrease">Decrease price</SelectItem>
							</SelectContent>
						</Select>
						<Select
							value={bulkActionValues.priceChangeMethod}
							onValueChange={(value) => handleBulkActionValueChange('priceChangeMethod', value)}
						>
							<SelectTrigger>
								<SelectValue placeholder="Select method" />
							</SelectTrigger>
							<SelectContent>
								<SelectItem value="percentage">By percentage</SelectItem>
								<SelectItem value="fixed">By fixed amount</SelectItem>
							</SelectContent>
						</Select>
						<Input
							type="number"
							
							placeholder={bulkActionValues.priceChangeMethod === 'percentage' ? 'Enter %' : 'Enter amount'}
							value={bulkActionValues.priceChangeAmount}
							onChange={(e) => handleBulkActionValueChange('priceChangeAmount', e.target.value)}
						/>
					</div>
				);

			case 'setCategory':
				return (
					<MultiSelect
						options={categoriesData?.map((category) => ({
							value: category.id.toString(),
							label: category.name,
						})) || []}
						defaultValue={bulkActionValues.category || []}
						onValueChange={(value) =>
							handleBulkActionValueChange('category', value)
						}
						placeholder="Select categories"
						className="w-[200px]"
					/>
				);

			case 'setTags':
				return (
					<MultiSelect
						options={tagsData?.map((tag) => ({
							value: tag.id.toString(),
							label: tag.name,
						})) || []}
						defaultValue={bulkActionValues.tags || []}
						onValueChange={(value) =>
							handleBulkActionValueChange('tags', value)
						}
						placeholder="Select tags"
						className="w-[300px]"
					/>
				);

			case 'setStock':
				return (
					<div className='flex space-x-4 items-center'>
						<Input
							type='number'
							placeholder='Enter stock quantity'
							value={bulkActionValues.stockQuantity}
							onChange={(e) =>
								handleBulkActionValueChange(
									'stockQuantity',
									e.target.value
								)
							}
							className='w-[150px]'
						/>
						<div className='flex items-center space-x-2 w-[150px]'>
							<Checkbox
								id='manage-stock'
								checked={bulkActionValues.manageStock}
								onCheckedChange={(checked) =>
									handleBulkActionValueChange(
										'manageStock',
										checked
									)
								}
							/>
							<Label htmlFor='manage-stock'>Manage stock</Label>
							
						</div>
					</div>
				);

			case 'setVisibility':
				return (
					<Select
						value={bulkActionValues.visibility}
						onValueChange={(value) =>
							handleBulkActionValueChange('visibility', value)
						}>
						<SelectTrigger className='w-[200px]'>
							<SelectValue placeholder='Select visibility' />
						</SelectTrigger>
						<SelectContent>
							<SelectItem value='visible'>Visible</SelectItem>
							<SelectItem value='catalog'>
								Catalog only
							</SelectItem>
							<SelectItem value='search'>Search only</SelectItem>
							<SelectItem value='hidden'>Hidden</SelectItem>
						</SelectContent>
					</Select>
				);

			case 'setDownloadable':
				return (
					<Checkbox
						checked={bulkActionValues.isDownloadable}
						onCheckedChange={(checked) =>
							handleBulkActionValueChange('isDownloadable', checked)
						}
					/>
				);

			case 'setVirtual':
				return (
					<Checkbox
						checked={bulkActionValues.isVirtual}
						onCheckedChange={(checked) =>
							handleBulkActionValueChange('isVirtual', checked)
						}
					/>
				);

			case 'setTaxStatus':
				return (
					<Select
						value={bulkActionValues.taxStatus}
						onValueChange={(value) =>
							handleBulkActionValueChange('taxStatus', value)
						}
					>
						<SelectTrigger className="w-[150px]">
							<SelectValue placeholder="Select tax status" />
						</SelectTrigger>
						<SelectContent>
							<SelectItem value="taxable">Taxable</SelectItem>
							<SelectItem value="shipping">Shipping only</SelectItem>
							<SelectItem value="none">None</SelectItem>
						</SelectContent>
					</Select>
				);

			case 'setShippingClass':
				return (
					<Input
						value={bulkActionValues.shippingClass || ''}
						onChange={(e) => handleBulkActionValueChange('shippingClass', e.target.value)}
						placeholder="Enter shipping class"
					/>
				);

			case 'setBackorders':
				return (
					<Select
						value={bulkActionValues.backorders || ''}
						onValueChange={(value) => handleBulkActionValueChange('backorders', value)}
					>
						<SelectTrigger className="w-[200px]">
							<SelectValue placeholder="Select backorder status" />
						</SelectTrigger>
						<SelectContent>
							<SelectItem value="no">Do not allow</SelectItem>
							<SelectItem value="notify">Allow, but notify customer</SelectItem>
							<SelectItem value="yes">Allow</SelectItem>
						</SelectContent>
					</Select>
				);

			case 'setFeatured':
				return (
					<Checkbox
						checked={bulkActionValues.featured || false}
						onCheckedChange={(checked) => handleBulkActionValueChange('featured', checked)}
					/>
				);

			case 'setReviewsAllowed':
				return (
					<Checkbox
						checked={bulkActionValues.reviewsAllowed || false}
						onCheckedChange={(checked) => handleBulkActionValueChange('reviewsAllowed', checked)}
					/>
				);

			case 'setSoldIndividually':
				return (
					<Checkbox
						checked={bulkActionValues.soldIndividually || false}
						onCheckedChange={(checked) => handleBulkActionValueChange('soldIndividually', checked)}
					/>
				);

			default:
				return null;
		}
	};

	const handleLoadMore = () => {
		setPage((prevPage) => prevPage + 1);
	};

	if (isLoading && page === 1) return <LoadingSpinner />;
	if (isError) return <div>Error loading products.</div>;


	return (
		<div className="w-full h-full flex flex-col">
			<div className="flex-grow overflow-auto">
				<div className='p-6'>
					<div className='mb-6 p-4 border rounded-md bg-gray-50'>
						<h2 className='text-xl font-semibold mb-4'>Bulk Actions</h2>
						<div className='flex space-x-4 items-end'>
							<div>
								<Label htmlFor='bulk-action' className='mb-2 block'>
									Select Action
								</Label>
								<Select
									value={bulkAction}
									onValueChange={setBulkAction}>
									<SelectTrigger
										id='bulk-action'
										className='w-[200px]'>
										<SelectValue placeholder='Select action' />
									</SelectTrigger>
									<SelectContent>
										<SelectItem value='changePrice'>
											Change Price
										</SelectItem>
										<SelectItem value='setVisibility'>
											Set Visibility
										</SelectItem>
										<SelectItem value='setDownloadable'>
											Set is Downloadable?
										</SelectItem>
										<SelectItem value='setVirtual'>
											Set is Virtual?
										</SelectItem>
										<SelectItem value='setTaxStatus'>
											Set Tax Status
										</SelectItem>
										<SelectItem value="setShippingClass">Set Shipping Class</SelectItem>
										<SelectItem value="setBackorders">Set Backorders</SelectItem>
										<SelectItem value="setFeatured">Set Featured</SelectItem>
										<SelectItem value="setReviewsAllowed">Set Reviews Allowed</SelectItem>
										<SelectItem value="setSoldIndividually">Set Sold Individually</SelectItem>
									</SelectContent>
								</Select>
							</div>
							{renderBulkActionFields()}
							<Button onClick={handleApplyBulkAction}>
								Apply to Selected
							</Button>
						</div>
					</div>

					<div className='overflow-x-auto'>
						<Table>
							<TableHeader>
								<TableRow>
									<TableHead className='w-[50px]'>
										<Checkbox
											checked={
												selectedProducts.length ===
												products.length
											}
											onCheckedChange={handleSelectAll}
											aria-label='Select all products'
											className='m-4'
										/>
									</TableHead>
									<TableHead className='w-[200px]'>
										Product
									</TableHead>
									<TableHead className='w-[150px]'>
										Type
									</TableHead>
									<TableHead>
										<div className='flex items-center'>
											<DollarSign className='w-4 h-4 mr-2' />
											Price
										</div>
									</TableHead>
									<TableHead>
										<div className='flex items-center'>
											<Package className='w-4 h-4 mr-2' />
											Category
										</div>
									</TableHead>
									<TableHead>
										<div className='flex items-center'>
											<Tag className='w-4 h-4 mr-2' />
											Tags
										</div>
									</TableHead>
									<TableHead>
										<div className='flex items-center w-[130px]'>
											<BarChart className='w-4 h-4 mr-2' />
											Stock
										</div>
									</TableHead>
									<TableHead>
										<div className='flex items-center'>
											<Eye className='w-4 h-4 mr-2' />
											Visibility
										</div>
									</TableHead>
									<TableHead className="min-w-[100px]">Downloadable</TableHead>
									<TableHead className="min-w-[100px]">Virtual</TableHead>
									<TableHead className="min-w-[150px]">Tax Status</TableHead>
									<TableHead className="min-w-[150px]">Shipping Class</TableHead>
									<TableHead className="min-w-[150px]">Backorders</TableHead>
									<TableHead className="min-w-[100px]">Featured</TableHead>
									<TableHead className="min-w-[100px]">Reviews</TableHead>
									<TableHead>Sold Individually</TableHead>
								</TableRow>
							</TableHeader>
							<TableBody>
								{products.map((product) => (
									<TableRow key={product.id}>
										<TableCell>
											<Checkbox
												checked={selectedProducts.includes(
													product.id
												)}
												onCheckedChange={() =>
													handleSelectProduct(product.id)
												}
												aria-label={`Select ${product.name}`}
											/>
										</TableCell>
										<TableCell>{product.name}</TableCell>
										<TableCell>
											<Select
												value={product.type || ''}
												onValueChange={(value) => handleChange(product.id, 'type', value)}
											>
												<SelectTrigger className='w-[150px]'>
													<SelectValue placeholder='Select type' />
												</SelectTrigger>
												<SelectContent>
													<SelectItem value='simple'>Simple</SelectItem>
													<SelectItem value='variable'>Variable</SelectItem>
													<SelectItem value='grouped'>Grouped</SelectItem>
													<SelectItem value='external'>External</SelectItem>
												</SelectContent>
											</Select>
										</TableCell>
										<TableCell>
											{product.type !== 'variable' ? (
												<div className='flex flex-col space-y-2'>
													<div className='flex space-x-2 items-center'>
														<Input
															type='number'
															value={product.regular_price || ''}
															onChange={(e) =>
																handleChange(product.id, 'regular_price', e.target.value)
															}
															className='w-24'
															placeholder='Regular'
														/>
														<Input
															type='number'
															value={product.sale_price || ''}
															onChange={(e) =>
																handleChange(product.id, 'sale_price', e.target.value)
															}
															className='w-24'
															placeholder='Sale'
														/>
														<Popover>
															<PopoverTrigger asChild>
																<Button
																	variant="outline"
																	size="icon"
																	className="h-8 w-8 p-0"
																>
																	<CalendarIcon className="h-4 w-4" />
																</Button>
															</PopoverTrigger>
															<PopoverContent className="w-auto p-0" align="start">
																<div className="grid gap-4 p-4">
																	<div className="space-y-2">
																		<h4 className="font-medium leading-none">Sale Start Date</h4>
																		<Calendar
																			mode="single"
																			selected={product.date_on_sale_from ? new Date(product.date_on_sale_from) : undefined}
																			onSelect={(date) => handleChange(product.id, 'date_on_sale_from', date?.toISOString())}
																		/>
																	</div>
																	<div className="space-y-2">
																		<h4 className="font-medium leading-none">Sale End Date</h4>
																		<Calendar
																			mode="single"
																			selected={product.date_on_sale_to ? new Date(product.date_on_sale_to) : undefined}
																			onSelect={(date) => handleChange(product.id, 'date_on_sale_to', date?.toISOString())}
																		/>
																	</div>
																</div>
															</PopoverContent>
														</Popover>
													</div>
													{(product.date_on_sale_from || product.date_on_sale_to) && (
														<span className="text-xs text-muted-foreground">
															Sale period: {' '}
															{product.date_on_sale_from && format(new Date(product.date_on_sale_from), 'MM/dd/yyyy')}
															{product.date_on_sale_from && product.date_on_sale_to && ' - '}
															{product.date_on_sale_to && format(new Date(product.date_on_sale_to), 'MM/dd/yyyy')}
														</span>
													)}
												</div>
											) : (
												<span className='text-sm text-muted-foreground'>
													Set in variations
												</span>
											)}
										</TableCell>
										<TableCell>
											<MultiSelect
												options={categoriesData?.map((category) => ({
													value: category.id.toString(),
													label: category.name,
												})) || []}
												defaultValue={product.categories?.map((cat) => cat.id.toString()) || []}
												onValueChange={(newValue) =>
													handleChange(
														product.id,
														'categories',
														newValue.map((id) => ({ id: parseInt(id), name: categoriesData?.find(cat => cat.id.toString() === id)?.name }))
													)
												}
												placeholder="Select categories"
												className="w-[200px]"
											/>
										</TableCell>
										<TableCell>
											<MultiSelect
												options={tagsData?.map((tag) => ({
													value: tag.id.toString(),
													label: tag.name,
												})) || []}
												defaultValue={product.tags?.map((tag) => tag.id.toString()) || []}
												onValueChange={(newValue) =>
													handleChange(
														product.id,
														'tags',
														newValue.map((id) => ({ id: parseInt(id), name: tagsData?.find(tag => tag.id.toString() === id)?.name }))
													)
												}
												placeholder="Select tags"
												className="w-[200px]"
											/>
										</TableCell>
										<TableCell>
											<div className='space-y-2'>
												<Input
													type='number'
													value={
														product.stock_quantity !==
														null
															? product.stock_quantity
															: ''
													}
													onChange={(e) =>
														handleChange(
															product.id,
															'stock_quantity',
															e.target.value !== ''
																? parseInt(
																		e.target
																			.value
																  )
																: null
														)
													}
													className='w-24'
													placeholder='Quantity'
												/>
												<div className='flex items-center space-x-2'>
													<Checkbox
														id={`manage-stock-${product.id}`}
														checked={
															product.manage_stock
														}
														onCheckedChange={(
															checked
														) =>
															handleChange(
																product.id,
																'manage_stock',
																checked
															)
														}
													/>
													<Label
														htmlFor={`manage-stock-${product.id}`}
														className='text-xs font-normal'
														>
														Manage stock
													</Label>
												</div>
											</div>
										</TableCell>
										<TableCell>
											<Select
												value={
													product.catalog_visibility || ''
												}
												onValueChange={(value) =>
													handleChange(
														product.id,
														'catalog_visibility',
														value
													)
												}>
												<SelectTrigger className='w-[180px]'>
													<SelectValue placeholder='Select visibility' />
												</SelectTrigger>
												<SelectContent>
													<SelectItem value='visible'>
														Visible
													</SelectItem>
													<SelectItem value='catalog'>
														Catalog only
													</SelectItem>
													<SelectItem value='search'>
														Search only
													</SelectItem>
													<SelectItem value='hidden'>
														Hidden
													</SelectItem>
												</SelectContent>
											</Select>
										</TableCell>
										<TableCell>
											<Checkbox
												checked={product.downloadable || false}
												onCheckedChange={(checked) =>
													handleChange(product.id, 'downloadable', checked)
												}
											/>
										</TableCell>
										<TableCell>
											<Checkbox
												checked={product.virtual || false}
												onCheckedChange={(checked) =>
													handleChange(product.id, 'virtual', checked)
												}
											/>
										</TableCell>
										<TableCell>
											<Select
												value={product.tax_status || ''}
												onValueChange={(value) =>
													handleChange(product.id, 'tax_status', value)
												}
											>
												<SelectTrigger className="w-[150px]">
													<SelectValue placeholder="Select tax status" />
												</SelectTrigger>
												<SelectContent>
													<SelectItem value="taxable">Taxable</SelectItem>
													<SelectItem value="shipping">Shipping only</SelectItem>
													<SelectItem value="none">None</SelectItem>
												</SelectContent>
											</Select>
										</TableCell>
										<TableCell>
											<Input
												value={product.shipping_class || ''}
												onChange={(e) =>
													handleChange(product.id, 'shipping_class', e.target.value)
												}
												placeholder="Shipping class"
											/>
										</TableCell>
										<TableCell>
											<Select
												value={product.backorders || ''}
												onValueChange={(value) =>
													handleChange(product.id, 'backorders', value)
												}
											>
												<SelectTrigger className="w-[150px]">
													<SelectValue placeholder="Backorders" />
												</SelectTrigger>
												<SelectContent>
													<SelectItem value="no">Do not allow</SelectItem>
													<SelectItem value="notify">Allow, but notify customer</SelectItem>
													<SelectItem value="yes">Allow</SelectItem>
												</SelectContent>
											</Select>
										</TableCell>
										<TableCell>
											<Checkbox
												checked={product.featured || false}
												onCheckedChange={(checked) =>
													handleChange(product.id, 'featured', checked)
												}
											/>
										</TableCell>
										<TableCell>
											<Checkbox
												checked={product.reviews_allowed || false}
												onCheckedChange={(checked) =>
													handleChange(product.id, 'reviews_allowed', checked)
												}
											/>
										</TableCell>
										<TableCell>
											<Checkbox
												checked={product.sold_individually || false}
												onCheckedChange={(checked) =>
													handleChange(product.id, 'sold_individually', checked)
												}
											/>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</div>
				</div>
			</div>

			<div className='sticky gap-2 bottom-4 bg-primary ml-4 p-4 rounded-md flex justify-between items-center w-[440px]'>
				<Button
					onClick={handleApplyChanges}
					disabled={isLoading || isSaving}
					variant='outline'
					className='border-none'
				>
					{isSaving ? 'Applying Changes...' : 'Apply Changes'}
				</Button>
				{hasMore && (
					<Button
						onClick={handleLoadMore}
						disabled={isLoading}
						variant='outline'
						type='button'
						className='bg-primary-400 text-white hover:border-none'
					>
						{isLoading ? 'Loading...' : 'Load More Products'}
					</Button>
				)}
				<Button
					variant='ghost'
					type='button'
					onClick={onClose}
					className='text-white'
				>
					Cancel
				</Button>
			</div>
		</div>
	);
}
