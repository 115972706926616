import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { PRODUCT_API } from "api/product";
import { useToast } from "components/ui/use-toast";
import { Button } from "components/ui/button";
import { Card, CardContent, CardFooter, CardHeader } from "components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import {
  PackageIcon,
  DownloadIcon,
  CreditCardIcon,
  BoxIcon,
  InfoIcon,
  ImageIcon,
  SettingsIcon,
  TagIcon,
} from "lucide-react";
import Inventory from "../_ui/Inventory";
import Shipping from "../_ui/Shipping";
import LinkedProducts from "../_ui/LinkedProducts";
import Advanced from "../_ui/Advanced";
import ProductDownloads from "../_ui/ProductDownload";
// import Variants from "../_ui/Variants";
import Attributes from "../_ui/Attributes";
import { cn } from "lib/utils";
import { ScrollArea } from "components/ui/scroll-area";
import BasicProductInfo from "./_ui/BasicProductInfo";
import AdditionalProductDetails from "./_ui/AdditionalProductDetails";
import ProductImages from "./_ui/ProductImages";

export default function AddProduct() {
  const [open, setOpen] = useState(false);
  const cache = useQueryClient();
  const [step, setStep] = useState(1);
  const { toast } = useToast();
  const params = useParams();
  const productId = params.productId;

  const createProductMutation = useMutation({
    mutationFn: PRODUCT_API.ADD_PRODUCTS.FN,
    onSuccess: (data) => {
      cache.invalidateQueries([PRODUCT_API.GET_ALL_PRODUCTS.KEY]);
      toast({
        title: "Success",
        description: "Product created successfully",
      });
    },
  });

  const initialValues = {
    id: 1097,
    name: "",
    slug: "",
    permalink: "",
    date_created: new Date().toISOString(),
    date_modified: new Date().toISOString(),
    type: "simple",
    status: "draft",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "",
    price: "",
    regular_price: "",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_to: null,
    on_sale: false,
    purchasable: true,
    total_sales: 0,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: -1,
    download_expiry: -1,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: false,
    stock_quantity: null,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "",
    dimensions: { length: "", width: "", height: "" },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: true,
    average_rating: "0",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [],
    tags: [],
    images: [],
    attributes: [],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    stock_status: "instock",
    has_options: false,
    post_password: "",
    permalink_template: "",
    generated_slug: "",
  };

  const handleSubmit = (values) => {
    const formattedProduct = {
      name: values.name,
      type: values.type.value,
      status: values.status.value,
      featured: values.featured,
      catalog_visibility: values.catalog_visibility.value,
      description: values.description,
      short_description: values.short_description,
      sku: values.sku,
      price: values.price,
      regular_price: values.regular_price,
      sale_price: values.sale_price,
      date_on_sale_from: values.date_on_sale_from,
      date_on_sale_to: values.date_on_sale_to,
      virtual: values.virtual,
      downloadable: values.downloadable,
      downloads: values.downloads,
      download_limit: values.download_limit,
      download_expiry: values.download_expiry,
      external_url: values.external_url,
      button_text: values.button_text,
      tax_status: values.tax_status.value,
      tax_class: values.tax_class.value,
      manage_stock: values.manage_stock,
      stock_quantity: values.stock_quantity,
      backorders: values.backorders,
      weight: values.weight,
      dimensions: {
        length: values.dimensions.length.toString(),
        width: values.dimensions.width.toString(),
        height: values.dimensions.height.toString(),
      },
      shipping_class: values.shipping_class?.label,
      shipping_class_id: values.shipping_class?.value,
      upsell_ids: values.upsell_ids.map((upsell) => upsell.value),
      cross_sell_ids: values.cross_sell_ids.map((crossSell) => crossSell.value),
      purchase_note: values.purchase_note,
      categories: values.categories.map((category) => ({
        id: category.value,
        name: category.label,
        slug: category?.label?.toLowerCase().replace(/ /g, "-"),
      })),
      tags: values.tags.map((tag) => ({
        id: tag.value,
        name: tag?.label,
        slug: tag?.label?.toLowerCase().replace(/ /g, "-"),
      })),
      images: values.images.map((image, index) => ({
        id: image.id,
        src: image.src,
        name: image.name,
        alt: image.alt,
        position: index,
      })),
      attributes: values.attributes,
      default_attributes: values.default_attributes,
      menu_order: values.menu_order,
      sold_individually: values.sold_individually,
      stock_status: values.stock_status.value,
      reviews_allowed: values.reviews_allowed,
    };

    console.log("formattedProduct", formattedProduct);

    // Handle product creation here
    console.log("Creating new product:", formattedProduct);

    createProductMutation.mutate(formattedProduct);

    setStep(1);
  };

  const steps = [
    { title: "Details", icon: PackageIcon },
    { title: "Additional", icon: TagIcon },
    {
      title: "Images",
      icon: ImageIcon,
    },

    { title: "Inventory", icon: BoxIcon },
    {
      title: "Shipping",
      icon: CreditCardIcon,
      condition: (values) => !values.virtual,
    },
    { title: "Attributes", icon: SettingsIcon },
    { title: "Linked Products", icon: SettingsIcon },
    { title: "Advanced", icon: InfoIcon },
    {
      title: "Downloads",
      icon: DownloadIcon,
      condition: (values) => values.downloadable,
    },
  ];

  const StepContent = ({ step, values, setFieldValue }) => {
    console.log(values);

    switch (step) {
      case 1:
        return <BasicProductInfo />;

      case 2:
        return <AdditionalProductDetails />;

      case 3:
        return <ProductImages />;

      case 4:
        return (
          <Card>
            <Inventory
              saveButton={false}
              loading={createProductMutation.isPending}
            />
          </Card>
        );
      case 5:
        return (
          !values.virtual && (
            <Card>
              <Shipping
                saveButton={false}
                loading={createProductMutation.isPending}
              />
            </Card>
          )
        );
      case 6:
        return (
          <Card>
            <Attributes
              values={values}
              saveButton={false}
              setFieldValue={setFieldValue}
            />
          </Card>
        );
      case 7:
        return (
          <Card>
            <LinkedProducts
              saveButton={false}
              values={values}
              loading={createProductMutation.isPending}
            />
          </Card>
        );
      case 8:
        return (
          <Card>
            <Advanced
              saveButton={false}
              loading={createProductMutation.isPending}
            />
          </Card>
        );
      case 9:
        return (
          values.downloadable && (
            <Card>
              <ProductDownloads />
            </Card>
          )
        );

      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>Add Product</Button>
      </DialogTrigger>
      <DialogContent className=" max-w-[90%] w-full h-[96vh] p-4  rounded-none">
        <DialogHeader>
          <DialogTitle>Add New Product</DialogTitle>
          <DialogDescription>
            Fill in the details to add a new product to your inventory.
          </DialogDescription>
        </DialogHeader>

        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, setFieldValue }) => {
            const activeSteps = steps.filter(
              (s) => !s.condition || s.condition(values)
            );

            return (
              <Form className="flex flex-col gap-4 p-4 ">
                <Card>
                  <CardHeader>
                    <div className="flex justify-between mb-2 overflow-x-auto">
                      {activeSteps.map((s, index) => {
                        return (
                          <Button
                            key={index}
                            variant="ghost"
                            className={`flex  gap-2  ${
                              step === index + 1
                                ? "text-primary border-primary"
                                : "text-muted-foreground"
                            }`}
                            onClick={() => setStep(index + 1)}
                          >
                            {<s.icon />}
                            <span className="text-xs whitespace-nowrap">
                              {s.title}
                            </span>
                          </Button>
                        );
                      })}
                    </div>
                    <div className="w-full bg-muted-foreground/20 rounded-full h-2">
                      <div
                        className="bg-primary h-2 rounded-full transition-all duration-300 ease-in-out"
                        style={{
                          width: `${(step / activeSteps.length) * 100}%`,
                        }}
                      ></div>
                    </div>
                  </CardHeader>
                  <ScrollArea className="overflow-hidden">
                    <CardContent className="max-h-[calc(100dvh-350px)] min-h-[calc(100dvh-350px)]">
                      <StepContent
                        step={step}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    </CardContent>
                  </ScrollArea>

                  <CardFooter
                    className={cn(
                      "flex mt-2",
                      step === 1 ? "justify-end" : "justify-between"
                    )}
                  >
                    {step > 1 && (
                      <Button
                        variant="outline"
                        onClick={() => setStep(step - 1)}
                      >
                        Previous
                      </Button>
                    )}
                    {step < activeSteps.length && (
                      <Button
                        className="self-end"
                        onClick={() => setStep(step + 1)}
                      >
                        Next
                      </Button>
                    )}
                    {step === activeSteps.length && (
                      <Button type="submit">Create Product</Button>
                    )}
                  </CardFooter>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
