import React, { useState, useEffect, useMemo } from 'react';
import { APP_NAME, APP_VERSION, APP_COPYRIGHT } from 'config';
import { useQuery } from '@tanstack/react-query';
import { ORDER_API } from 'api/orders';
import { PRODUCT_API } from 'api/product';
import {
	BarChart2,
	BriefcaseBusiness,
	CircleUser,
	Menu,
	Package,
	Settings,
	ShoppingBasket,
	ShoppingCart,
	Users,
	Sparkles,
	ExternalLink,
	Link as LinkIcon,
	Wrench,
	Zap,
} from 'lucide-react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Badge } from 'components/ui/badge';

import { Button } from 'components/ui/button';
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbSeparator,
	BreadcrumbList,
	BreadcrumbPage,
} from 'components/ui/breadcrumb';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { Sheet, SheetContent, SheetTrigger } from 'components/ui/sheet';
import { Toaster } from 'components/ui/toaster';
import { Skeleton } from 'components/ui/skeleton';
import CommandPalette from 'components/ui/CommandPalette';
import { cn } from 'lib/utils';
import {
	ExtensionsProvider,
	useExtensions,
} from '../contexts/ExtensionsContext';
import { Card, CardContent } from 'components/ui/card';
import {
	Menubar,
	MenubarContent,
	MenubarItem,
	MenubarMenu,
	MenubarSeparator,
	MenubarShortcut,
	MenubarTrigger,
} from 'components/ui/menubar';
import { ScrollArea } from 'components/ui/scroll-area';
import { Notifications } from 'components/ui/notifications';
import { BASE_URL } from 'api/axios';
import { submitDemoEmail } from 'api/axios';
import { DemoEmailDialog } from 'components/ui/DemoEmailDialog';
import { useToast } from 'components/ui/use-toast';
import { APP_DEMO_MODE } from 'config';

const quickLinks = [
	{
		label: 'Orders',
		items: [
			{
				label: 'All Orders',
				url: `${BASE_URL}/wp-admin/edit.php?post_type=shop_order`,
			},
			{
				label: 'Add New Order',
				url: `${BASE_URL}/wp-admin/post-new.php?post_type=shop_order`,
			},
			{
				label: 'Processing',
				url: `${BASE_URL}/wp-admin/edit.php?post_status=wc-processing&post_type=shop_order`,
			},
			{
				label: 'Completed',
				url: `${BASE_URL}/wp-admin/edit.php?post_status=wc-completed&post_type=shop_order`,
			},
		],
	},
	{
		label: 'Products',
		items: [
			{
				label: 'All Products',
				url: `${BASE_URL}/wp-admin/edit.php?post_type=product`,
			},
			{
				label: 'Add New Product',
				url: `${BASE_URL}/wp-admin/post-new.php?post_type=product`,
			},
			{
				label: 'Categories',
				url: `${BASE_URL}/wp-admin/edit-tags.php?taxonomy=product_cat&post_type=product`,
			},
			{
				label: 'Tags',
				url: `${BASE_URL}/wp-admin/edit-tags.php?taxonomy=product_tag&post_type=product`,
			},
		],
	},
	{
		label: 'Discounts',
		items: [
			{
				label: 'All Coupons',
				url: `${BASE_URL}/wp-admin/edit.php?post_type=shop_coupon`,
			},
			{
				label: 'Add New Coupon',
				url: `${BASE_URL}/wp-admin/post-new.php?post_type=shop_coupon`,
			},
		],
	},
	{
		label: 'Store',
		items: [
			{ label: 'View Shop', url: BASE_URL },
			{
				label: 'WooCommerce Settings',
				url: `${BASE_URL}/wp-admin/admin.php?page=wc-settings`,
			},
			{ label: 'WP Admin', url: `${BASE_URL}/wp-admin/` },
		],
	},
];

const menuItems = [
	{
		section: 'Store Management',
		items: [
			{
				to: '/',
				icon: BarChart2,
				label: 'Dashboard',
			},
			{
				to: '/orders',
				icon: BriefcaseBusiness,
				label: 'Orders',
				badge: true,
			},
			{
				to: '/customers',
				icon: Users,
				label: 'Customers',
			},
			// {
			// 	to: '/subscriptions',
			// 	icon: Zap,
			// 	label: 'Subscriptions',
			// 	requiredExtension: 'WooCommerce Subscriptions',
			// },
		],
	},
	{
		section: 'Marketing',
		items: [
			{
				to: '/discounts',
				icon: Package,
				label: 'Discounts',
			},
		],
	},
	{
		section: 'Configuration',
		items: [
			{
				to: '/products',
				icon: ShoppingBasket,
				label: 'Products',
			},
			{
				to: '/categories',
				icon: Users,
				label: 'Categories',
			},
			{
				to: '/setup',
				icon: Settings,
				label: 'Store setup',
			},
			{
				to: '/tools',
				icon: Wrench,
				label: 'Tools',
			},
		],
	},
];

const tips = [
	{
		description: 'Hit "Cmd + K" to open the command palette.',
	},
	{
		description: 'Connect up to 15 stores to your account.',
	},
];

// Randomly select a tip from the array
const randomTip = tips[Math.floor(Math.random() * tips.length)];

function DashboardContent({ children }) {
	const location = useLocation();
	const pathnames = location.pathname.split('/').filter((x) => x);
	const isProductPage =
		pathnames[0] === 'products' && pathnames[1] === 'update';
	const id = isProductPage ? pathnames[2] : null;

	const extensions = useExtensions();

	const filteredMenuItems = useMemo(() => {
		return menuItems.map((section) => ({
			...section,
			items: section.items.filter(
				(item) =>
					!item.requiredExtension ||
					extensions.includes(item.requiredExtension)
			),
		}));
	}, [extensions]);

	const productNameQuery = useQuery({
		queryFn: () =>
			PRODUCT_API.GET_PRODUCT_NAME.FN({
				queryKey: [PRODUCT_API.GET_PRODUCT_NAME.KEY, { id }],
			}),
		queryKey: [PRODUCT_API.GET_PRODUCT_NAME.KEY, id],
		enabled: isProductPage && id !== null,
	});

	const {
		data: processingOrdersCount,
		isLoading: isProcessingOrdersLoading,
	} = useQuery({
		queryFn: () => ORDER_API.GET_PROCESSING_ORDERS_COUNT.FN(),
		queryKey: [ORDER_API.GET_PROCESSING_ORDERS_COUNT.KEY],
	});

	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const navigate = useNavigate();

	const [showEmailDialog, setShowEmailDialog] = useState(false);
	const { toast } = useToast();

	useEffect(() => {
		if (APP_DEMO_MODE) {
			const isVerified =
				localStorage.getItem('demoEmailVerified') === 'true';
			const hasProvidedEmail =
				localStorage.getItem('demoEmailProvided') === 'true';
			setShowEmailDialog(!isVerified && !hasProvidedEmail);
		}
	}, []);

	const handleEmailSubmit = async (email) => {
		try {
			await submitDemoEmail(email);
			localStorage.setItem('demoEmailProvided', 'true');
			localStorage.setItem('demoEmailVerified', 'true');
			setShowEmailDialog(false);
			toast({
				title: 'Thank you!',
				description:
					'Your email has been submitted successfully. You can now access the demo.',
				variant: 'success',
			});
		} catch (error) {
			console.error('Error in handleEmailSubmit:', error);
			toast({
				title: 'Error',
				description: `There was an error submitting your email: ${error.message}. Please try again.`,
				variant: 'destructive',
			});
		}
	};

	const handleCloseDialog = () => {
		setShowEmailDialog(false);
	};

	if (APP_DEMO_MODE && showEmailDialog) {
		return (
			<DemoEmailDialog
				open={showEmailDialog}
				onEmailVerified={handleEmailSubmit}
				onClose={handleCloseDialog}
			/>
		);
	}

	const getPageTitle = () => {
		if (isProductPage) {
			if (productNameQuery.isLoading) {
				return <Skeleton className='h-[36px] w-[400px]' />;
			}
			if (productNameQuery.isError)
				return `Error: ${productNameQuery.error.message}`;
			if (productNameQuery.data) {
				return (
					<>
						{productNameQuery.data.name}{' '}
						<span className='text-muted-foreground'>({id})</span>
					</>
				);
			}
		}
		return pathnames[pathnames.length - 1] !== undefined
			? pathnames[pathnames.length - 1]
			: 'Global Overview of Your Store';
	};

	const handleMobileMenuItemClick = (to) => {
		setIsMobileMenuOpen(false);
		navigate(to);
	};

	return (
		<div className='grid min-h-screen w-full md:grid-cols-[275px_1fr] lg:grid-cols-[275px_1fr] bg-neutral-50'>
			{/* Sidebar */}
			<div className='hidden pl-4 md:block bg-neutral-50'>
				<div className='flex h-screen flex-col gap-2 fixed w-[255px] overflow-hidden'>
					{/* Logo section */}
					<div className='flex flex-col gap-2 mt-4 w-full justify-start'>
						<div className='flex items-center gap-2 w-full justify-start'>
							<ShoppingCart className='h-10 w-10 text-primary bg-primary/10 rounded-lg p-2' />
							<a
								href='/'
								className='text-2xl flex items-center transition focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary-500 focus-visible:outline-none focus-visible:shadow-outline-primary rounded-full px-2 -ml-2'>
								<span className='font-bold text-primary-600'>
									Store
								</span>
								<span className='text-slate-600'>UI</span>{' '}
								{APP_DEMO_MODE ? (
									<span className='uppercase mt-0.5 leading-none text-[10px] ml-1 font-bold bg-yellow-200 text-slate-700 px-2 py-1 rounded-md'>
										demo mode
									</span>
								) : (
									<span className='uppercase mt-0.5 leading-none text-[10px] ml-1 font-bold bg-gray-200 text-slate-700 px-2 py-1 rounded-md'>
										{APP_VERSION}
									</span>
								)}
							</a>
						</div>

						{/* show store url */}
						<div className='text-xs text-muted-foreground truncate'>
							<a
								href={
									APP_DEMO_MODE
										? 'https://demo.storeui.net'
										: BASE_URL
								}
								target='_blank'
								rel='noopener noreferrer'
								className='hover:text-primary transition-all duration-300'>
								{APP_DEMO_MODE
									? 'https://demo.storeui.net'
									: BASE_URL}
							</a>
						</div>
					</div>
					{/* Scrollable content */}
					<div className='flex-1 overflow-y-auto'>
						<nav className='grid items-start text-sm font-medium pr-2 gap-1'>
							{filteredMenuItems.map((section, sectionIndex) => (
								<React.Fragment key={section.section}>
									<div className='text-zinc-500 font-medium text-sm text-opacity-1 py-3 mt-2'>
										{section.section}
									</div>
									{section.items.map((item) => (
										<NavLink
											key={item.to}
											to={item.to}
											className={({ isActive }) =>
												cn(
													'flex items-center gap-3 rounded-lg px-3 py-2 transition-all',
													isActive
														? 'bg-zinc-200 text-primary'
														: 'text-muted-foreground hover:text-primary hover:bg-zinc-200'
												)
											}>
											<div className='flex items-center gap-3 flex-grow'>
												<item.icon className='h-4 w-4' />
												{item.label}
											</div>
											{item.badge &&
												(isProcessingOrdersLoading ? (
													<Skeleton className='h-5 w-5 rounded-full animate-pulse' />
												) : processingOrdersCount >
												  0 ? (
													<Badge className='ml-auto'>
														{processingOrdersCount}
													</Badge>
												) : null)}
										</NavLink>
									))}
								</React.Fragment>
							))}

							{/* Quick Links Menubar */}
							<div className='text-zinc-500 font-medium text-sm text-opacity-1 py-3 mt-2'>
								Shortcuts
							</div>
							<Menubar className='border-none p-0 bg-transparent'>
								<MenubarMenu>
									<MenubarTrigger className='flex items-center gap-3 rounded-lg px-3 py-2 transition-all text-muted-foreground hover:text-primary hover:bg-zinc-200 cursor-pointer focus:bg-zinc-200 data-[state=open]:bg-zinc-200 data-[state=open]:text-primary w-full'>
										<div className='flex items-center gap-3 flex-grow'>
											<LinkIcon className='h-4 w-4' />
											<span>Quick Links</span>
										</div>
										<ExternalLink className='h-4 w-4 ml-auto' />
									</MenubarTrigger>
									<MenubarContent className='w-[250px]'>
										<ScrollArea className='h-[300px] pr-3'>
											{quickLinks.map((section) => (
												<React.Fragment
													key={section.label}>
													<MenubarItem className='font-semibold'>
														{section.label}
													</MenubarItem>
													{section.items.map(
														(item) => (
															<MenubarItem
																key={
																	item.label
																}>
																<a
																	href={
																		item.url
																	}
																	target='_blank'
																	rel='noopener noreferrer'
																	className='flex items-center w-full'>
																	{item.label}
																	<MenubarShortcut>
																		<ExternalLink className='h-3 w-3' />
																	</MenubarShortcut>
																</a>
															</MenubarItem>
														)
													)}
													<MenubarSeparator />
												</React.Fragment>
											))}
										</ScrollArea>
									</MenubarContent>
								</MenubarMenu>
							</Menubar>
						</nav>
					</div>

					{/* Promo Card */}
					<Card className='mt-4 mb-8 mr-4'>
						<CardContent className='p-4'>
							<div className='flex items-center gap-2 mb-2'>
								<Sparkles className='h-4 w-4 text-yellow-500' />
								<h4 className='font-semibold'>
									StoreUI Pro tips
								</h4>
							</div>
							<p className='text-sm text-muted-foreground'>
								{randomTip.description}
							</p>
						</CardContent>
						{/* <CardFooter className='p-4 pt-0'>
							<Button
								className='w-full'
								variant='outline'
								disabled={true}
							>
								Learn More (coming soon)
							</Button>
						</CardFooter> */}
					</Card>
				</div>
			</div>
			{/* Header */}
			<div className='flex flex-col bg-neutral-50 m-2 rounded-lg shadow-md border border-zinc-200'>
				<header className='flex gap-4 bg-white pt-6 pb-2 px-6 rounded-tr-lg rounded-tl-lg'>
					<Sheet
						open={isMobileMenuOpen}
						onOpenChange={setIsMobileMenuOpen}>
						<SheetTrigger asChild>
							<Button
								variant='outline'
								size='icon'
								className='shrink-0 md:hidden'>
								<Menu className='h-5 w-5' />
								<span className='sr-only'>
									Toggle navigation menu
								</span>
							</Button>
						</SheetTrigger>
						<SheetContent side='left' className='flex flex-col'>
							<nav className='grid gap-2 text-lg font-medium'>
								{filteredMenuItems.map((section) => (
									<React.Fragment key={section.section}>
										<div className='text-zinc-500 font-medium text-sm text-opacity-1 py-3 mt-2'>
											{section.section}
										</div>
										{section.items.map((item) => (
											<button
												key={item.to}
												onClick={() =>
													handleMobileMenuItemClick(
														item.to
													)
												}
												className='mx-[-0.65rem] flex items-center gap-4 rounded-xl px-3 py-2 text-left text-muted-foreground hover:text-primary hover:bg-zinc-100 hover:bg-opacity-1'>
												<item.icon className='h-5 w-5' />
												<span className='flex-grow'>
													{item.label}
												</span>
												{item.badge &&
													processingOrdersCount >
														0 && (
														<Badge variant='secondary'>
															{
																processingOrdersCount
															}
														</Badge>
													)}
											</button>
										))}
									</React.Fragment>
								))}
							</nav>
						</SheetContent>
					</Sheet>
					<div className='w-full flex-1'>
						<Breadcrumb className='flex-col flex items-start gap-2'>
							<h3 className='text-2xl font-semibold leading-none tracking-tight block capitalize'>
								{getPageTitle()}
							</h3>
							<BreadcrumbList>
								<BreadcrumbItem>
									<BreadcrumbLink asChild>
										<Link to='/'>Dashboard</Link>
									</BreadcrumbLink>
								</BreadcrumbItem>
								{pathnames.map((value, index) => {
									const to = `/${pathnames
										.slice(0, index + 1)
										.join('/')}`;
									const isLast =
										index === pathnames.length - 1;
									return (
										<React.Fragment key={to}>
											<BreadcrumbSeparator />
											<BreadcrumbItem>
												{isLast ? (
													<BreadcrumbPage>
														{isProductPage ? (
															productNameQuery.isLoading ? (
																<Skeleton className='h-[24px] w-[150px] animate-pulse rounded' />
															) : productNameQuery.data ? (
																productNameQuery
																	.data.name
															) : (
																value
															)
														) : (
															value
														)}
													</BreadcrumbPage>
												) : (
													<BreadcrumbLink asChild>
														<Link to={to}>
															{value}
														</Link>
													</BreadcrumbLink>
												)}
											</BreadcrumbItem>
										</React.Fragment>
									);
								})}
							</BreadcrumbList>
						</Breadcrumb>
					</div>
					<Notifications />
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button
								variant='secondary'
								size='icon'
								className='rounded-full hover:bg-primary/10 hover:text-primary transition-all duration-300'>
								<CircleUser className='h-5 w-5' />
								<span className='sr-only'>
									Toggle user menu
								</span>
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align='end'>
							<DropdownMenuLabel>
								My StoreUI Account
							</DropdownMenuLabel>
							<DropdownMenuSeparator />
							<DropdownMenuItem
								className='cursor-pointer'
								onClick={() =>
									window.open(
										'https://storeui.net/my-account/',
										'_blank'
									)
								}>
								My account
							</DropdownMenuItem>
							<DropdownMenuItem
								className='cursor-pointer'
								onClick={() =>
									window.open(
										'https://storeui.net/my-account/store-api-settings/',
										'_blank'
									)
								}>
								My Stores
							</DropdownMenuItem>
							<DropdownMenuItem
								className='cursor-pointer'
								onClick={() =>
									window.open(
										'https://storeui.net/my-account/subscriptions/',
										'_blank'
									)
								}>
								My subscriptions
							</DropdownMenuItem>
						</DropdownMenuContent>
					</DropdownMenu>
				</header>
				{/* Main Content */}
				<main className='bg-white h-full rounded-br-lg rounded-bl-lg'>
					{children}
				</main>
				<Toaster />
			</div>
			<CommandPalette />
		</div>
	);
}

export function DashboardLayout({ children }) {
	return (
		<ExtensionsProvider>
			<DashboardContent>{children}</DashboardContent>
			<div className='p-4 bg-neutral-50'>
				<p className='text-sm text-muted-foreground text-center'>
					{APP_NAME} v{APP_VERSION} &copy; {APP_COPYRIGHT}
				</p>
			</div>
		</ExtensionsProvider>
	);
}
