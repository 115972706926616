import React from "react";
import { Field } from "formik";
import { Label } from "components/ui/label";
import { ReusableTabs } from "components/reusable-tabs";

const FormTabs = ({
  name,
  label,
  labelColor = "text-gray-700", // Default label color
  layout = "row", // Default layout
}) => (
  <Field name={name}>
    {({ field, meta, form }) => {
      const containerClassName = `flex ${
        layout === "row" ? "flex-row" : "flex-col"
      } gap-3 items-${layout === "row" ? "center" : "start"}`;

      return (
        <div>
          <div className={containerClassName}>
            {label && (
              <Label
                className={`${
                  layout === "row" ? "w-[300px]" : "w-full"
                } ${labelColor}`}
              >
                {label}
              </Label>
            )}
            <div className={layout === "row" ? "w-full" : "w-full"}>
              <ReusableTabs
                value={field.value}
                onChange={(value) => form.setFieldValue(name, value)}
              />
            </div>
          </div>
          {meta.touched && meta.error ? (
            <div className="text-red-500 text-sm mt-1">{meta.error}</div>
          ) : null}
        </div>
      );
    }}
  </Field>
);

export default FormTabs;
