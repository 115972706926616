import React from "react";
import { renderNormalField } from "components/renderNormalField";
import { SaveProductButton } from "./SaveProductButton";
const fields = [
  {
    name: "purchase_note",
    label: "Purchase note",
    type: "textarea",
  },
  {
    name: "reviews_allowed",
    label: "Enable reviews",
    type: "checkbox",
  },
];

const Advanced = ({ loading, saveButton = true }) => {
  return (
    <>
      <div className="flex flex-col gap-8 p-1 m-4 max-w-3xl">
        {fields.map((field) => (
          <React.Fragment key={field.name}>
            {renderNormalField({ ...field })}
          </React.Fragment>
        ))}
      </div>
      <SaveProductButton loading={loading} />
    </>
  );
};

export default Advanced;
