import React from "react";
import { Avatar, AvatarFallback } from "components/ui/avatar";
import { Badge } from "components/ui/badge";
import { format } from "date-fns";
import GenericTable from "components/generic-table";

const getStatusColor = (status) => {
  switch (status) {
    case "pending":
      return "text-orange-700 bg-orange-50 ring-orange-600/10 hover:bg-orange-100";
    case "active":
      return "text-green-700 bg-green-50 ring-green-600/20 hover:bg-green-100";
    case "on-hold":
      return "text-yellow-700 bg-yellow-50 ring-yellow-600/10 hover:bg-yellow-100";
    case "expired":
      return "text-gray-700 bg-gray-50 ring-gray-600/10 hover:bg-gray-100";
    case "pending-cancel":
      return "text-purple-700 bg-purple-50 ring-purple-600/10 hover:bg-purple-100";
    case "cancelled":
      return "text-red-700 bg-red-50 ring-red-600/10 hover:bg-red-100";
    default:
      return "bg-blue-100 text-blue-800";
  }
};

export default function SubscriptionTable({
  data,
  isLoading,
  isError,
  error,
  sorting,
  setSorting,
  columnFilters,
  setColumnFilters,
  columnVisibility,
  setColumnVisibility,
  rowSelection,
  setRowSelection,
  page,
  per_page,
  handleEditClick,
  selectedRowIds,
  setSelectedRowIds,
  setPage,
  total,
}) {
  const columns = [
    {
      accessorKey: "number",
      header: "Number",
      cell: ({ row }) => <div>#{row.getValue("number")}</div>,
    },
    {
      accessorKey: "total",
      header: "Revenue",
      cell: ({ row }) => (
        <div>{`${row.getValue("total")} / ${row.original.billing_period}`}</div>
      ),
    },
    {
      accessorKey: "date_created",
      header: "Date",
      cell: ({ row }) => {
        const date = new Date(row.getValue("date_created"));
        return <div>{format(date, "d MMM, h:mm a")}</div>;
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }) => {
        const status = row.getValue("status");
        return (
          <Badge
            className={`${getStatusColor(
              row.getValue("status")
            )} rounded-md py-1 px-2 text-xs font-medium ring-1`}
          >
            {status}
          </Badge>
        );
      },
    },
    {
      accessorKey: "billing.email",
      header: "Customer",
      cell: ({ row }) => (
        <div className="flex items-center gap-2">
          <Avatar className="border-2 border-zinc-200">
            <AvatarFallback>
              {row.original.billing.email.charAt(0).toUpperCase()}
            </AvatarFallback>
          </Avatar>
          <span>{row.original.billing.email}</span>
        </div>
      ),
    },
    {
      accessorKey: "line_items",
      header: "Purchased",
      cell: ({ row }) => <div>{row.original.line_items[0]?.name || "N/A"}</div>,
    },
  ];

  return (
    <GenericTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isError={isError}
      error={error}
      sorting={sorting}
      setSorting={setSorting}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
      columnVisibility={columnVisibility}
      setColumnVisibility={setColumnVisibility}
      rowSelection={rowSelection}
      setRowSelection={setRowSelection}
      page={page}
      per_page={per_page}
      handleEditClick={handleEditClick}
      selectedRowIds={selectedRowIds}
      setSelectedRowIds={setSelectedRowIds}
      setPage={setPage}
      total={total}
    />
  );
}
