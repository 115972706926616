import SimpleSelect from "components/simple-select";
import { Label } from "components/ui/label";
import { Field } from "formik";
import React from "react";

const FormMultiSelect = ({
  options,
  name,
  label,
  labelColor = "text-gray-700", // Default label color
  layout = "row", // Default layout
}) => {
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        const findSelectedOptions = (value) => {
          if (Array.isArray(value)) {
            return value.map(
              (item) =>
                options.find(
                  (option) => option.value === (item.value || item)
                ) || item
            );
          } else if (typeof value === "object" && value !== null) {
            return (
              options.find((option) => option.value === value.value) || value
            );
          } else {
            return options.find((option) => option.value === value) || null;
          }
        };

        const selectedOptions = findSelectedOptions(field.value);

        const containerClassName = `flex ${
          layout === "row" ? "flex-row" : "flex-col"
        } gap-3 items-${layout === "row" ? "center" : "start"}`;

        return (
          <div>
            <div className={containerClassName}>
              {label && (
                <Label
                  className={`${
                    layout === "row" ? "w-[300px]" : "w-full"
                  } ${labelColor}`}
                >
                  {label}
                </Label>
              )}
              <div className={layout === "row" ? "w-full" : "w-full"}>
                <SimpleSelect
                  options={options}
                  value={selectedOptions}
                  onChange={(selectedOptions) => {
                    const newValue = Array.isArray(selectedOptions)
                      ? selectedOptions.map((option) => ({
                          name: option.label,
                          value: option.value,
                          label: option.label,
                        }))
                      : selectedOptions
                      ? {
                          value: selectedOptions.value,
                          name: selectedOptions.label,
                          label: selectedOptions.label,
                        }
                      : null;

                    form.setFieldValue(name, newValue);
                  }}
                  isMulti={true}
                />
              </div>
            </div>
            {meta.touched && meta.error ? (
              <div className="text-red-500 text-sm mt-1">{meta.error}</div>
            ) : null}
          </div>
        );
      }}
    </Field>
  );
};

export default FormMultiSelect;
