import SimpleSelect from "components/simple-select";
import { Label } from "components/ui/label";
import { Field } from "formik";
import React from "react";

const FormSelect = ({
  options,
  name,
  label,
  labelColor = "text-gray-700", // Default label color
  layout = "row", // Default layout
}) => {
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        const containerClassName = `flex ${
          layout === "row" ? "flex-row" : "flex-col"
        } gap-3 items-${layout === "row" ? "center" : "start"}`;

        return (
          <div>
            <div className={containerClassName}>
              {label && (
                <Label
                  className={`${
                    layout === "row" ? "w-[300px]" : "w-full"
                  } ${labelColor}`}
                >
                  {label}
                </Label>
              )}
              <div className={layout === "row" ? "w-full" : "w-full"}>
                <SimpleSelect
                  options={options}
                  value={options.find(
                    (option) =>
                      option?.value?.toString() === field?.value?.toString()
                  )}
                  onChange={(selectedOption) => {
                    console.log("selectedOption", selectedOption);
                    form.setFieldValue(
                      name,
                      selectedOption
                        ? {
                            value: selectedOption.value,
                            label: selectedOption.label,
                          }
                        : null
                    );
                  }}
                  isMulti={false}
                />
              </div>
            </div>
            {meta.touched && meta.error ? (
              <div className="text-red-500 text-sm mt-1">{meta.error}</div>
            ) : null}
          </div>
        );
      }}
    </Field>
  );
};

export default FormSelect;
