import { jsPDF } from "jspdf";
import "jspdf-autotable";

export const generateSubscriptionPDF = (subscription) => {
  const doc = new jsPDF();

  // Add header
  doc.setFontSize(20);
  doc.setTextColor(40);
  doc.text("Subscription Details", 14, 22);

  // Add logo (if available)
  // doc.addImage("logo.png", "PNG", 170, 10, 30, 30);

  // Add subscription details
  doc.setFontSize(12);
  doc.setTextColor(80);
  doc.text(`Subscription #: ${subscription.id}`, 14, 40);
  doc.text(
    `Start Date: ${new Date(subscription.start_date_gmt).toLocaleDateString()}`,
    14,
    48
  );
  doc.text(
    `Next Payment: ${new Date(
      subscription.next_payment_date_gmt
    ).toLocaleDateString()}`,
    14,
    56
  );
  doc.text(`Status: ${subscription.status}`, 14, 64);
  doc.text(`Billing Period: ${subscription.billing_period}`, 14, 72);
  doc.text(`Billing Interval: ${subscription.billing_interval}`, 14, 80);
  doc.text(`Payment Method: ${subscription.payment_method_title}`, 14, 88);

  // Add billing details
  doc.setFontSize(14);
  doc.setTextColor(40);
  doc.text("Bill To:", 14, 102);
  doc.setFontSize(12);
  doc.setTextColor(80);
  doc.text(
    `${subscription.billing.first_name} ${subscription.billing.last_name}`,
    14,
    110
  );
  doc.text(subscription.billing.address_1, 14, 118);
  doc.text(
    `${subscription.billing.city}, ${subscription.billing.state} ${subscription.billing.postcode}`,
    14,
    126
  );
  doc.text(subscription.billing.country, 14, 134);
  doc.text(`Email: ${subscription.billing.email}`, 14, 142);
  doc.text(`Phone: ${subscription.billing.phone}`, 14, 150);

  // Add shipping details (if different from billing)
  if (subscription.shipping.address_1 !== subscription.billing.address_1) {
    doc.setFontSize(14);
    doc.setTextColor(40);
    doc.text("Ship To:", 120, 102);
    doc.setFontSize(12);
    doc.setTextColor(80);
    doc.text(
      `${subscription.shipping.first_name} ${subscription.shipping.last_name}`,
      120,
      110
    );
    doc.text(subscription.shipping.address_1, 120, 118);
    doc.text(
      `${subscription.shipping.city}, ${subscription.shipping.state} ${subscription.shipping.postcode}`,
      120,
      126
    );
    doc.text(subscription.shipping.country, 120, 134);
  }

  // Add items table
  const tableColumn = ["Item", "Quantity", "Price", "Total"];
  const tableRows = subscription.line_items.map((item) => [
    item.name,
    item.quantity,
    `$${parseFloat(item.price).toFixed(2)}`,
    `$${(parseFloat(item.price) * item.quantity).toFixed(2)}`,
  ]);

  doc.autoTable({
    startY: 160,
    head: [tableColumn],
    body: tableRows,
    headStyles: { fillColor: [40, 40, 40], textColor: 255 },
    alternateRowStyles: { fillColor: [245, 245, 245] },
    columnStyles: {
      0: { cellWidth: 80 },
      1: { cellWidth: 30, halign: "center" },
      2: { cellWidth: 40, halign: "right" },
      3: { cellWidth: 40, halign: "right" },
    },
  });

  // Add totals
  const finalY = doc.lastAutoTable.finalY || 160;
  doc.setFontSize(12);
  doc.setTextColor(80);

  doc.text(`Subtotal:`, 140, finalY + 20);
  doc.text(`$${parseFloat(subscription.total).toFixed(2)}`, 190, finalY + 20, {
    align: "right",
  });

  doc.text(`Shipping:`, 140, finalY + 30);
  doc.text(
    `$${parseFloat(subscription.shipping_total).toFixed(2)}`,
    190,
    finalY + 30,
    { align: "right" }
  );

  doc.text(`Tax:`, 140, finalY + 40);
  doc.text(
    `$${parseFloat(subscription.total_tax).toFixed(2)}`,
    190,
    finalY + 40,
    { align: "right" }
  );

  doc.setFontSize(14);
  doc.setTextColor(40);
  doc.text(`Total (per ${subscription.billing_period}):`, 110, finalY + 55);
  doc.text(`$${parseFloat(subscription.total).toFixed(2)}`, 190, finalY + 55, {
    align: "right",
  });

  // Add footer
  doc.setFontSize(10);
  doc.setTextColor(150);
  doc.text("Thank you for your subscription!", 14, 280);

  // Save the PDF
  doc.save(`subscription_${subscription.id}.pdf`);
};
