import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDisclosure } from "hooks/useDisclosure";
import SearchAndActions from "./_ui/searchAction";
import SubscriptionTable from "./_ui/SubscriptionTable";
import SubscriptionSheet from "./_ui/subscriptionSheet";
import { addDays } from "date-fns";
import { SUBSCRIPTION_API } from "api/subscription";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Subscriptions() {
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disab
  const [page, setPage] = useState(1);
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    orderBy: "name",
    order: "asc",
    per_page: 10,
    dateRange: {
      from: new Date(),
      to: addDays(new Date(), 30),
    },
    status: undefined,
  });

  const dateRange = `${filters.dateRange.from.toISOString()},${filters.dateRange.to.toISOString()}`;

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const navigate = useNavigate();
  const newSubscriptionState = useDisclosure();
  const dropdownState = useDisclosure();

  const subscriptionQuery = useQuery({
    queryFn: () =>
      SUBSCRIPTION_API.GET_ALL_SUBSCRIPTIONS.FN({
        params: { page, search, ...filters, dateRange },
      }),
    queryKey: [
      SUBSCRIPTION_API.GET_ALL_SUBSCRIPTIONS.KEY,
      page,
      search,
      filters,
    ],
  });

  const cache = useQueryClient();

  const createSubscriptionMutation = useMutation({
    mutationFn: SUBSCRIPTION_API.CREATE_SUBSCRIPTION.FN,
    onError: (error) => {
      console.log("Error Adding order: ", error);
    },
    onSuccess: (data) => {
      console.log("Order added successfully:", data);
      subscriptionQuery.refetch();
      setIsSheetOpen(true);
      setSelectedSubscriptionId(data.id);
      navigate(`/subscriptions?isUpdate=true&subscriptionId=${data.id}`);
    },
  });

  useEffect(() => {
    const isUpdate = searchParams.get("isUpdate") === "true";
    const subscriptionId = searchParams.get("subscriptionId");

    if (isUpdate && subscriptionId) {
      setSelectedSubscriptionId(subscriptionId);
      setIsSheetOpen(true);
    }
  }, [searchParams]);
  const handleEditClick = (subscription) => {
    // setSelectedSubscriptionId(subscription);
    setIsSheetOpen(true);

    setSelectedSubscriptionId(subscription.id);
    setIsSheetOpen(true);
    navigate(`/subscriptions?isUpdate=true&subscriptionId=${subscription.id}`);

    // Set the order data in the query cache
    cache.setQueryData([
      SUBSCRIPTION_API.GET_SUBSCRIPTION_BY_ID.KEY,
      subscription.id,
    ]);
  };

  const handleSheetClose = () => {
    setIsSheetOpen(false);
    setSelectedSubscriptionId(null);
  };

  return (
    <div className="w-full">
      <SearchAndActions
        newSubscriptionState={newSubscriptionState}
        loading={createSubscriptionMutation.isLoading}
        selectedRowIds={selectedRowIds}
        setSearch={setSearch}
        dropdownState={dropdownState}
        filters={filters}
        setFilters={setFilters}
        mutate={createSubscriptionMutation.mutate}
      />

      <SubscriptionTable
        data={subscriptionQuery.data || []}
        isLoading={subscriptionQuery.isLoading}
        isError={subscriptionQuery.isError}
        error={subscriptionQuery.error}
        statusFilter={filters.status}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        page={page}
        per_page={filters.per_page}
        handleEditClick={handleEditClick}
        setSelectedRowIds={setSelectedRowIds}
        selectedRowIds={selectedRowIds}
        setPage={setPage}
        total={subscriptionQuery.data?.length || 0}
      />

      <SubscriptionSheet
        isOpen={isSheetOpen}
        onClose={handleSheetClose}
        subscriptionId={selectedSubscriptionId}
        isEdit={true}
      />
      <SubscriptionSheet
        isOpen={newSubscriptionState.isOpen}
        onClose={newSubscriptionState.onClose}
        isEdit={false}
      />
    </div>
  );
}
