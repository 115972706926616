import React from "react";
import { Formik, Form } from "formik";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetDescription,
} from "components/ui/sheet";
import { ScrollArea } from "components/ui/scroll-area";
import { useMutation, useQuery } from "@tanstack/react-query";
import { sections } from "../subscriptionSchema";
import { renderNormalField } from "components/renderNormalField";
import AddressSubscriptionSection from "./address/AddressSubscriptionSection";
import SubscriptionHeader from "./header/OrderHeader";
import { SUBSCRIPTION_API } from "api/subscription";
import { Skeleton } from "components/ui/skeleton";
import CustomerInfo from "./customer/CustomerInfo";
import ScheduleComponent from "./ScheduleSection";
import { Button } from "components/ui/button";
import RelatedOrder from "./RelatedOrder";
import SubscriptionItems from "./items/SubscriptionItems";
import SubscriptionActions from "./actions/SubscriptionActions";

const SubscriptionSheet = ({
  isOpen,
  onClose,
  subscriptionId,
  page,
  isEdit,
}) => {
  const subscriptionQuery = useQuery({
    queryKey: [
      SUBSCRIPTION_API.GET_SUBSCRIPTION_BY_ID.KEY,
      { id: subscriptionId },
    ],
    queryFn: () =>
      SUBSCRIPTION_API.GET_SUBSCRIPTION_BY_ID.FN({ id: subscriptionId }),
    enabled: !!subscriptionId && isOpen,
  });

  const subscription = subscriptionQuery.data;

  const updateSubscriptionMutation = useMutation({
    mutationFn: SUBSCRIPTION_API.UPDATE_SUBSCRIPTION.FN,
  });

  const isLoading = subscriptionQuery.isLoading;

  const SubscriptionSkeleton = () => (
    <div className="space-y-6 p-6">
      <Skeleton className="h-10 w-3/4" />
      <div className="space-y-2">
        <Skeleton className="h-4 w-1/2" />
        <Skeleton className="h-4 w-1/3" />
      </div>
      <div className="space-y-2">
        <Skeleton className="h-4 w-1/2" />
        <Skeleton className="h-4 w-1/3" />
      </div>
      <Skeleton className="h-40 w-full" />
      <div className="flex justify-end space-x-2">
        <Skeleton className="h-10 w-24" />
        <Skeleton className="h-10 w-24" />
      </div>
    </div>
  );

  return (
    <Sheet open={isOpen} scrollBehavior="inside" onOpenChange={onClose}>
      <SheetContent side="right" className="min-w-[680px]">
        <SheetDescription>
          {isLoading ? (
            <SubscriptionSkeleton />
          ) : subscriptionQuery.isError ? (
            <div className="p-6">
              Error loading order: {subscriptionQuery.error.message}
            </div>
          ) : subscription ? (
            <div className="">
              <Formik
                initialValues={{
                  ...subscription,
                }}
                onSubmit={(values) => {
                  // Subscription dates could not be set. Error message: Invalid trial_end date. The date must be of the format: "Y-m-d H:i:s".
                  function formatDate(date) {
                    return (
                      date.getFullYear() +
                      "-" +
                      String(date.getMonth() + 1).padStart(2, "0") +
                      "-" +
                      String(date.getDate()).padStart(2, "0") +
                      " " +
                      String(date.getHours()).padStart(2, "0") +
                      ":" +
                      String(date.getMinutes()).padStart(2, "0") +
                      ":" +
                      String(date.getSeconds()).padStart(2, "0")
                    );
                  }

                  updateSubscriptionMutation.mutate({
                    id: subscription.id,
                    updateData: {
                      customer_id: values.customer_id,
                      billing: values.billing,
                      shipping: values.shipping,
                      billing_period: values.billing_period.value,
                      billing_interval: values.billing_interval.value,
                      trial_end_date: formatDate(
                        new Date(values.trial_end_date_gmt)
                      ),
                      next_payment_datet: formatDate(
                        new Date(values?.next_payment_date_gmt)
                      ),
                    },
                  });
                }}
              >
                {({ errors, touched, values, setFieldValue }) => {
                  const tabs = sections.map((section, i) => ({
                    value: section.legend,
                    label: section.legend,
                    content: (
                      <div key={`section-${i}`} className="space-y-4 ">
                        {section?.fields.map((field, fieldIndex) => (
                          <div key={`field-${fieldIndex}`}>
                            {renderNormalField(field)}
                          </div>
                        ))}
                      </div>
                    ),
                  }));
                  return (
                    <Form className="flex flex-col gap-2 p-1 text-black ">
                      <ScrollArea
                        className="w-full h-screen pb-16"
                        isScrollBar={false}
                      >
                        <SubscriptionHeader subscription={subscription} />
                        <CustomerInfo
                          subscription={subscription}
                          customerId={values.customer_id}
                        />
                        <AddressSubscriptionSection
                          subscription={subscription}
                          customerId={values.customer_id}
                        />
                        <RelatedOrder subscriptionId={subscription.id} />
                        <ScheduleComponent />
                        <SubscriptionItems subscription={subscription} />
                        <SubscriptionActions
                          subscription={subscription}
                          isEdit={isEdit}
                          updateLoading={updateSubscriptionMutation.isPending}
                        />
                      </ScrollArea>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          ) : null}
        </SheetDescription>
      </SheetContent>
    </Sheet>
  );
};

export default SubscriptionSheet;
