import CustomTabs from 'components/custom-tabs';
import React from 'react';
import ProductSummary from './_ui/ProductSummary';
import Attributes from './_ui/Attributes';
import Inventory from './_ui/Inventory';
import Shipping from './_ui/Shipping';
import LinkedProducts from './_ui/LinkedProducts';
import Advanced from './_ui/Advanced';
import { Form, Formik } from 'formik';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { PRODUCT_API } from 'api/product';
import ProductDownloads from './_ui/ProductDownload';
import { useToast } from 'components/ui/use-toast';
import { LoadingSpinner } from 'components/ui/loadingSpinner';
import Variants from './_ui/Variants';
import MediaLibrarySelect from 'components/ui/MediaLibrarySelect';
import { useExtension } from '../../../hooks/useExtensions';
import SEOPressProductMeta from 'extensions/seopress/SEOPressProductMeta';

const ProductControl = ({ isUpdate }) => {
	const params = useParams();
	const productId = params.productId;
	const { toast } = useToast();

	const hasSEOPress = useExtension('SEOPress');

	const updateProduct = useMutation({
		mutationFn: PRODUCT_API.UPDATE_PRODUCT.FN,
		mutationKey: PRODUCT_API.UPDATE_PRODUCT.KEY,
		onError: (error) => {
			toast({
				title: 'Error',
				description: 'There was a problem updating the product.',
				variant: 'destructive',
			});
		},

		onSuccess: (data) => {
			toast({
				title: 'Success',
				description: 'Product updated successfully.',
			});
		},
	});

	const productQuery = useQuery({
		queryFn: PRODUCT_API.GET_PRODUCT_BY_ID.FN,
		queryKey: [PRODUCT_API.GET_PRODUCT_BY_ID.KEY, { id: productId }],
		enabled: isUpdate,
	});

	if (isUpdate && productQuery.isLoading) {
		return <LoadingSpinner />;
	}

	const product = productQuery.data;

	const initialValues = isUpdate
		? {
				...product,
				categories:
					product?.categories?.map((category) => {
						return {
							value: category.id,
							label: category.name,
						};
					}) || [],
				tags:
					product?.tags?.map((tag) => {
						return {
							value: tag.id,
							label: tag.name,
						};
					}) || [],

				upsell_ids:
					product?.upsell_ids?.map((upsell) => {
						return {
							value: upsell,
						};
					}) || [],

				cross_sell_ids:
					product?.cross_sell_ids?.map((crossSell) => {
						return {
							value: crossSell,
						};
					}) || [],

				stock_status: product.stock_status,

				virtual: product.virtual,
				downloadable: product.downloadable,
				shipping_class: product.shipping_class_id.toString(),
				shipping_class_id: product.shipping_class_id,
				images: product.images || [],
				// SEOPress
				seopress_title: '',
				seopress_description: '',
				seopress_canonical: '',
				seopress_noindex: false,
				seopress_nofollow: false,
				seopress_og_title: '',
				seopress_og_description: '',
				seopress_og_image: '',
				seopress_twitter_title: '',
				seopress_twitter_description: '',
				seopress_twitter_image: '',
				// SEOPress Redirection
				seopress_redirections_enabled: false,
				seopress_redirections_logged_status: 'both',
				seopress_redirections_type: '301',
				seopress_redirections_value: '',
		  } || {}
		: {
				id: 1097,
				name: '',
				slug: '',
				permalink: '',
				date_created: new Date().toISOString(),
				date_modified: new Date().toISOString(),
				type: 'simple',
				status: 'draft',
				featured: false,
				catalog_visibility: 'visible',
				description: '',
				short_description: '',
				sku: '',
				price: '',
				regular_price: '',
				sale_price: '',
				date_on_sale_from: null,
				date_on_sale_to: null,
				on_sale: false,
				purchasable: true,
				total_sales: 0,
				virtual: false,
				downloadable: false,
				downloads: [],
				download_limit: -1,
				download_expiry: -1,
				external_url: '',
				button_text: '',
				tax_status: 'taxable',
				tax_class: '',
				manage_stock: false,
				stock_quantity: null,
				backorders: 'no',
				backorders_allowed: false,
				backordered: false,
				low_stock_amount: null,
				sold_individually: false,
				weight: '',
				dimensions: { length: '', width: '', height: '' },
				shipping_required: true,
				shipping_taxable: true,
				shipping_class: '',
				shipping_class_id: 0,
				reviews_allowed: true,
				average_rating: '0',
				rating_count: 0,
				upsell_ids: [],
				cross_sell_ids: [],
				parent_id: 0,
				purchase_note: '',
				categories: [],
				tags: [],
				images: [],
				attributes: [],
				default_attributes: [],
				variations: [],
				grouped_products: [],
				menu_order: 0,
				stock_status: 'instock',
				has_options: false,
				post_password: '',
				permalink_template: '',
				generated_slug: '',
				// SEOPress
				seopress_title: '',
				seopress_description: '',
				seopress_canonical: '',
				seopress_noindex: false,
				seopress_nofollow: false,
				seopress_og_title: '',
				seopress_og_description: '',
				seopress_og_image: '',
				seopress_twitter_title: '',
				seopress_twitter_description: '',
				seopress_twitter_image: '',
				// SEOPress Redirection
				seopress_redirections_enabled: false,
				seopress_redirections_logged_status: 'both',
				seopress_redirections_type: '301',
				seopress_redirections_value: '',
		  };

	const handleSubmit = (values) => {
		const formattedProduct = {
			name: values.name,
			type: values.type.value,
			status: values.status.value,
			featured: values.featured,
			catalog_visibility: values.catalog_visibility.value,
			description: values.description,
			short_description: values.short_description,
			sku: values.sku,
			price: values.price,
			regular_price: values.regular_price,
			sale_price: values.sale_price,
			date_on_sale_from: values.date_on_sale_from,
			date_on_sale_to: values.date_on_sale_to,
			virtual: values.virtual,
			downloadable: values.downloadable,
			downloads: values.downloads,
			download_limit: values.download_limit,
			download_expiry: values.download_expiry,
			external_url: values.external_url,
			button_text: values.button_text,
			tax_status: values.tax_status.value,
			tax_class: values.tax_class.value,
			manage_stock: values.manage_stock,
			stock_quantity: values.stock_quantity,
			backorders: values.backorders,
			weight: values.weight,
			dimensions: {
				length: values.dimensions.length.toString(),
				width: values.dimensions.width.toString(),
				height: values.dimensions.height.toString(),
			},
			shipping_class: values.shipping_class?.label,
			shipping_class_id: values.shipping_class?.value,
			upsell_ids: values.upsell_ids.map((upsell) => upsell.value),
			cross_sell_ids: values.cross_sell_ids.map(
				(crossSell) => crossSell.value
			),
			purchase_note: values.purchase_note,
			categories: values.categories.map((category) => ({
				id: category.value,
				name: category.label,
				slug: category?.label?.toLowerCase().replace(/ /g, '-'),
			})),
			tags: values.tags.map((tag) => ({
				id: tag.value,
				name: tag?.label,
				slug: tag?.label?.toLowerCase().replace(/ /g, '-'),
			})),
			images: values.images.map((image, index) => ({
				id: image.id,
				src: image.src,
				name: image.name,
				alt: image.alt,
				position: index,
			})),
			attributes: values.attributes,
			default_attributes: values.default_attributes,
			menu_order: values.menu_order,
			sold_individually: values.sold_individually,
			stock_status: values.stock_status.value,
			reviews_allowed: values.reviews_allowed,
			// SEOPress
			seopress_title: values.seopress_title,
			seopress_description: values.seopress_description,
			seopress_canonical: values.seopress_canonical,
			seopress_noindex: values.seopress_noindex,
			seopress_nofollow: values.seopress_nofollow,
			seopress_og_title: values.seopress_og_title,
			seopress_og_description: values.seopress_og_description,
			seopress_og_image: values.seopress_og_image,
			seopress_twitter_title: values.seopress_twitter_title,
			seopress_twitter_description: values.seopress_twitter_description,
			seopress_twitter_image: values.seopress_twitter_image,
			// SEOPress Redirection
			seopress_redirections_enabled: values.seopress_redirections_enabled,
			seopress_redirections_logged_status: values.seopress_redirections_logged_status,
			seopress_redirections_type: values.seopress_redirections_type,
			seopress_redirections_value: values.seopress_redirections_value,
		};

		console.log('formattedProduct', formattedProduct);

		if (isUpdate) {
			updateProduct.mutate({ id: productId, data: formattedProduct });
		} else {
			// createProduct.mutate({ product });
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			enableReinitialize>
			{({ values, setFieldValue }) => {
				const tabs = [
					{
						value: 'summary',
						label: 'Summary',
						content: (
							<ProductSummary
								values={values}
								setFieldValue={setFieldValue}
								loading={updateProduct.isPending}>
								<div className='space-y-4'>
									<h3 className='text-lg font-semibold'>
										Product Images
									</h3>
									<MediaLibrarySelect
										value={values.images[0]?.src || ''}
										onChange={(imageUrl) => {
											const newImages = [
												...values.images,
											];
											if (newImages[0]) {
												newImages[0] = {
													...newImages[0],
													src: imageUrl,
												};
											} else {
												newImages[0] = {
													src: imageUrl,
												};
											}
											setFieldValue('images', newImages);
										}}
									/>
									<h4 className='text-md font-semibold'>
										Gallery
									</h4>
									<div className='grid grid-cols-3 gap-4'>
										{[1, 2, 3, 4, 5].map((index) => (
											<MediaLibrarySelect
												key={index}
												value={
													values.images[index]?.src ||
													''
												}
												onChange={(imageUrl) => {
													const newImages = [
														...values.images,
													];
													if (newImages[index]) {
														newImages[index] = {
															...newImages[index],
															src: imageUrl,
														};
													} else {
														newImages[index] = {
															src: imageUrl,
														};
													}
													setFieldValue(
														'images',
														newImages
													);
												}}
											/>
										))}
									</div>
								</div>
							</ProductSummary>
						),
					},

					{
						value: 'attributes',
						label: 'Attributes',
						content: (
							<Attributes
								values={values}
								setFieldValue={setFieldValue}
							/>
						),
					},
					{
						value: 'inventory',
						label: 'Inventory',
						content: (
							<Inventory loading={updateProduct.isPending} />
						),
					},
					{
						value: 'linked-products',
						label: 'Linked products',
						content: (
							<LinkedProducts
								values={values}
								loading={updateProduct.isPending}
							/>
						),
					},
					{
						value: 'advanced',
						label: 'Advanced',
						content: <Advanced loading={updateProduct.isPending} />,
					},
				];

				// Add SEOPress tab conditionally
				if (hasSEOPress) {
					tabs.splice(4, 0, {
						value: 'seo',
						label: 'SEO',
						content:
							<SEOPressProductMeta
								productId={productId}
								values={values}
								setFieldValue={setFieldValue}
								/>
					});
				}
				
				
				if (!values.virtual) {
					tabs.splice(3, 0, {
						value: 'shipping',
						label: 'Shipping',
						content: <Shipping loading={updateProduct.isPending} />,
					});
				}

				// Add download tab conditionally
				if (values.downloadable) {
					tabs.splice(4, 0, {
						value: 'download',
						label: 'Product Downloads',
						content: <ProductDownloads />,
					});
				}
				if (values.type.value === 'variable') {
					tabs.splice(1, 0, {
						value: 'variable',
						label: 'Variations',
						content: <Variants productId={productId} />,
					});
				}

				return (
					<Form className='flex flex-col gap-4 p-4'>
						<CustomTabs
							tabs={tabs}
							defaultValue='summary'
							className='w-full border-none sticky top-2 z-10'
						/>
					</Form>
				);
			}}
		</Formik>
	);
};

export default ProductControl;