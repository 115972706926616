import React from "react";
import { Label } from "components/ui/label";
import FormInput from "./form-input";

const FormDimentions = ({
  name,
  label,
  labelColor = "text-gray-700",
  layout = "row",
  labelClassName = "",
  inputClassName = "",
}) => {
  const containerClassName = `flex ${
    layout === "row" ? "flex-row" : "flex-col"
  } gap-3 items-${layout === "row" ? "center" : "start"}`;

  return (
    <div>
      <div className={containerClassName}>
        {label && (
          <Label
            className={`${
              layout === "row" ? "w-[300px]" : "w-full"
            } ${labelColor} ${labelClassName}`}
          >
            {label}
          </Label>
        )}
        <div
          className={`flex gap-4 items-start justify-start ${
            layout === "row" ? "w-full" : "w-full"
          } flex-col lg:flex-row lg:items-center`}
        >
          <FormInput
            name={`${name}.length`}
            type="number"
            placeholder="Length"
            className={`w-32 ${inputClassName}`}
            layout="row" // Override to ensure inputs are always in a row
          />
          <FormInput
            name={`${name}.width`}
            type="number"
            placeholder="Width"
            className={`w-32 ${inputClassName}`}
            layout="row"
          />
          <FormInput
            name={`${name}.height`}
            type="number"
            placeholder="Height"
            className={`w-32 ${inputClassName}`}
            layout="row"
          />
          <span className="text-sm text-muted-foreground">L x W x H</span>
        </div>
      </div>
    </div>
  );
};

export default FormDimentions;
