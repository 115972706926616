import React from "react";
import { format } from "date-fns";
import { FaCalendar } from "react-icons/fa";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { Button } from "components/ui/button";
import { Calendar } from "components/ui/calendar";
import { cn } from "lib/utils";
import { Field } from "formik";
import { Label } from "components/ui/label";

const FormDate = ({
  name,
  label,
  labelColor = "text-gray-700",
  layout = "row",
  labelClassName = "",
  dateClassName = "",
}) => (
  <Field name={name}>
    {({ field, form, meta }) => {
      const containerClassName = `flex ${
        layout === "row" ? "flex-row" : "flex-col"
      } gap-3 items-${layout === "row" ? "center" : "start"}`;

      return (
        <div>
          <div className={containerClassName}>
            {label && (
              <Label
                className={`${
                  layout === "row" ? "w-[300px]" : "w-full"
                } ${labelColor} ${labelClassName}`}
              >
                {label}
              </Label>
            )}
            <div className={layout === "row" ? "w-full" : "w-full"}>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={"outline"}
                    className={cn(
                      "pl-3 text-left font-normal w-full",
                      !field.value && "text-muted-foreground",
                      dateClassName
                    )}
                  >
                    {field.value ? (
                      format(new Date(field.value), "PPP")
                    ) : (
                      <span>Pick a date</span>
                    )}
                    <FaCalendar className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    selected={field.value ? new Date(field.value) : undefined}
                    onSelect={(date) => {
                      form.setFieldValue(name, date);
                    }}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </div>
          </div>
          {meta.error && meta.touched ? (
            <div className="text-red-500 text-sm mt-1">{meta.error}</div>
          ) : null}
        </div>
      );
    }}
  </Field>
);

export default FormDate;
