import React from "react";
import { renderNormalField } from "components/renderNormalField";
import { useQuery } from "@tanstack/react-query";
import { PRODUCT_API } from "api/product";
import { LoadingSpinner } from "components/ui/loadingSpinner";
import { SaveProductButton } from "./SaveProductButton";

const LinkedProducts = ({ values, loading, saveButton = true }) => {
  const productIds = values.upsell_ids
    .concat(values.cross_sell_ids)
    .map((id) => id.value)
    .join(",");
  const productsIncludeQuery = useQuery({
    queryFn: () =>
      PRODUCT_API.GET_ALL_PRODUCTS.FN({
        params: { per_page: 100, include: productIds },
      }),
    queryKey: [PRODUCT_API.GET_ALL_PRODUCTS.KEY, { per_page: 100 }, productIds],
  });
  const productsQuery = useQuery({
    queryFn: () =>
      PRODUCT_API.GET_ALL_PRODUCTS.FN({
        params: { per_page: 100 },
      }),
    queryKey: [PRODUCT_API.GET_ALL_PRODUCTS.KEY, { per_page: 100 }],
  });

  if (productsQuery.isPending || productsIncludeQuery.isPending) {
    return <LoadingSpinner />;
  }

  const options = productsQuery.data
    ?.map((product) => ({
      value: product.id,
      label: product.name,
    }))
    .concat(
      productsIncludeQuery.data?.map((product) => ({
        value: product.id,
        label: product.name,
      })) || []
    );

  const fields = [
    {
      name: "upsell_ids",
      label: "Upsells",
      type: "multiselect",
      options: options,
    },
    {
      name: "cross_sell_ids",
      label: "Cross-sells",
      type: "multiselect",
      options: options,
    },
  ];

  return (
    <>
      <div className="flex flex-col gap-8 p-1 m-4 max-w-3xl">
        {fields.map((field) => (
          <div key={field.name}>{renderNormalField(field)}</div>
        ))}
      </div>
      {saveButton && <SaveProductButton loading={loading} />}
    </>
  );
};

export default LinkedProducts;
