import React from "react";
import { useFormikContext } from "formik";
import { Card, CardContent } from "components/ui/card";
import MediaLibrarySelect from "components/ui/MediaLibrarySelect";

const ProductImages = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleImageChange = (index, imageUrl) => {
    const newImages = [...values.images];
    if (newImages[index]) {
      newImages[index] = { ...newImages[index], src: imageUrl };
    } else {
      newImages[index] = { src: imageUrl };
    }
    setFieldValue("images", newImages);
  };

  return (
    <Card>
      <CardContent className="space-y-4 mt-6">
        <div>
          <h3 className="text-lg font-semibold mb-2">Main Image</h3>
          <MediaLibrarySelect
            value={values.images[0]?.src || ""}
            onChange={(imageUrl) => handleImageChange(0, imageUrl)}
          />
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Product Gallery</h3>
          <div className="grid grid-cols-3 gap-4">
            {[1, 2, 3, 4, 5, 6].map((index) => (
              <div key={index} className="flex flex-col gap-2">
                <span className="text-sm text-gray-500">Image {index}</span>
                <MediaLibrarySelect
                  value={values.images[index]?.src || ""}
                  onChange={(imageUrl) => handleImageChange(index, imageUrl)}
                />
              </div>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ProductImages;
