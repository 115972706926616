import React from "react";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import { Button } from "components/ui/button";
import { Popover, PopoverTrigger, PopoverContent } from "components/ui/popover";
import { User, Mail, MessageSquare, Pencil, X } from "lucide-react";
import FakeChat from "./FakeChat";
import { CUSTOMER_API } from "api/customers";
import { useQuery } from "@tanstack/react-query";
import SimpleSelect from "components/simple-select";
import { useFormikContext } from "formik";

const CustomerInfo = ({ subscription, customerId }) => {
  const { setFieldValue } = useFormikContext();
  const getCustomersQuery = useQuery({
    queryKey: [CUSTOMER_API.GET_ALL_CUSTOMERS.KEY],
    queryFn: () =>
      CUSTOMER_API.GET_ALL_CUSTOMERS.FN({
        params: { per_page: 100 },
      }),
  });

  const customerQuery = useQuery({
    queryKey: [CUSTOMER_API.GET_CUSTOMER.KEY, customerId],
    queryFn: () => CUSTOMER_API.GET_CUSTOMER.FN({ id: customerId }),
    enabled: !!customerId,
  });
  const customer = customerQuery.data;

  const [isWriteToBuyerDialogOpen, setIsWriteToBuyerDialogOpen] =
    React.useState(false);

  const customerOptions = React.useMemo(() => {
    return (
      getCustomersQuery.data?.map((c) => ({
        value: c.id,
        label: `${c.first_name} ${c.last_name}`,
      })) || []
    );
  }, [getCustomersQuery.data]);

  const handleCustomerChange = (selectedOption) => {
    setFieldValue("customer_id", selectedOption.value);
  };
  if (!customerQuery.data) {
    return (
      <div className="w-full">
        <SimpleSelect
          options={customerOptions}
          value={customerOptions.find((c) => c.value === customerId) || null}
          onChange={handleCustomerChange}
          isMulti={false}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-end">
      <div
        className="cursor-pointer"
        onClick={() => {
          setFieldValue("customer_id", null);
        }}
      >
        <X />
      </div>
      <div className="flex gap-4 w-full">
        <div className="flex items-center gap-4 mb-6 w-1/2">
          <Avatar>
            <AvatarImage src={customer?.avatar_url} />
            <AvatarFallback>
              {customer.first_name[0]}
              {customer.last_name[0]}
            </AvatarFallback>
          </Avatar>
          <div>
            <div className="flex items-center gap-2">
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="px-2 gap-2 hover:bg-transparent hover:underline"
                  >
                    {customer.first_name} {customer.last_name}{" "}
                    {/* {isCustomerDetailsOpen ? (
                    <ChevronUp className="h-4 w-4" />
                  ) : (
                    <ChevronDown className="h-4 w-4" />
                  )} */}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-60">
                  <div className="flex flex-col space-y-2">
                    <Button variant="ghost" className="justify-start">
                      <User className="mr-2 h-4 w-4" />
                      View Customer Profile
                    </Button>
                    <Button
                      variant="ghost"
                      className="justify-start"
                      onClick={() =>
                        (window.location.href = `mailto:${customer?.email}`)
                      }
                    >
                      <Mail className="mr-2 h-4 w-4" />
                      Send email to customer
                    </Button>
                  </div>
                </PopoverContent>
              </Popover>
            </div>
            <div className="flex flex-col items-start px-2 -mt-2">
              <div className="text-sm text-muted-foreground">
                {customer?.email}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start gap-2 mb-6 w-1/2">
          <div className="flex items-center gap-2">
            <MessageSquare className="h-5 w-5" />
            <span className="text-sm">No messages about this order yet</span>
          </div>
          <Button
            variant="outline"
            onClick={() => setIsWriteToBuyerDialogOpen(true)}
          >
            <Pencil className="h-4 w-4 mr-2" />
            Message buyer
          </Button>
        </div>

        <FakeChat
          isOpen={isWriteToBuyerDialogOpen}
          onOpenChange={setIsWriteToBuyerDialogOpen}
          customerName={`${customer.first_name}`}
          orderId={subscription.id}
        />
      </div>
    </div>
  );
};

export default CustomerInfo;
