import React, { useState, useEffect } from 'react';
import { Button } from 'components/ui/button';
import FormMultiSelect from 'components/form/form-multi-select';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { TAG_API } from 'api/tags';
import TagDialog from './TagDialog';
import ManageTagsDialog from './ManageTagsDialog';
import { LoadingSpinner } from 'components/ui/loadingSpinner';
import { Plus, CircleHelp } from 'lucide-react';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from 'components/ui/tooltip';

const TagSelect = ({ value = [], onChange, layout = "row" }) => {
  const [isNewTagDialogOpen, setIsNewTagDialogOpen] = useState(false);
  const [isManageTagsDialogOpen, setIsManageTagsDialogOpen] = useState(false);
  const [localTags, setLocalTags] = useState([]);
  const queryClient = useQueryClient();

  const { data: tags, isLoading } = useQuery({
    queryKey: [TAG_API.GET_ALL_TAGS.KEY],
    queryFn: () => TAG_API.GET_ALL_TAGS.FN({ params: { per_page: 100 } }),
  });

  useEffect(() => {
    if (tags) {
      setLocalTags(tags);
    }
  }, [tags]);

  if (isLoading) return <LoadingSpinner />;

	const tagOptions = localTags.map((tag) => ({
		value: tag.id.toString(),
		label: tag.name,
	}));

	const handleNewTag = (newTag) => {
		const formattedNewTag = {
			...newTag,
			id: newTag.id.toString(),
		};

    setLocalTags((prevTags) => [...prevTags, formattedNewTag]);

    queryClient.setQueryData([TAG_API.GET_ALL_TAGS.KEY], (oldData) => {
      if (Array.isArray(oldData)) {
        return [...oldData, formattedNewTag];
      }
      return oldData;
    });

    const newValue = [...value, formattedNewTag.id];
    onChange(newValue);
    setIsNewTagDialogOpen(false);
  };

	const handleTagChange = (newSelectedTags) => {
		onChange(newSelectedTags);
	};

	return (
		<div className='flex flex-col space-y-2'>
			<div>
				<FormMultiSelect
					name='tags'
					options={tagOptions}
					value={value.map((id) => ({
						value: id.toString(),
						label: localTags.find((tag) => tag.id.toString() === id.toString())?.name || '',
					}))}
					onChange={handleTagChange}
					label='Tags'
					className='flex-grow'
				/>
				<div className='flex flex-row gap-3 items-center mt-4'>
					<div className='w-[300px]'></div>

          <div className="w-full flex flex-row gap-2">
            <Button
              variant="outline"
              type="button"
              onClick={() => setIsNewTagDialogOpen(true)}
            >
              <Plus className="w-4 h-4 mr-2" /> New Tag
            </Button>
            <Button
              variant="ghost"
              type="button"
              onClick={() => setIsManageTagsDialogOpen(true)}
            >
              Manage Tags
            </Button>

            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <CircleHelp className="w-4 h-4 text-gray-500" />
                </TooltipTrigger>
                <TooltipContent>
                  <p>Manage existing tags and create new ones</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
      </div>
      <TagDialog
        isOpen={isNewTagDialogOpen}
        onClose={() => setIsNewTagDialogOpen(false)}
        onTagCreated={handleNewTag}
        allTags={localTags}
      />
      <ManageTagsDialog
        isOpen={isManageTagsDialogOpen}
        onClose={() => setIsManageTagsDialogOpen(false)}
        allTags={localTags}
      />
    </div>
  );
};

export default TagSelect;
