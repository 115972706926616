import React from "react";
import { useFormikContext } from "formik";
import { Card, CardContent } from "components/ui/card";
import { renderNormalField } from "components/renderNormalField";
import ProductTypeSelector from "./ProductTypeSelector";

const BasicProductInfo = () => {
  const { values } = useFormikContext();

  const basicFields = [
    { name: "name", label: "Product Name", type: "text", layout: "column" },
    {
      name: "regular_price",
      label: "Regular Price",
      type: "text",
      layout: "column",
    },
    {
      name: "sale_price",
      label: "Sale Price (optional)",
      type: "text",
      layout: "column",
    },
    {
      name: "status",
      label: "Product Status",
      type: "select",
      layout: "column",
      options: [
        { value: "publish", label: "Published" },
        { value: "draft", label: "Draft" },
      ],
    },
    {
      name: "short_description",
      label: "Short Description",
      type: "textarea",
      layout: "column",
    },
  ];

  if (values.type === "simple") {
    basicFields.splice(
      4,
      0,
      {
        name: "virtual",
        label: "Virtual Product",
        layout: "column",
        type: "checkbox",
      },
      {
        name: "downloadable",
        label: "Downloadable Product",
        layout: "column",
        type: "checkbox",
      }
    );
  }

  if (values.sale_price) {
    basicFields.splice(
      3,
      0,
      {
        name: "date_on_sale_from",
        label: "Sale Start Date",
        type: "date",
        layout: "column",
      },
      {
        name: "date_on_sale_to",
        label: "Sale End Date",
        type: "date",
        layout: "column",
      }
    );
  }

  return (
    <Card>
      <CardContent className="mt-6">
        <div className="space-y-6">
          <div className="space-y-2">
            <h3 className="text-sm font-medium">Product Type</h3>
            <ProductTypeSelector />
          </div>
          <div className="grid grid-cols-2 gap-4">
            {basicFields.slice(0, 1).map((field) => (
              <div key={field.name} className="col-span-2">
                {renderNormalField({
                  ...field,
                  value: values[field.name],
                })}
              </div>
            ))}
          </div>

          <div className="grid grid-cols-2 gap-4">
            {basicFields.slice(1).map((field, index) => (
              <div key={field.name} className="col-span-1">
                {renderNormalField({
                  ...field,
                  value: values[field.name],
                })}
              </div>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default BasicProductInfo;
