import React from "react";
import { format } from "date-fns";
import { CalendarIcon } from "@radix-ui/react-icons";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { Button } from "components/ui/button";
import { Calendar } from "components/ui/calendar";
import { cn } from "lib/utils";
import { Field } from "formik";
import { Label } from "components/ui/label";

export const FormDateRangePicker = ({
  name,
  label,
  labelColor = "text-gray-700",
  layout = "row",
  labelClassName = "",
  dateRangeClassName = "",
}) => (
  <Field name={name}>
    {({ field, form, meta }) => {
      const containerClassName = `flex ${
        layout === "row" ? "flex-row" : "flex-col"
      } gap-3 items-${layout === "row" ? "center" : "start"}`;

      return (
        <div>
          <div className={containerClassName}>
            {label && (
              <Label
                className={`${
                  layout === "row" ? "w-[300px]" : "w-full"
                } ${labelColor} ${labelClassName}`}
              >
                {label}
              </Label>
            )}
            <div className={layout === "row" ? "w-full" : "w-full"}>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={"outline"}
                    className={cn(
                      "w-full pl-3 text-left font-normal",
                      !field.value && "text-muted-foreground",
                      dateRangeClassName
                    )}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {field.value?.from ? (
                      field.value.to ? (
                        <>
                          {format(new Date(field.value.from), "LLL dd, y")} -{" "}
                          {format(new Date(field.value.to), "LLL dd, y")}
                        </>
                      ) : (
                        format(new Date(field.value.from), "LLL dd, y")
                      )
                    ) : (
                      <span>Pick a date range</span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    initialFocus
                    mode="range"
                    defaultMonth={
                      field.value?.from ? new Date(field.value.from) : undefined
                    }
                    selected={field.value}
                    onSelect={(dateRange) => {
                      form.setFieldValue(name, dateRange);
                    }}
                    numberOfMonths={2}
                  />
                </PopoverContent>
              </Popover>
            </div>
          </div>
          {meta.error && meta.touched ? (
            <div className="text-red-500 text-sm mt-1">{meta.error}</div>
          ) : null}
        </div>
      );
    }}
  </Field>
);

export default FormDateRangePicker;
