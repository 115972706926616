import React from "react";
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";
import AddressUpdateDialog from "../AddressSubscriptionUpdateDialog";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SUBSCRIPTION_API } from "api/subscription";
import { toast } from "components/ui/use-toast";
import { CUSTOMER_API } from "api/customers";

const AddressSubscriptionSection = ({ subscription, customerId }) => {
  const customerQuery = useQuery({
    queryKey: [CUSTOMER_API.GET_CUSTOMER.KEY, customerId],
    queryFn: () => CUSTOMER_API.GET_CUSTOMER.FN({ id: customerId }),
    enabled: !!customerId,
  });
  const customer = customerQuery.data;
  const [isAddressUpdateDialogOpen, setIsAddressUpdateDialogOpen] =
    React.useState(false);
  const [addressType, setAddressType] = React.useState(null);
  const queryClient = useQueryClient();

  const openAddressUpdateDialog = (type) => {
    setAddressType(type);
    setIsAddressUpdateDialogOpen(true);
  };
  const updateSubscriptionMutation = useMutation({
    mutationFn: SUBSCRIPTION_API.UPDATE_SUBSCRIPTION.FN,
    onSuccess: () => {
      queryClient.invalidateQueries([
        SUBSCRIPTION_API.UPDATE_SUBSCRIPTION.KEY,
        { id: subscription.subscriptionId },
      ]);
      toast({
        title: "Success",
        description: `Address updated successfully`,
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: `Failed to update  address`,
        variant: "destructive",
      });
    },
  });
  const handleAddressUpdate = () => {
    // Invalidate and refetch the subscription data
    queryClient.invalidateQueries([
      SUBSCRIPTION_API.GET_SUBSCRIPTION_BY_ID.KEY,
      { id: subscription.id },
    ]);
  };

  const handleSubmit = (values, addressType) => {
    if (!customer) {
      toast({
        title: "Error",
        description: `Please Select a customer first`,
        variant: "destructive",
      });
      return;
    }

    let updateData = {};
    if (addressType === "shipping") {
      updateData = {
        shipping: values,
      };
    } else {
      updateData = {
        billing: values,
      };
    }

    updateSubscriptionMutation.mutate({ id: subscription?.id, updateData });
  };

  return (
    <>
      <Separator className="my-6" />
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">
          Shipping & Billing Address
        </h3>
        {/* <p className="text-sm text-muted-foreground">
          Transaction ID:{" "}
          {subscription.transaction_id ? subscription.transaction_id : "N/A"}
        </p> */}
      </div>
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <div className="flex  justify-between">
            <h4 className="font-semibold mb-2">Ship to</h4>

            <Button
              size="xs"
              className="text-xs p-1"
              onClick={() => {
                handleSubmit(customer.shipping, "shipping");
              }}
            >
              Copy
            </Button>
          </div>
          <p>
            {subscription.shipping.first_name} {subscription.shipping.last_name}
          </p>
          <p>{subscription.shipping.address_1}</p>
          <p>
            {subscription.shipping.city}, {subscription.shipping.state}{" "}
            {subscription.shipping.postcode}
          </p>
          <p>{subscription.shipping.country}</p>
          <Button
            variant="link"
            className="p-0 mt-2"
            onClick={() => openAddressUpdateDialog("shipping")}
          >
            Update shipping address
          </Button>
        </div>
        <div>
          <div className="flex  justify-between">
            <h4 className="font-semibold mb-2">Billed to</h4>

            <Button
              size="xs"
              className="text-xs p-1"
              onClick={() => {
                handleSubmit(customer.billing, "billing");
              }}
            >
              Copy
            </Button>
          </div>
          <p>
            {subscription.billing.first_name} {subscription.billing.last_name}
          </p>
          <p>{subscription.billing.address_1}</p>
          <p>
            {subscription.billing.city}, {subscription.billing.state}{" "}
            {subscription.billing.postcode}
          </p>
          <p>{subscription.billing.country}</p>
          <Button
            variant="link"
            className="p-0 mt-2"
            onClick={() => openAddressUpdateDialog("billing")}
          >
            Update billing address
          </Button>
        </div>
      </div>

      <AddressUpdateDialog
        isOpen={isAddressUpdateDialogOpen}
        onClose={() => setIsAddressUpdateDialogOpen(false)}
        subscriptionId={subscription.id}
        shippingAddress={subscription.shipping}
        billingAddress={subscription.billing}
        onUpdate={handleAddressUpdate}
        addressType={addressType}
      />
    </>
  );
};

export default AddressSubscriptionSection;
