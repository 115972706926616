import React from "react";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import { Button } from "components/ui/button";
import { Popover, PopoverTrigger, PopoverContent } from "components/ui/popover";
import {
  ChevronUp,
  ChevronDown,
  User,
  Mail,
  MessageSquare,
  Pencil,
} from "lucide-react";
import FakeChat from "./FakeChat";

const CustomerInfo = ({ order, customer, onOpenCustomerSheet }) => {
  const [isCustomerDetailsOpen, setIsCustomerDetailsOpen] =
    React.useState(false); // eslint-disable-line
  const [isWriteToBuyerDialogOpen, setIsWriteToBuyerDialogOpen] =
    React.useState(false);

  return (
    <div className="flex gap-4 w-full">
      <div className="flex items-center gap-4 mb-6 w-1/2">
        <Avatar>
          <AvatarImage src={customer?.avatar_url} />
          <AvatarFallback>
            {order.billing.first_name[0]}
            {order.billing.last_name[0]}
          </AvatarFallback>
        </Avatar>
        <div>
          <div className="flex items-center gap-2">
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="ghost"
                  size="sm"
                  className="px-2 gap-2 hover:bg-transparent hover:underline"
                >
                  {order.billing.first_name} {order.billing.last_name}{" "}
                  {isCustomerDetailsOpen ? (
                    <ChevronUp className="h-4 w-4" />
                  ) : (
                    <ChevronDown className="h-4 w-4" />
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-60">
                <div className="flex flex-col space-y-2">
                  <Button
                    variant="ghost"
                    className="justify-start"
                    onClick={() => onOpenCustomerSheet(customer)}
                  >
                    <User className="mr-2 h-4 w-4" />
                    View Customer Profile
                  </Button>
                  <Button
                    variant="ghost"
                    className="justify-start"
                    onClick={() =>
                      (window.location.href = `mailto:${customer?.email}`)
                    }
                  >
                    <Mail className="mr-2 h-4 w-4" />
                    Send email to customer
                  </Button>
                </div>
              </PopoverContent>
            </Popover>
          </div>
          <div className="flex flex-col items-start px-2 -mt-2">
            <div className="text-sm text-muted-foreground">
              {customer?.email}
            </div>
            <Button
              variant="link"
              className="p-0 h-auto text-sm"
              onClick={() => onOpenCustomerSheet(customer)}
            >
              Order history
            </Button>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-start gap-2 mb-6 w-1/2">
        <div className="flex items-center gap-2">
          <MessageSquare className="h-5 w-5" />
          <span className="text-sm">No messages about this order yet</span>
        </div>
        <Button
          variant="outline"
          onClick={() => setIsWriteToBuyerDialogOpen(true)}
        >
          <Pencil className="h-4 w-4 mr-2" />
          Message buyer
        </Button>
      </div>

      <FakeChat
        isOpen={isWriteToBuyerDialogOpen}
        onOpenChange={setIsWriteToBuyerDialogOpen}
        customerName={`${order.billing.first_name}`}
        orderId={order.id}
      />
    </div>
  );
};

export default CustomerInfo;
