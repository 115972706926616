export const printSubscription = (subscription) => {
  const printContent = `
        <html>
            <head>
                <title>Subscription #${subscription.id}</title>
                <style>
                    body { 
                        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                        line-height: 1.6;
                        color: #333;
                        max-width: 800px;
                        margin: 0 auto;
                        padding: 20px;
                    }
                    .header { 
                        text-align: center;
                        margin-bottom: 30px;
                        border-bottom: 2px solid #f0f0f0;
                        padding-bottom: 20px;
                    }
                    .header h1 {
                        color: #2c3e50;
                        font-size: 28px;
                    }
                    .subscription-details {
                        background-color: #f9f9f9;
                        border-radius: 5px;
                        padding: 20px;
                        margin-bottom: 30px;
                    }
                    .subscription-details p {
                        margin: 10px 0;
                    }
                    .items-table {
                        width: 100%;
                        border-collapse: separate;
                        border-spacing: 0;
                    }
                    .items-table th, .items-table td {
                        border: 1px solid #e0e0e0;
                        padding: 12px;
                        text-align: left;
                    }
                    .items-table th {
                        background-color: #f2f2f2;
                        font-weight: 600;
                        text-transform: uppercase;
                        font-size: 14px;
                    }
                    .items-table tr:nth-child(even) {
                        background-color: #fafafa;
                    }
                    .total {
                        margin-top: 30px;
                        text-align: right;
                        font-size: 18px;
                        font-weight: bold;
                    }
                    @media print {
                        body { print-color-adjust: exact; -webkit-print-color-adjust: exact; }
                    }
                </style>
            </head>
            <body>
                <div class="header">
                    <h1>Subscription #${subscription.id}</h1>
                </div>
                <div class="subscription-details">
                    <p><strong>Status:</strong> ${subscription.status}</p>
                    <p><strong>Start Date:</strong> ${new Date(
                      subscription.start_date_gmt
                    ).toLocaleDateString()}</p>
                    <p><strong>Next Payment Date:</strong> ${new Date(
                      subscription.next_payment_date_gmt
                    ).toLocaleDateString()}</p>
                    <p><strong>Billing Period:</strong> ${
                      subscription.billing_period
                    }</p>
                    <p><strong>Billing Interval:</strong> ${
                      subscription.billing_interval
                    }</p>
                    <p><strong>Customer:</strong> ${
                      subscription.billing.first_name
                    } ${subscription.billing.last_name}</p>
                    <p><strong>Email:</strong> ${subscription.billing.email}</p>
                </div>
                <table class="items-table">
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${subscription.line_items
                          .map(
                            (item) => `
                            <tr>
                                <td>${item.name}</td>
                                <td>${item.quantity}</td>
                                <td>$${parseFloat(item.price).toFixed(2)}</td>
                            </tr>
                        `
                          )
                          .join("")}
                    </tbody>
                </table>
                <div class="total">
                    <p>Total: $${parseFloat(subscription.total).toFixed(2)}</p>
                </div>
            </body>
        </html>
    `;

  const printWindow = window.open("", "_blank");
  printWindow.document.write(printContent);
  printWindow.document.close();

  printWindow.onafterprint = () => {
    printWindow.close();
  };

  printWindow.print();

  // Fallback for browsers that don't support onafterprint
  setTimeout(() => {
    if (!printWindow.closed) {
      printWindow.close();
    }
  }, 1000);
};
