import React, { useState } from "react";
import { Button } from "components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import {
  ChevronDown,
  Printer,
  FileText,
  RefreshCw,
  Trash2,
  Check,
} from "lucide-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "components/ui/use-toast";
import ReusableDialogButton from "components/reusable-dialog-button";
import RefundModal from "../order-summary/RefundModal";
import { printSubscription } from "../../print/PrintFunctions";
import { generateSubscriptionPDF } from "../../pdf/GenerateInvoicePDF";
import { StickyNote } from "lucide-react";
import NotesDialog from "../notes/NotesDialog";
import { SUBSCRIPTION_API } from "api/subscription";

const SubscriptionActionsDropdown = ({
  subscription,
  isEdit,
  deleteMutate,
  deleteLoading,
}) => {
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [isNotesDialogOpen, setIsNotesDialogOpen] = useState(false);
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const updateSubscriptionMutation = useMutation({
    mutationFn: SUBSCRIPTION_API.UPDATE_SUBSCRIPTION.FN,
    onSuccess: () => {
      queryClient.invalidateQueries([
        SUBSCRIPTION_API.GET_SUBSCRIPTION_BY_ID.KEY,
        { id: subscription.id },
      ]);
      toast({
        title: "Success",
        description: `Subscription status updated`,
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to update subscription status",
        variant: "destructive",
      });
    },
  });

  const regenerateDownloadPermissionsMutation = useMutation({
    mutationFn: SUBSCRIPTION_API.REGENERATE_DOWNLOAD_PERMISSIONS.FN,
    onSuccess: () => {
      toast({
        title: "Success",
        description: "Download permissions regenerated",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to regenerate download permissions",
        variant: "destructive",
      });
    },
  });

  const processRenewalMutation = useMutation({
    mutationFn: SUBSCRIPTION_API.PROCESS_RENEWAL.FN,
    onSuccess: () => {
      toast({
        title: "Success",
        description: "Renewal processed successfully",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to process renewal",
        variant: "destructive",
      });
    },
  });

  const createRenewalOrderMutation = useMutation({
    mutationFn: SUBSCRIPTION_API.CREATE_RENEWAL_ORDER.FN,
    onSuccess: () => {
      toast({
        title: "Success",
        description: "Renewal order created successfully",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to create renewal order",
        variant: "destructive",
      });
    },
  });

  const handleSubscriptionStatusChange = async (value) => {
    updateSubscriptionMutation.mutate({
      id: subscription.id,
      updateData: { status: value },
    });
  };

  const handleRegenerateDownloadPermissions = () => {
    regenerateDownloadPermissionsMutation.mutate(subscription.id);
  };

  const handleProcessRenewal = () => {
    processRenewalMutation.mutate(subscription.id);
  };

  const handleCreateRenewalOrder = () => {
    createRenewalOrderMutation.mutate(subscription.id);
  };

  if (!subscription) return null;

  const handlePrintOrder = () => {
    printSubscription(subscription);
  };

  const handleGenerateInvoice = () => {
    generateSubscriptionPDF(subscription);
  };

  const handleRefund = () => {
    setIsRefundModalOpen(true);
  };

  const handleOpenNotesDialog = () => {
    setIsNotesDialogOpen(true);
  };

  const isSubscriptionCompleted = subscription.status === "completed";

  return (
    <div className="flex space-x-2">
      {isSubscriptionCompleted ? (
        <div className="flex items-center border transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-green-700 bg-green-50 ring-green-600/20 gap-2 rounded-md py-1 px-4 text-sm font-medium ring-1 ring-inset">
          <Check className="h-4 w-4" />
          <span>Completed</span>
        </div>
      ) : (
        <Select
          onValueChange={handleSubscriptionStatusChange}
          defaultValue={subscription.status}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Update Status" />
          </SelectTrigger>

          <SelectContent>
            <SelectItem value="pending">Pending</SelectItem>

            <SelectItem value="active">Active</SelectItem>
            <SelectItem value="on-hold">On Hold</SelectItem>
            <SelectItem value="cancelled">Cancelled</SelectItem>
            <SelectItem value="expired">Expired</SelectItem>
            <SelectItem value="switched">Switched</SelectItem>
            <SelectItem value="pending-cancel">Pending Cancel</SelectItem>
          </SelectContent>
        </Select>
      )}

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline">
            More Actions <ChevronDown className="ml-2 h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={handlePrintOrder}>
            <Printer className="mr-2 h-4 w-4" />
            Print subscription
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleGenerateInvoice}>
            <FileText className="mr-2 h-4 w-4" />
            Generate PDF invoice
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleRefund}>
            <RefreshCw className="mr-2 h-4 w-4" />
            Refund
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleOpenNotesDialog}>
            <StickyNote className="mr-2 h-4 w-4" />
            Subscription notes
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleRegenerateDownloadPermissions}>
            <RefreshCw className="mr-2 h-4 w-4" />
            Regenerate Download Permissions
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleProcessRenewal}>
            <RefreshCw className="mr-2 h-4 w-4" />
            Process Renewal
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleCreateRenewalOrder}>
            <FileText className="mr-2 h-4 w-4" />
            Create Renewal Order
          </DropdownMenuItem>
          {isEdit &&
            subscription &&
            subscription.id &&
            !isSubscriptionCompleted && (
              <DropdownMenuItem>
                <ReusableDialogButton
                  triggerText="Delete subscription"
                  onConfirm={() => deleteMutate({ id: subscription.id })}
                  dialogTitle="Delete Order"
                  dialogDescription="Are you sure you want to delete this subscription?"
                  cancelText="Cancel"
                  className="text-red-500 hover:text-red-600 flex items-center"
                  confirmText="Confirm"
                  isLoading={deleteLoading}
                >
                  <Trash2 className="mr-2 h-4 w-4" />
                  Delete subscription
                </ReusableDialogButton>
              </DropdownMenuItem>
            )}
        </DropdownMenuContent>
      </DropdownMenu>

      <RefundModal
        subscriptionId={subscription.id}
        isOpen={isRefundModalOpen}
        onClose={() => setIsRefundModalOpen(false)}
      />
      <NotesDialog
        subscriptionId={subscription.id}
        isOpen={isNotesDialogOpen}
        onClose={() => setIsNotesDialogOpen(false)}
      />
    </div>
  );
};

export default SubscriptionActionsDropdown;
