import { useQuery } from "@tanstack/react-query";
import { SUBSCRIPTION_API } from "api/subscription";
import React from "react";

const RelatedOrder = ({ subscriptionId }) => {
  const subscriptionQuery = useQuery({
    queryKey: [
      SUBSCRIPTION_API.GET_SUBSCRIPTION_ORDERS.KEY,
      { id: subscriptionId },
    ],
    queryFn: () =>
      SUBSCRIPTION_API.GET_SUBSCRIPTION_ORDERS.FN({ id: subscriptionId }),
    enabled: !!subscriptionId,
  });

  console.log(subscriptionQuery.data);

  if (!subscriptionQuery.data) return null;

  return (
    <div>
      RelatedOrder
      {subscriptionQuery?.data.map((item) => (
        <div
          key={item.id}
          className="grid grid-cols-12 gap-4 items-center mb-4"
        >
          <div className="col-span-7 flex items-center gap-2">
            <div>
              <p className="font-medium">{item.name}</p>
              <p className="text-sm text-muted-foreground">
                SKU: {item.sku}, ID: {item.product_id}
              </p>
            </div>
          </div>
          <div className="col-span-2 text-center">{item.quantity}</div>
          <div className="col-span-3 text-right">
            {/* {subscription.currency_symbol} */}
            {parseFloat(item.price).toFixed(2)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default RelatedOrder;
