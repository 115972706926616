import React from "react";
import { useFormikContext } from "formik";
import FormInput from "./form/form-input";
import FormMultiSelect from "./form/form-multi-select";
import FormTabs from "./form/form-tabs";
import FormSelect from "./form/form-select";
import FormDate from "./form/form-date";
import FormTextarea from "./form/form-textarea";
import { WYSIWYGEditor } from "./form/form-editor";
import { FormDateRangePicker } from "./form/form-date-range-picker";
import { CardTitle } from "./ui/card";
import { Field } from "formik";
import { Label } from "./ui/label";
import FormDimentions from "./form/form-dimentions";
import FormFileUploadArray from "./form/form-file-array-upload";

export const renderNormalField = (field, setFieldValue) => {
  const { name, value, label, type, options, layout = "row" } = field;

  switch (type) {
    case "heading":
      return <CardTitle className="text-md">{label}</CardTitle>;
    case "text":
      return (
        <FormInput type={type} name={name} label={label} layout={layout} />
      );
    case "select":
      return (
        <FormSelect
          options={options}
          name={name}
          label={label}
          layout={layout}
        />
      );
    case "checkbox":
      return <FormTabs name={name} label={label} layout={layout} />;
    case "multiselect":
      return (
        <FormMultiSelect
          options={options}
          name={name}
          label={label}
          layout={layout}
        />
      );

    case "color":
      return (
        <FormInput type={type} name={name} label={label} layout={layout} />
      );

    case "textarea":
      return <FormTextarea name={name} label={label} layout={layout} />;

    case "editor":
      return (
        <WYSIWYGEditor
          name={name}
          label={label}
          value={value}
          onChange={(newValue) => setFieldValue(name, newValue)}
        />
      );

    case "email":
      return (
        <FormInput type={type} name={name} label={label} layout={layout} />
      );

    case "date":
      return <FormDate name={name} label={label} layout={layout} />;

    case "date-range":
      return <FormDateRangePicker name={name} label={label} layout={layout} />;

    case "number":
      return (
        <FormInput type={type} name={name} label={label} layout={layout} />
      );
    case "dimensions":
      return (
        <FormDimentions type={type} name={name} label={label} layout={layout} />
      );
    case "file-array":
      return <FormFileUploadArray name={name} label={label} layout={layout} />;

    case "custom":
      return (
        <Field name={name}>
          {({ field: formikField }) => (
            <div className="flex flex-col gap-2">
              <Label htmlFor={name}>{label}</Label>
              <field.component
                id={name}
                {...formikField}
                onChange={(value) => {
                  console.log("value", value);
                  setFieldValue(name, value);
                }}
              />
            </div>
          )}
        </Field>
      );

    default:
      return null;
  }
};
