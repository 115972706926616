import { axiosClient } from "../axios";

// Create a new subscription
const createSubscription = async (data) => {
  try {
    const response = await axiosClient.post("subscriptions", data);
    return response.data;
  } catch (error) {
    console.error("Error creating subscription:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

// Retrieve all subscriptions
const getAllSubscriptions = async (params) => {
  try {
    const response = await axiosClient.get("subscriptions", { ...params });
    return response.data;
  } catch (error) {
    console.error("Error fetching subscriptions:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

// Retrieve a specific subscription by ID
const getSubscriptionById = async ({ id }) => {
  try {
    const response = await axiosClient.get(`subscriptions/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching subscription by id:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

// Update an existing subscription
const updateSubscription = async ({ id, updateData }) => {
  try {
    const response = await axiosClient.put(`subscriptions/${id}`, updateData);
    return response.data;
  } catch (error) {
    console.error("Error updating subscription:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

// Delete a subscription
const deleteSubscription = async (id) => {
  try {
    const response = await axiosClient.delete(`subscriptions/${id}`, {
      params: { force: true },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting subscription:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

// Retrieve subscription statuses
const getSubscriptionStatuses = async () => {
  try {
    const response = await axiosClient.get("subscriptions/statuses");
    return response.data;
  } catch (error) {
    console.error("Error fetching subscription statuses:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

// Batch update subscriptions
const batchUpdateSubscriptions = async (data) => {
  try {
    const response = await axiosClient.post("subscriptions/batch", data);
    return response.data;
  } catch (error) {
    console.error("Error batch updating subscriptions:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

const regenerateDownloadPermissions = async (id) => {
  try {
    const response = await axiosClient.post(
      `orders/${id}/actions/regenerate_download_permissions`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error("Error regenerating download permissions:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

// Process renewal for a subscription
const processRenewal = async (id) => {
  try {
    const response = await axiosClient.post(
      `orders/${id}/actions/process_renewal`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error("Error processing renewal:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

// Create renewal order for a subscription
const createRenewalOrder = async (id) => {
  try {
    const response = await axiosClient.post(
      `orders/${id}/actions/create_renewal_order`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error("Error creating renewal order:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};
export const deleteSubscriptionNote = async ({ subscriptionId, noteId }) => {
  try {
    const response = await axiosClient.delete(
      `subscriptions/${subscriptionId}/notes/${noteId}`,
      {
        params: { force: true },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error deleting subscription note: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};
export const addSubscriptionNote = async ({ subscriptionId, noteData }) => {
  try {
    const response = await axiosClient.post(
      `subscriptions/${subscriptionId}/notes`,
      noteData
    );
    return response.data;
  } catch (error) {
    console.log("Error adding subscription note: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getAllSubscriptionNotes = async ({ subscriptionId }) => {
  try {
    const { data } = await axiosClient.get(
      `subscriptions/${subscriptionId}/notes`
    );
    return data;
  } catch (error) {
    console.log("Error fetching subscription notes: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const createRefund = async ({ subscriptionId, refundData }) => {
  try {
    const response = await axiosClient.post(
      `subscriptions/${subscriptionId}/refunds`,
      refundData
    );
    return response.data;
  } catch (error) {
    console.log("Error creating refund: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

const getSubscriptionOrders = async ({ id, params }) => {
  try {
    const response = await axiosClient.get(`subscriptions/${id}/orders`, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching subscription orders:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const SUBSCRIPTION_API = {
  CREATE_SUBSCRIPTION: {
    KEY: "CREATE_SUBSCRIPTION",
    FN: createSubscription,
  },
  GET_ALL_SUBSCRIPTIONS: {
    KEY: "GET_ALL_SUBSCRIPTIONS",
    FN: getAllSubscriptions,
  },
  GET_SUBSCRIPTION_BY_ID: {
    KEY: "GET_SUBSCRIPTION_BY_ID",
    FN: getSubscriptionById,
  },
  UPDATE_SUBSCRIPTION: {
    KEY: "UPDATE_SUBSCRIPTION",
    FN: updateSubscription,
  },
  DELETE_SUBSCRIPTION: {
    KEY: "DELETE_SUBSCRIPTION",
    FN: deleteSubscription,
  },
  GET_SUBSCRIPTION_STATUSES: {
    KEY: "GET_SUBSCRIPTION_STATUSES",
    FN: getSubscriptionStatuses,
  },
  BATCH_UPDATE_SUBSCRIPTIONS: {
    KEY: "BATCH_UPDATE_SUBSCRIPTIONS",
    FN: batchUpdateSubscriptions,
  },
  // New endpoints
  REGENERATE_DOWNLOAD_PERMISSIONS: {
    KEY: "REGENERATE_DOWNLOAD_PERMISSIONS",
    FN: regenerateDownloadPermissions,
  },
  PROCESS_RENEWAL: {
    KEY: "PROCESS_RENEWAL",
    FN: processRenewal,
  },
  CREATE_RENEWAL_ORDER: {
    KEY: "CREATE_RENEWAL_ORDER",
    FN: createRenewalOrder,
  },
  CREATE_REFUND: {
    KEY: "CREATE_REFUND",
    FN: createRefund,
  },
  GET_ALL_SUBSCRIPTION_NOTES: {
    KEY: "GET_ALL_SUBSCRIPTION_NOTES",
    FN: getAllSubscriptionNotes,
  },
  ADD_SUBSCRIPTION_NOTE: {
    KEY: "ADD_SUBSCRIPTION_NOTE",
    FN: addSubscriptionNote,
  },
  DELETE_SUBSCRIPTION_NOTE: {
    KEY: "DELETE_SUBSCRIPTION_NOTE",
    FN: deleteSubscriptionNote,
  },
  GET_SUBSCRIPTION_ORDERS: {
    KEY: "GET_SUBSCRIPTION_ORDERS",
    FN: getSubscriptionOrders,
  },
};
