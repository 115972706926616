import React from "react";
import { renderNormalField } from "components/renderNormalField";
import { Separator } from "components/ui/separator";

const ScheduleComponent = () => {
  const fields = [
    {
      name: "billing_interval",
      label: "Interval",
      type: "select",
      options: [
        { value: "1", label: "Every" },
        { value: "2", label: "Every 2nd" },
        { value: "3", label: "Every 3rd" },
      ],
      layout: "column",
    },
    {
      name: "billing_period",
      label: "Period",
      layout: "column",
      type: "select",
      options: [
        { value: "day", label: "Day" },
        { value: "week", label: "Week" },
        { value: "month", label: "Month" },
        { value: "year", label: "Year" },
      ],
    },
    {
      name: "trial_end_date_gmt",
      label: "Trial end",
      layout: "column",
      type: "date",
    },
    {
      name: "next_payment_date_gmt",
      label: "Next payment",
      type: "date",
      layout: "column",
    },
  ];

  return (
    <div>
      <Separator className="my-6" />
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Schedule</h3>
        <div className="grid grid-cols-2 gap-4 mb-6 mt-6">
          {fields.map((field) => (
            <div key={field.name} className="flex flex-col space-y-2">
              {renderNormalField(field)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScheduleComponent;
