import React from "react";
import { CardHeader, CardTitle } from "components/ui/card";
import { MoveRight, Tag, ExternalLink } from "lucide-react";
import SubscriptionActionsDropdown from "./SubscriptionActionsDropdown";
import { BASE_URL } from "api/axios";

const SubscriptionHeader = ({
  subscription,
  isEdit,
  deleteMutate,
  deleteLoading,
}) => {
  if (!subscription) return null;

  const wooCommerceOrderUrl = `${BASE_URL}/wp-admin/admin.php?page=wc-subscriptions&action=edit&id=${subscription.id}`;

  return (
    <CardHeader className="mt-0 pt-0 pl-0 pr-4">
      <div className="flex justify-between items-start">
        <div className="flex items-center gap-2">
          <CardTitle className="text-2xl font-bold">
            Subscription from {subscription.billing.first_name ?? "Demo"}
          </CardTitle>
          <a
            href={wooCommerceOrderUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-900 hover:text-gray-700"
            title="Open in WooCommerce"
          >
            <ExternalLink className="h-5 w-5" />
          </a>
        </div>
        <div className="text-sm text-right">
          <p>
            Subscribed on{" "}
            {new Date(subscription.date_created).toLocaleDateString()}
          </p>
          {subscription.shipping ? (
            <p>
              Ship to {subscription.shipping.city},{" "}
              {subscription.shipping.country}
            </p>
          ) : (
            <p>Virtual subscription</p>
          )}
        </div>
      </div>
      <div className="text-sm flex flex-col pb-2">
        <p>
          n°{subscription.id} via{" "}
          {subscription.payment_method || "Not specified"}
        </p>
        <p className="flex items-center gap-2">
          <span>{subscription.line_items.length} item(s)</span>
          <MoveRight className="h-4 w-4" />
          <span>
            {subscription.currency_symbol}
            {parseFloat(subscription.total).toFixed(2)}
          </span>
        </p>
        {subscription.coupon_code && (
          <p className="flex items-center">
            <Tag className="h-4 w-4 mr-2" /> {subscription.coupon_code}
          </p>
        )}
      </div>
      <div className="flex gap-2 mt-4">
        <SubscriptionActionsDropdown
          subscription={subscription}
          isEdit={isEdit}
          deleteMutate={deleteMutate}
          deleteLoading={deleteLoading}
        />
      </div>
    </CardHeader>
  );
};

export default SubscriptionHeader;
