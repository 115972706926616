import React, { useState, useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ORDER_API } from "api/orders";
import { CUSTOMER_API } from "api/customers";
import { Sheet, SheetContent } from "components/ui/sheet";
import { ScrollArea } from "components/ui/scroll-area";
import OrderHeader from "./header/OrderHeader";
import CustomerInfo from "./customer/CustomerInfo";
import AddressSection from "./address/AddressSection";
import OrderItems from "./items/OrderItems";
import OrderActions from "./actions/OrderActions";
import CustomerSheet from "pages/customers/_ui/customerSheet";
import { Skeleton } from "components/ui/skeleton";

const OrderSheet = ({ isOpen, onClose, orderId, isEdit, initialOrderData }) => {
  const [isCustomerSheetOpen, setIsCustomerSheetOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const queryClient = useQueryClient(); // eslint-disable-line

  const orderQuery = useQuery({
    queryKey: [ORDER_API.GET_ORDER_BY_ID.KEY, orderId],
    queryFn: () => ORDER_API.GET_ORDER_BY_ID.FN({ id: orderId }),
    enabled: !!orderId && isOpen,
    initialData: initialOrderData,
  });

  const customerQuery = useQuery({
    queryKey: [CUSTOMER_API.GET_CUSTOMER.KEY, orderQuery.data?.customer_id],
    queryFn: () =>
      CUSTOMER_API.GET_CUSTOMER.FN({ id: orderQuery.data?.customer_id }),
    enabled: !!orderQuery.data?.customer_id && isOpen,
  });

  const handleOpenCustomerSheet = useCallback((customer) => {
    setSelectedCustomer(customer);
    setIsCustomerSheetOpen(true);
  }, []);

  const isLoading = orderQuery.isLoading || customerQuery.isLoading;
  const order = orderQuery.data;
  const customer = customerQuery.data;

  const OrderSkeleton = () => (
    <div className="space-y-6 p-6">
      <Skeleton className="h-10 w-3/4" />
      <div className="space-y-2">
        <Skeleton className="h-4 w-1/2" />
        <Skeleton className="h-4 w-1/3" />
      </div>
      <div className="space-y-2">
        <Skeleton className="h-4 w-1/2" />
        <Skeleton className="h-4 w-1/3" />
      </div>
      <Skeleton className="h-40 w-full" />
      <div className="flex justify-end space-x-2">
        <Skeleton className="h-10 w-24" />
        <Skeleton className="h-10 w-24" />
      </div>
    </div>
  );

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent className="sm:max-w-[800px] p-0">
        <ScrollArea className="h-full">
          {isLoading ? (
            <OrderSkeleton />
          ) : orderQuery.isError ? (
            <div className="p-6">
              Error loading order: {orderQuery.error.message}
            </div>
          ) : order ? (
            <div className="p-6">
              <OrderHeader order={order} />
              <CustomerInfo
                order={order}
                customer={customer}
                onOpenCustomerSheet={handleOpenCustomerSheet}
              />
              <AddressSection order={order} />
              <OrderItems order={order} />
              <OrderActions order={order} isEdit={isEdit} />
            </div>
          ) : null}
        </ScrollArea>
      </SheetContent>
      <CustomerSheet
        isOpen={isCustomerSheetOpen}
        onClose={() => setIsCustomerSheetOpen(false)}
        customer={selectedCustomer}
        isEdit={true}
      />
    </Sheet>
  );
};

export default React.memo(OrderSheet);
