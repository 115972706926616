import * as React from 'react';
import { cva } from 'class-variance-authority';
import { CheckIcon, XIcon, ChevronDown, WandSparkles } from 'lucide-react';

import { cn } from 'lib/utils';
import { Separator } from 'components/ui/separator';
import { Button } from 'components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from 'components/ui/command';

const multiSelectVariants = cva(
	'inline-flex items-center gap-2 rounded-md border border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80 px-1.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
	{
		variants: {
			variant: {
				default: '',
				secondary: '',
				destructive:
					'bg-destructive text-destructive-foreground hover:bg-destructive/80',
				inverted: 'bg-background text-foreground',
			},
		},
		defaultVariants: {
			variant: 'default',
		},
	}
);

const MultiSelect = React.forwardRef(
	(
		{
			options,
			onValueChange,
			variant,
			defaultValue = [],
			placeholder = 'Select options',
			animation = 0,
			maxCount = 3,
			modalPopover = false,
			asChild = false,
			className,
			...props
		},
		ref
	) => {
		const [selectedValues, setSelectedValues] =
			React.useState(defaultValue);
		const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
		const [isAnimating, setIsAnimating] = React.useState(false);

		const handleInputKeyDown = (event) => {
			if (event.key === 'Enter') {
				setIsPopoverOpen(true);
			} else if (
				event.key === 'Backspace' &&
				!event.currentTarget.value
			) {
				const newSelectedValues = [...selectedValues];
				newSelectedValues.pop();
				setSelectedValues(newSelectedValues);
				onValueChange(newSelectedValues);
			}
		};

		const toggleOption = (option) => {
			const newSelectedValues = selectedValues.includes(option)
				? selectedValues.filter((value) => value !== option)
				: [...selectedValues, option];
			setSelectedValues(newSelectedValues);
			onValueChange(newSelectedValues);
		};

		const handleClear = () => {
			setSelectedValues([]);
			onValueChange([]);
		};

		const handleTogglePopover = () => {
			setIsPopoverOpen((prev) => !prev);
		};

		const clearExtraOptions = () => {
			const newSelectedValues = selectedValues.slice(0, maxCount);
			setSelectedValues(newSelectedValues);
			onValueChange(newSelectedValues);
		};

		const toggleAll = () => {
			if (selectedValues.length === options.length) {
				handleClear();
			} else {
				const allValues = options.map((option) => option.value);
				setSelectedValues(allValues);
				onValueChange(allValues);
			}
		};

		return (
			<Popover
				open={isPopoverOpen}
				onOpenChange={setIsPopoverOpen}
				modal={modalPopover}>
				<PopoverTrigger asChild>
					<Button
						ref={ref}
						{...props}
						onClick={handleTogglePopover}
						className={cn(
							'flex w-full p-1 rounded-md border min-h-10 h-auto items-center justify-between bg-inherit hover:bg-inherit',
							className
						)}>
						{selectedValues.length > 0 ? (
							<div className='flex flex-wrap items-center gap-1'>
								{selectedValues
									.slice(0, maxCount)
									.map((value) => {
										const option = options.find(
											(o) => o.value === value
										);
										return (
											<div
												key={value}
												className={cn(
													multiSelectVariants({
														variant,
													})
												)}>
												<span>{option?.label}</span>
												<XIcon
													className='h-3 w-3 cursor-pointer opacity-50'
													onClick={(event) => {
														event.stopPropagation();
														toggleOption(value);
													}}
												/>
											</div>
										);
									})}
								{selectedValues.length > maxCount && (
									<div
										className={cn(
											multiSelectVariants({ variant })
										)}>
										+{selectedValues.length - maxCount} more
									</div>
								)}
							</div>
						) : (
							<span className='text-sm text-muted-foreground font-normal ml-1'>
								{placeholder}
							</span>
						)}
						<ChevronDown className='h-4 w-4 opacity-50' />
					</Button>
				</PopoverTrigger>
				<PopoverContent className='w-[200px] p-0' align='start'>
					<Command>
						<CommandInput placeholder='Search...' />
						<CommandList>
							<CommandEmpty>No results found.</CommandEmpty>
							<CommandGroup>
								{options.map((option) => (
									<CommandItem
										key={option.value}
										onSelect={() =>
											toggleOption(option.value)
										}>
										<div
											className={cn(
												'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
												selectedValues.includes(
													option.value
												)
													? 'bg-primary text-primary-foreground'
													: 'opacity-50 [&_svg]:invisible'
											)}>
											<CheckIcon className='h-4 w-4' />
										</div>
										<span>{option.label}</span>
									</CommandItem>
								))}
							</CommandGroup>
						</CommandList>
					</Command>
				</PopoverContent>
			</Popover>
		);
	}
);

MultiSelect.displayName = 'MultiSelect';

export default MultiSelect;
