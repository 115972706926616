import React, { useState } from "react";
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";
import ReusableDialogButton from "components/reusable-dialog-button";
import RefundModal from "../order-summary/RefundModal";

const SubscriptionActions = ({
  subsription,
  subsriptionId,
  isEdit,
  deleteMutate,
  deleteLoading,
  updateLoading,
}) => {
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);

  return (
    <>
      <Separator className="my-6" />
      <div className="flex justify-between items-center">
        <Button
          variant="outline"
          type="button"
          onClick={() => setIsRefundModalOpen(true)}
        >
          Refund
        </Button>
        <div className="flex items-center gap-4">
          {isEdit && subsription && subsription.id && (
            <ReusableDialogButton
              triggerText="Delete subsription"
              onConfirm={() => deleteMutate({ id: subsription.id })}
              dialogTitle="Delete Subsription"
              dialogDescription="Are you sure you want to delete this subsription?"
              cancelText="Cancel"
              className="text-red-500 hover:text-red-600"
              confirmText="Confirm"
              isLoading={deleteLoading}
            />
          )}
          <Button type="submit">
            {updateLoading ? "Updating..." : "Update"}
          </Button>
        </div>
      </div>
      <RefundModal
        subsriptionId={subsriptionId}
        isOpen={isRefundModalOpen}
        onClose={() => setIsRefundModalOpen(false)}
      />
    </>
  );
};

export default SubscriptionActions;
