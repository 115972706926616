import React from "react";
import { useFormikContext } from "formik";
import { Card, CardContent } from "components/ui/card";
import { renderNormalField } from "components/renderNormalField";
import CategorySelect from "../../_ui/CategorySelect";
import TagSelect from "../../_ui/TagSelect";

const AdditionalProductDetails = () => {
  const { values, setFieldValue } = useFormikContext();

  const detailFields = [
    {
      name: "catalog_visibility",
      label: "Catalog Visibility",
      type: "select",
      layout: "column",
      options: [
        { value: "visible", label: "Visible (Shop and search results)" },
        { value: "catalog", label: "Catalog only" },
        { value: "search", label: "Search results only" },
        { value: "hidden", label: "Hidden" },
      ],
    },
    {
      name: "description",
      label: "Full Description",
      type: "textarea",
      layout: "column",
    },
    { name: "slug", label: "URL Slug", type: "text", layout: "column" },
    { name: "permalink", label: "Permalink", type: "text", layout: "column" },
  ];

  return (
    <Card>
      <CardContent className="mt-6">
        <div className="grid grid-cols-2 gap-4">
          {detailFields.map((field, index) => (
            <div
              key={field.name}
              className={
                index === 0 || index === 1 ? "col-span-2" : "col-span-1"
              }
            >
              {renderNormalField({
                ...field,
                value: values[field.name],
              })}
            </div>
          ))}
          <div className="col-span-1">
            <TagSelect
              layout="column"
              value={values.tags || []}
              onChange={(newValue) => setFieldValue("tags", newValue)}
            />
          </div>
          <div className="col-span-1">
            <CategorySelect
              layout="column"
              value={values.categories || []}
              onChange={(newValue) => setFieldValue("categories", newValue)}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
export default AdditionalProductDetails;
