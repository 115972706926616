import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card';
import { Button } from 'components/ui/button';
import { renderNormalField } from 'components/renderNormalField';
import MediaLibrarySelect from 'components/ui/MediaLibrarySelect';
import { wpApiClient } from '../../api/axios';
import { useToast } from 'components/ui/use-toast';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';

const SEOPressProductMeta = ({ productId }) => {
	const { values, setFieldValue } = useFormikContext();
	const queryClient = useQueryClient();
	const { toast } = useToast();
	const [isSaving, setIsSaving] = useState(false);
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		// Fetch categories
		wpApiClient.get('/wp/v2/categories').then(response => {
			setCategories(response.data);
		});
	}, []);

	const { data, isLoading, error } = useQuery({
		queryKey: ['seopress', productId],
		queryFn: async () => {
			const response = await wpApiClient.get(`/seopress/v1/posts/${productId}`);
			if (response.data.success) {
				return response.data.data;
			} else {
				throw new Error('Failed to fetch SEOPress data');
			}
		},
		enabled: !!productId,
	});

	useEffect(() => {
		if (data) {
			setFieldValue('seopress_title', data.title || '');
			setFieldValue('seopress_description', data.description || '');
			setFieldValue('seopress_canonical', data.canonical || '');
			setFieldValue('seopress_noindex', data.robots?.noindex || false);
			setFieldValue('seopress_nofollow', data.robots?.nofollow || false);
			setFieldValue('seopress_noimageindex', data.robots?.noimageindex || false);
			setFieldValue('seopress_noarchive', data.robots?.noarchive || false);
			setFieldValue('seopress_nosnippet', data.robots?.nosnippet || false);
			setFieldValue('seopress_og_title', data.og?.title || '');
			setFieldValue('seopress_og_description', data.og?.description || '');
			setFieldValue('seopress_og_image', data.og?.image || '');
			setFieldValue('seopress_twitter_title', data.twitter?.title || '');
			setFieldValue('seopress_twitter_description', data.twitter?.description || '');
			setFieldValue('seopress_twitter_image', data.twitter?.image || '');
			setFieldValue('seopress_primary_category', data.primarycat || '');
			setFieldValue('seopress_redirections_enabled', data.redirections?.enabled || false);
			setFieldValue('seopress_redirections_logged_status', data.redirections?.logged_status || 'both');
			setFieldValue('seopress_redirections_type', data.redirections?.type || '301');
			setFieldValue('seopress_redirections_value', data.redirections?.value || '');
		}
	}, [data, setFieldValue]);

	const updateSEOData = useMutation({
		mutationFn: async (seoData) => {
			const titleDescResponse = await wpApiClient.put(
				`/seopress/v1/posts/${productId}/title-description-metas`,
				{ title: seoData.title, description: seoData.description }
			);

			const socialResponse = await wpApiClient.put(
				`/seopress/v1/posts/${productId}/social-settings`,
				{
					_seopress_social_fb_title: seoData.og.title,
					_seopress_social_fb_desc: seoData.og.description,
					_seopress_social_fb_img: seoData.og.image,
					_seopress_social_twitter_title: seoData.twitter.title,
					_seopress_social_twitter_desc: seoData.twitter.description,
					_seopress_social_twitter_img: seoData.twitter.image,
				}
			);

			const robotsResponse = await wpApiClient.put(
				`/seopress/v1/posts/${productId}/meta-robot-settings`,
				{
					_seopress_robots_index: seoData.robots.noindex ? 'no' : 'yes',
					_seopress_robots_follow: seoData.robots.nofollow ? 'no' : 'yes',
					_seopress_robots_imageindex: seoData.robots.noimageindex ? 'no' : 'yes',
					_seopress_robots_archive: seoData.robots.noarchive ? 'no' : 'yes',
					_seopress_robots_snippet: seoData.robots.nosnippet ? 'no' : 'yes',
					_seopress_robots_canonical: seoData.canonical,
					_seopress_robots_primary_cat: seoData.primarycat,
				}
			);

			return { titleDescResponse, socialResponse, robotsResponse };
		},
		onMutate: () => {
			setIsSaving(true);
		},
		onSuccess: (_, variables) => {
			queryClient.setQueryData(['seopress', productId], (oldData) => ({
				...oldData,
				...variables,
			}));
			toast({
				title: 'Success',
				description: 'SEO settings updated successfully.',
				variant: 'default',
			});
		},
		onError: (error) => {
			console.error('Error updating SEO data:', error);
			toast({
				title: 'Error',
				description: 'Failed to update SEO settings. Please try again.',
				variant: 'destructive',
			});
		},
		onSettled: () => {
			setIsSaving(false);
		},
	});

	const handleSave = () => {
		const seoData = {
			title: values.seopress_title,
			description: values.seopress_description,
			canonical: values.seopress_canonical,
			robots: {
				noindex: values.seopress_noindex,
				nofollow: values.seopress_nofollow,
				noimageindex: values.seopress_noimageindex,
				noarchive: values.seopress_noarchive,
				nosnippet: values.seopress_nosnippet,
			},
			og: {
				title: values.seopress_og_title,
				description: values.seopress_og_description,
				image: values.seopress_og_image,
			},
			twitter: {
				title: values.seopress_twitter_title,
				description: values.seopress_twitter_description,
				image: values.seopress_twitter_image,
			},
			primarycat: values.seopress_primary_category,
			redirections: {
				enabled: values.seopress_redirections_enabled,
				logged_status: values.seopress_redirections_logged_status,
				type: values.seopress_redirections_type,
				value: values.seopress_redirections_value,
			},
		};
		updateSEOData.mutate(seoData);
	};

	const seoFields = [
		{ name: 'seopress_title', label: 'SEO Title', type: 'text' },
		{ name: 'seopress_description', label: 'Meta Description', type: 'textarea' },
		{ name: 'seopress_canonical', label: 'Canonical URL', type: 'text' },
	];

	const robotsFields = [
		{ name: 'seopress_noindex', label: 'Do not display this page in search engine results / XML - HTML sitemaps (noindex)', type: 'checkbox' },
		{ name: 'seopress_nofollow', label: 'Do not follow links for this page (nofollow)', type: 'checkbox' },
		{ name: 'seopress_noimageindex', label: 'Do not index images for this page (noimageindex)', type: 'checkbox' },
		{ name: 'seopress_noarchive', label: 'Do not display a "Cached" link in the Google search results (noarchive)', type: 'checkbox' },
		{ name: 'seopress_nosnippet', label: 'Do not display a description in search results for this page (nosnippet)', type: 'checkbox' },
	];

	const ogFields = [
		{ name: 'seopress_og_title', label: 'OG Title', type: 'text' },
		{ name: 'seopress_og_description', label: 'OG Description', type: 'textarea' },
		{ name: 'seopress_og_image', label: 'OG Image', type: 'custom', component: MediaLibrarySelect },
	];

	const twitterFields = [
		{ name: 'seopress_twitter_title', label: 'Twitter Title', type: 'text' },
		{ name: 'seopress_twitter_description', label: 'Twitter Description', type: 'textarea' },
		{ name: 'seopress_twitter_image', label: 'Twitter Image', type: 'custom', component: MediaLibrarySelect },
	];

	const redirectionFields = [
		{ name: 'seopress_redirections_enabled', label: 'Enable redirection?', type: 'checkbox' },
		{ 
			name: 'seopress_redirections_logged_status', 
			label: 'Select a login status', 
			type: 'select',
			options: [
				{ value: 'both', label: 'All' },
				{ value: 'only_logged_in', label: 'Only logged in' },
				{ value: 'only_not_logged_in', label: 'Only not logged in' },
			]
		},
		{ 
			name: 'seopress_redirections_type', 
			label: 'Select a redirection type', 
			type: 'select',
			options: [
				{ value: '301', label: '301 Moved Permanently' },
				{ value: '302', label: '302 Found' },
				{ value: '307', label: '307 Temporary Redirect' },
			]
		},
		{ name: 'seopress_redirections_value', label: 'URL redirection', type: 'text' },
	];

	if (isLoading) return <div>Loading SEO data...</div>;
	if (error) return <div>Error loading SEO data: {error.message}</div>;

	return (
		<>
			<div className="flex flex-col gap-8 p-1 m-4 max-w-3xl">
				<Card>
					<CardHeader>
						<CardTitle>SEO Settings</CardTitle>
					</CardHeader>
					<CardContent className="flex flex-col gap-4">
						{seoFields.map((field) => (
							<div key={field.name}>
								{renderNormalField({
									...field,
									name: field.name,
									value: values[field.name],
									onChange: (e) => setFieldValue(field.name, e.target.value),
								})}
							</div>
						))}
					</CardContent>
				</Card>

				<Card>
					<CardHeader>
						<CardTitle>Meta Robots Settings</CardTitle>
					</CardHeader>
					<CardContent className="flex flex-col gap-4">
						{robotsFields.map((field) => (
							<div key={field.name}>
								{renderNormalField({
									...field,
									name: field.name,
									value: values[field.name],
									onChange: (e) => setFieldValue(field.name, e.target.checked),
								})}
							</div>
						))}
					</CardContent>
				</Card>

				<Card>
					<CardHeader>
						<CardTitle>Primary Category</CardTitle>
					</CardHeader>
					<CardContent>
						<Select
							value={values.seopress_primary_category || 'none'}
							onValueChange={(value) => setFieldValue('seopress_primary_category', value === 'none' ? '' : value)}
						>
							<SelectTrigger>
								<SelectValue placeholder="Select a primary category" />
							</SelectTrigger>
							<SelectContent>
								<SelectItem value="none">None (will disable this feature)</SelectItem>
								{categories.map((category) => (
									<SelectItem key={category.id} value={category.id.toString()}>
										{category.name}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					</CardContent>
				</Card>

				<Card>
					<CardHeader>
						<CardTitle>Open Graph Settings</CardTitle>
					</CardHeader>
					<CardContent className="flex flex-col gap-4">
						{ogFields.map((field) => (
							<div key={field.name}>
								{field.type === 'custom' ? (
									<MediaLibrarySelect
										value={values[field.name]}
										onChange={(value) => setFieldValue(field.name, value)}
									/>
								) : (
									renderNormalField({
										...field,
										value: values[field.name],
										onChange: (value) => setFieldValue(field.name, value),
									})
								)}
							</div>
						))}
					</CardContent>
				</Card>

				<Card>
					<CardHeader>
						<CardTitle>Twitter Settings</CardTitle>
					</CardHeader>
					<CardContent className="flex flex-col gap-4">
						{twitterFields.map((field) => (
							<div key={field.name}>
								{field.type === 'custom' ? (
									<MediaLibrarySelect
										value={values[field.name]}
										onChange={(value) => setFieldValue(field.name, value)}
									/>
								) : (
									renderNormalField({
										...field,
										value: values[field.name],
										onChange: (value) => setFieldValue(field.name, value),
									})
								)}
							</div>
						))}
					</CardContent>
				</Card>

				<Card>
					<CardHeader>
						<CardTitle>Redirection Settings</CardTitle>
					</CardHeader>
					<CardContent className="flex flex-col gap-4">
						{redirectionFields.map((field) => (
							<div key={field.name}>
								{field.type === 'select' ? (
									<Select
										value={values[field.name]}
										onValueChange={(value) => setFieldValue(field.name, value)}
									>
										<SelectTrigger>
											<SelectValue placeholder={field.label} />
										</SelectTrigger>
										<SelectContent>
											{field.options.map((option) => (
												<SelectItem key={option.value} value={option.value}>
													{option.label}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								) : (
									renderNormalField({
										...field,
										value: values[field.name],
										onChange: (value) => setFieldValue(field.name, value),
									})
								)}
							</div>
						))}
					</CardContent>
				</Card>
			</div>
			<Button onClick={handleSave} disabled={isSaving}>
				{isSaving ? 'Saving...' : 'Save SEO Settings'}
			</Button>
		</>
	);
};

export default SEOPressProductMeta;