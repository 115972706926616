import React, { createContext, useContext, useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { SETUP_API } from 'api/setup';

const ExtensionsContext = createContext();

export const ExtensionsProvider = ({ children }) => {
	const [extensions, setExtensions] = useState([]);

	//console.log(extensions);

	const { data: systemStatusData } = useQuery({
		queryKey: ['system-status'],
		queryFn: SETUP_API.GET_SYSTEM_STATUS.FN,
	});

	useEffect(() => {
		if (systemStatusData && systemStatusData.active_plugins) {
			const activePlugins = systemStatusData.active_plugins.map(
				(plugin) => plugin.name
			);
			setExtensions(activePlugins);
		}
	}, [systemStatusData]);

	return (
		<ExtensionsContext.Provider value={extensions}>
			{children}
		</ExtensionsContext.Provider>
	);
};

export const useExtensions = () => {
	const context = useContext(ExtensionsContext);
	if (context === undefined) {
		throw new Error(
			'useExtensions must be used within an ExtensionsProvider'
		);
	}
	return context;
};
