import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import Notes from "./Notes";

const NotesDialog = ({ subscriptionId, isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Subscription Notes</DialogTitle>
        </DialogHeader>
        <Notes subscriptionId={subscriptionId} />
      </DialogContent>
    </Dialog>
  );
};

export default NotesDialog;
