import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { renderNormalField } from 'components/renderNormalField';
import { SaveProductButton } from './SaveProductButton';
import CategorySelect from './CategorySelect';
import TagSelect from './TagSelect';
import MediaLibrarySelect from 'components/ui/MediaLibrarySelect';
import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	CardDescription,
} from 'components/ui/card';
import { Button } from 'components/ui/button';
import ProductReviews from './ProductReviews';
import { Dialog, DialogContent } from 'components/ui/dialog';

const ProductSummary = ({ loading }) => {
	const { values, setFieldValue } = useFormikContext();
	const [isReviewsDialogOpen, setIsReviewsDialogOpen] = useState(false);

	const priceFields = [
		{ name: 'regular_price', label: 'Price', type: 'text' },
		{ name: 'sale_price', label: 'Sale price', type: 'text' },
	];

	const productDetailsFields = [
		{ name: 'name', label: 'Name', type: 'text' },
		{ name: 'description', label: 'Description', type: 'editor' },
		{
			name: 'short_description',
			label: 'Short description',
			type: 'editor',
		},
		{ name: 'slug', label: 'Slug', type: 'text' },
		{ name: 'permalink', label: 'Link', type: 'text' },
	];

	const baseFields = [
		{
			name: 'type',
			label: 'Product Type',
			type: 'select',
			options: [
				{ value: 'simple', label: 'Simple product' },
				{ value: 'grouped', label: 'Grouped product' },
				{ value: 'external', label: 'External/Affiliate product' },
				{ value: 'variable', label: 'Variable product' },
			],
		},
		{
			name: 'status',
			label: 'Status',
			type: 'select',
			options: [
				{ value: 'draft', label: 'Draft' },
				{ value: 'publish', label: 'Published' },
			],
		},
		{
			name: 'categories',
			label: 'Categories',
			type: 'custom',
			component: CategorySelect,
		},
		{
			name: 'tags',
			label: 'Tags',
			type: 'custom',
			component: TagSelect,
		},
		{
			name: 'catalog_visibility',
			label: 'Visibility',
			type: 'select',
			options: [
				{ value: 'visible', label: 'Shop and search results' },
				{ value: 'catalog', label: 'Shop only' },
				{ value: 'search', label: 'Search results only' },
				{ value: 'hidden', label: 'Hidden' },
			],
		},
		{ name: 'images', label: 'Images', type: 'file' },
	];

	const insertFieldAfter = (fields, targetField, newFields) => {
		const targetIndex = fields.findIndex(
			(field) => field.name === targetField
		);
		if (targetIndex !== -1) {
			fields.splice(targetIndex + 1, 0, ...newFields);
		}
	};

	let fields = [...baseFields];
	let allPriceFields = [...priceFields];

	if (values.sale_price) {
		insertFieldAfter(allPriceFields, 'sale_price', [
			{
				name: 'date_on_sale_from',
				label: 'Sale start date',
				type: 'date',
			},
			{ name: 'date_on_sale_to', label: 'Sale end date', type: 'date' },
		]);
	}

	if (values.type.value === 'simple' || values.type === 'simple') {
		insertFieldAfter(fields, 'type', [
			{ name: 'virtual', label: 'Virtual', type: 'checkbox' },
			{ name: 'downloadable', label: 'Downloadable', type: 'checkbox' },
		]);
	}

	return (
		<>
			<div className='flex flex-col gap-8 p-1 m-4 max-w-3xl'>
				<Card>
					<CardHeader>
						<div className='flex items-center justify-between'>
							<div className='flex flex-col gap-2'>
								<CardTitle>Product Details</CardTitle>
								<CardDescription>
									Set the product details.
								</CardDescription>
							</div>
							{values.id && (
								<Button
									variant='secondary'
									type='button'
									onClick={() =>
										setIsReviewsDialogOpen(true)
									}>
									Manage Reviews
								</Button>
							)}
						</div>
					</CardHeader>
					<CardContent className='flex flex-col gap-4'>
						{productDetailsFields.map((field) => (
							<div key={field.name}>
								{renderNormalField({
									...field,
									value: values[field.name],
								})}
							</div>
						))}
					</CardContent>
				</Card>

				<Card>
					<CardHeader>
						<CardTitle>Price</CardTitle>
						<CardDescription>
							Set the price for your product.
						</CardDescription>
					</CardHeader>
					<CardContent className='flex flex-col gap-4'>
						{allPriceFields.map((field) => (
							<div key={field.name}>
								{renderNormalField({
									...field,
									value: values[field.name],
								})}
							</div>
						))}
					</CardContent>
				</Card>

				<Card>
					<CardHeader>
						<CardTitle>Organization</CardTitle>
						<CardDescription>
							Set the categories and tags for your product.
						</CardDescription>
					</CardHeader>
					<CardContent className='flex flex-col gap-4'>
						{fields.map((field) => (
							<div key={field.name}>
								{field.name === 'categories' ? (
									<CategorySelect
										value={values[field.name] || []}
										onChange={(newValue) =>
											setFieldValue(field.name, newValue)
										}
									/>
								) : field.name === 'tags' ? (
									<TagSelect
										value={values[field.name] || []}
										onChange={(newValue) =>
											setFieldValue(field.name, newValue)
										}
									/>
								) : (
									renderNormalField({
										...field,
										value: values[field.name],
									})
								)}
							</div>
						))}
					</CardContent>
				</Card>

				<Card>
					<CardHeader>
						<CardTitle>Product Images</CardTitle>
						<CardDescription>
							Set the main image and gallery for your product.
						</CardDescription>
					</CardHeader>
					<CardContent className='space-y-4'>
						<div>
							<h3 className='text-lg font-semibold mb-2'>
								Main Image
							</h3>
							<MediaLibrarySelect
								value={values.images[0]?.src || ''}
								onChange={(imageUrl) => {
									const newImages = [...values.images];
									if (newImages[0]) {
										newImages[0] = {
											...newImages[0],
											src: imageUrl,
										};
									} else {
										newImages[0] = { src: imageUrl };
									}
									setFieldValue('images', newImages);
								}}
							/>
						</div>
						<div>
							<h3 className='text-lg font-semibold mb-2'>
								Product Gallery
							</h3>
							<div className='grid grid-cols-3 gap-4'>
								{[1, 2, 3, 4, 5, 6].map((index) => (
									<div
										key={index}
										className='flex flex-col gap-2'>
										<span className='text-sm text-gray-500'>
											Image {index}
										</span>
										<MediaLibrarySelect
											key={index}
											value={
												values.images[index]?.src || ''
											}
											onChange={(imageUrl) => {
												const newImages = [
													...values.images,
												];
												if (newImages[index]) {
													newImages[index] = {
														...newImages[index],
														src: imageUrl,
													};
												} else {
													newImages[index] = {
														src: imageUrl,
													};
												}
												setFieldValue(
													'images',
													newImages
												);
											}}
										/>
									</div>
								))}
							</div>
						</div>
					</CardContent>
				</Card>
			</div>
			<SaveProductButton loading={loading} />

			<Dialog
				open={isReviewsDialogOpen}
				onOpenChange={setIsReviewsDialogOpen}>
				<DialogContent className='max-w-4xl max-h-[80vh]'>
					<ProductReviews productId={values.id} />
				</DialogContent>
			</Dialog>
		</>
	);
};

export default ProductSummary;
