import React from "react";
import { Field } from "formik";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";

const FormInput = ({
  name,
  label,
  type,
  placeholder = "",
  className = "",
  labelColor = "text-gray-700",
  layout = "row",
  labelClassName = "",
  inputClassName = "",
}) => (
  <Field name={name} id={name}>
    {({ field, meta }) => {
      const containerClassName = `flex ${
        layout === "row" ? "flex-row" : "flex-col"
      } gap-3 items-${layout === "row" ? "center" : "start"}`;

      return (
        <div>
          <div className={containerClassName}>
            {label && (
              <Label
                className={`${
                  layout === "row" ? "w-[300px]" : "w-full"
                } ${labelColor} ${labelClassName}`}
              >
                {label}
              </Label>
            )}
            <div className={layout === "row" ? "w-full" : "w-full"}>
              <Input
                {...field}
                type={type}
                placeholder={placeholder}
                className={`${className} ${inputClassName}`}
              />
            </div>
          </div>
          {meta.touched && meta.error ? (
            <div className="text-red-500 text-sm mt-1">{meta.error}</div>
          ) : null}
        </div>
      );
    }}
  </Field>
);

export default FormInput;
