import React, { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button } from 'components/ui/button';
import { useToast } from 'components/ui/use-toast';
import { wpApiClient } from 'api/axios';
import { APP_DEMO_MODE } from '../../config';

const MediaUpload = ({ onUploadSuccess }) => {
	const fileInputRef = useRef(null);
	const { toast } = useToast();

	const uploadMutation = useMutation({
		mutationFn: async (file) => {
			const formData = new FormData();
			formData.append('file', file);

			const response = await wpApiClient.post('/wp/v2/media', formData, {
				headers: {
					'Content-Disposition': `attachment; filename="${file.name}"`,
					'Content-Type': 'multipart/form-data',
				},
			});

			return response.data;
		},
		onSuccess: (data) => {
			onUploadSuccess(data.source_url);
			toast({
				title: 'Success',
				description: 'Image uploaded successfully',
			});
		},
		onError: (error) => {
			console.error('Error uploading file:', error.response?.data || error.message);
			toast({
				title: 'Error',
				description: `Failed to upload image: ${error.response?.data?.message || error.message}`,
				variant: 'destructive',
			});
		},
	});

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			uploadMutation.mutate(file);
		}
	};

	return (
		<div>
			<input
				type="file"
				accept="image/*"
				onChange={handleFileChange}
				style={{ display: 'none' }}
				ref={fileInputRef}
			/>
			<>
			<Button
				onClick={() => fileInputRef.current.click()}
				disabled={uploadMutation.isPending || APP_DEMO_MODE}
				type="button"
			>
				{uploadMutation.isPending ? 'Uploading...' : 'Upload Image'}
			</Button>
			{ APP_DEMO_MODE && (
				<span className="ml-4 text-xs text-gray-500">
					Upload is disabled in demo mode
				</span>
			)}
			</>
		</div>
	);
};

export default MediaUpload;
