import React from "react";
import { useFormikContext } from "formik";
import { Card, CardContent } from "components/ui/card";
import { Box, Package, ExternalLink } from "lucide-react";

const ProductTypeSelector = () => {
  const { values, setFieldValue } = useFormikContext();

  const productTypes = [
    { value: "simple", label: "Simple product", icon: Box },
    { value: "grouped", label: "Grouped product", icon: Package },
    // { value: "variable", label: "Variable product", icon: Layers },
    {
      value: "external",
      label: "External/Affiliate product",
      icon: ExternalLink,
    },
  ];

  return (
    <div className="grid grid-cols-2 gap-4">
      {productTypes.map((type) => (
        <Card
          key={type.value}
          className={`cursor-pointer transition-all ${
            values.type === type.value ? " ring-primary" : ""
          }`}
          onClick={() => setFieldValue("type", type.value)}
        >
          <CardContent
            className={`flex items-center h-20 p-4 rounded-md  ${
              values.type === type.value ? "bg-primary text-white" : ""
            }`}
          >
            <type.icon className="mr-2 h-6 w-6" />
            <span>{type.label}</span>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default ProductTypeSelector;
