import React, { useState } from "react";
import { Button } from "components/ui/button";
import SearchInput from "components/search-input";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import FilterDropdown from "./filterDropdown";
import { PRODUCT_API } from "api/product";
import { useNavigate } from "react-router-dom";
import { useToast } from "components/ui/use-toast";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from 'components/ui/select';
import { axiosClient } from 'api/axios';

import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from 'components/ui/dialog';
import ProductsBulkEdit from '../products-bulk-edit';

export default function SearchAndActions({
	selectedRowIds,
	loading,
	setSearch,
	dropdownState,
	filters,
	setFilters,
	refetchProducts,
	data,
}) {
	const cache = useQueryClient();
	const { toast } = useToast(); // Correct usage of useToast hook
	const navigate = useNavigate();
	const [selectedBulkAction, setSelectedBulkAction] = useState('');
	const [isBulkEditOpen, setIsBulkEditOpen] = useState(false);

	const handleInputChange = (value) => {
		//console.log('Input changed:', value);
		setSearch(value);
	};

	const handleInputFocus = () => {
		//console.log('Input focused');
	};

	const handleInputBlur = () => {
		//console.log('Input blurred');
	};

  const createProductMutation = useMutation({
    mutationFn: PRODUCT_API.ADD_PRODUCTS.FN,
    onSuccess: (data) => {
      cache.invalidateQueries([PRODUCT_API.GET_ALL_PRODUCTS.KEY]);
      navigate(`/products/update/${data.id}`);
      toast({
        title: "Success",
        description: "Product created successfully",
      });
    },
  });

  const bulkActions = [
    {
      label: "Delete",
      action: async (selectedIds) => {
        try {
          await PRODUCT_API.DELETE_BATCH_PRODUCTS.FN({
            ids: selectedIds,
          });
          toast({
            title: "Success",
            description: "Products deleted successfully",
          });
          refetchProducts();
        } catch (error) {
          console.error("Error deleting products:", error);
          toast({
            title: "Error",
            description: `Failed to delete products. ${
              error.response?.data?.message || error.message
            }`,
            variant: "destructive",
          });
        }
      },
    },
    {
      label: "Duplicate",
      action: async (selectedIds) => {
        try {
          const duplicatedProducts = await Promise.all(
            selectedIds.map(async (id) => {
              const response = await axiosClient.post(
                `/products/${id}/duplicate`
              );
              return response.data;
            })
          );

          toast({
            title: "Success",
            description: `${duplicatedProducts.length} product(s) duplicated successfully.`,
          });
          refetchProducts();
        } catch (error) {
          console.error("Error duplicating products:", error);
          toast({
            title: "Error",
            description: `Failed to duplicate products. ${
              error.response?.data?.message || error.message
            }`,
            variant: "destructive",
          });
        }
      },
    },
  ];

  const handleBulkAction = async () => {
    if (selectedBulkAction && selectedRowIds.length > 0) {
      const action = bulkActions.find(
        (action) => action.label === selectedBulkAction
      );
      if (action) {
        await action.action(selectedRowIds);
        setSelectedBulkAction("");
      }
    }
  };

	return (
		<div className='flex items-center py-2 px-4'>
			<div className='flex items-center gap-2 w-2/3'>
				<SearchInput
					columnName='code'
					placeholder='Search products'
					onInputChange={handleInputChange}
					onInputFocus={handleInputFocus}
					onInputBlur={handleInputBlur}
					className='border border-zinc-200 rounded-md'
				/>
				{selectedRowIds.length > 0 && (
					<>
						<Select
							value={selectedBulkAction}
							onValueChange={setSelectedBulkAction}>
							<SelectTrigger className='w-[150px]'>
								<SelectValue placeholder='Bulk actions' />
							</SelectTrigger>
							<SelectContent>
								{bulkActions.map((action, index) => (
									<SelectItem
										key={index}
										value={action.label}>
										{action.label}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
						<Button
							onClick={handleBulkAction}
							disabled={!selectedBulkAction}
							variant='outline'>
							Apply
						</Button>
					</>
				)}
			</div>
			<div className='ml-auto flex gap-3 items-center'>
				<Dialog
					open={isBulkEditOpen}
					onOpenChange={setIsBulkEditOpen}
				>
					<DialogTrigger asChild>
						<Button variant='outline' type='button'>Bulk Edit Products</Button>
					</DialogTrigger>
					<DialogContent className="max-w-[90vw] w-full max-h-[90vh] p-0 overflow-auto [&>button]:hidden">
						<ProductsBulkEdit
							products={data}
							refetchProducts={refetchProducts}
							onClose={() => setIsBulkEditOpen(false)}
						/>
					</DialogContent>
				</Dialog>
				<FilterDropdown
					dropdownState={dropdownState}
					filters={filters}
					setFilters={setFilters}
				/>
				<Button
					className={
						createProductMutation.isPending
							? 'cursor-not-allowed'
							: ''
					}
					disabled={createProductMutation.isPending}
					onClick={() =>
						createProductMutation.mutate({
							name: 'Draft product',
							status: 'draft',
						})
					}>
					{createProductMutation.isPending
						? 'Creating...'
						: 'Add product'}
				</Button>
			</div>
		</div>
	);
}
